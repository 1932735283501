import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStatusNotIn'
})
export class FilterStatusNotIn implements PipeTransform {

    transform(value, status: string): any {
        if(value){
            return value.filter(x=>{
                return x.status.toString() != status;
            })
        }
        return value;
    }

}