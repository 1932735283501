import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { observeResize } from '@utils/helper-functions';

@Directive({
    selector: '[IonContentOffset]'
})
export class IonContentOffsetDirective implements AfterViewInit, OnDestroy {

    private headerResizeObserver$: Subscription;
    private footerResizeObserver$: Subscription;

    constructor(
        private ionContent: ElementRef<HTMLElement>,
    ) { }

    ngAfterViewInit(): void {
        const ionContent = this.ionContent.nativeElement;
        if (ionContent) {
            const ionHeader = ionContent.previousElementSibling;
            const ionFooter = ionContent.nextElementSibling;

            if (ionHeader?.nodeName === 'ION-HEADER') {
                this.headerResizeObserver$ = this.heightResizeObserver(ionHeader).subscribe((headerHeight: number) => {
                    ionContent.style.setProperty('--offset-top', `${headerHeight}px`);
                });
            }

            if (ionFooter?.nodeName === 'ION-FOOTER') {
                this.footerResizeObserver$ = this.heightResizeObserver(ionFooter).subscribe((footerHeight: number) => {
                    ionContent.style.setProperty('--offset-bottom', `${footerHeight}px`);
                });
            }
        }
    }

    heightResizeObserver(el: Element) {
        return observeResize(el).pipe(
            map((contentRect) => contentRect.height),
            filter((height) => !isNaN(height)),
            distinctUntilChanged(),
        );
    }

    ngOnDestroy(): void {
        this.headerResizeObserver$?.unsubscribe();
        this.footerResizeObserver$?.unsubscribe();
    }
}