<ng-container *ngIf="manualCardEntry">
    <form class="payment-form" [formGroup]="paymentMethodForm">
        <div>
            <div class="input-floating">
                <input 
                    type="text" 
                    formControlName="cardHolderName" 
                    maxlength="20"
                    [(ngModel)]="paymentMethodDetails.cardHolderName" 
                    placeholder=" " 
                    clearInput
                />
                <label>Name on Card</label>
                <control-messages [form]="paymentMethodForm" control="cardHolderName"></control-messages>
            </div>
        </div>
        <div>
            <div class="input-floating">
                <input 
                    type="tel" 
                    formControlName="cardNumber" 
                    [(ngModel)]="paymentMethodDetails.cardNumber"
                    (input)="checkCardType($event)" 
                    [mask]="cardPattern" 
                    placeholder=" " 
                    clearInput 
                />
                <label>Card Number</label>
                <control-messages [form]="paymentMethodForm" control="cardNumber"></control-messages>
            </div>
        </div>
        <div class="con-2-input-floating">
            <div class="input-floating">
                <input 
                    type="tel" 
                    #expiryDate 
                    formControlName="expiry_date" 
                    mask="00/00"
                    [(ngModel)]="paymentMethodDetails.expiry_date" 
                    (input)="validateExpiryDate($event)" 
                    placeholder=" "
                    clearInput
                />
                <label>Expiry Date</label>
                <control-messages [form]="paymentMethodForm" control="expiry_date"></control-messages>
            </div>
            <div class="input-floating">
                <input 
                    type="tel" 
                    #cvv 
                    formControlName="cvv"
                    [(ngModel)]="paymentMethodDetails.cvv" 
                    [mask]="cvvPattern" 
                    placeholder=" " 
                    clearInput 
                />
                <label>CVC</label>
                <control-messages [form]="paymentMethodForm" control="cvv"></control-messages>
            </div>
        </div>
    </form>
</ng-container>

<div class="payment-form" [hidden]="manualCardEntry">
    <div class="header-loader" [hidden]="gatewayLoaded">
		<ion-spinner name="crescent"></ion-spinner>
	</div>
    <div id="sq-card" [hidden]="paymentGateway !== 'square'"></div>
    <div id="stripe-card" [hidden]="paymentGateway !== 'stripe'"></div>
    <div id="evo-card" [hidden]="paymentGateway !== 'payFabric'"></div>
    <div id="adyen-card" [hidden]="paymentGateway !== 'adyenFielddPay'"></div>
    <div id="finix-card" [hidden]="paymentGateway !== 'finixFielddPay'"></div>
    <form class="payment-form" id="qualPay-form" [hidden]="paymentGateway !== 'qualFielddPay'">
        <div id="qp-embedded-container"></div>
        <input type="submit" [hidden]="true" #formSubmitBtn>
    </form>
</div>