<div class="progress-con">
	<div>
		<div class="svg-con">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<defs>
					<style>
						.fa-secondary {
							opacity: .4
						}
					</style>
				</defs>
				<path
					d="M537.6 226.6A96.11 96.11 0 0 0 448 96a95.51 95.51 0 0 0-53.3 16.2A160 160 0 0 0 96 192c0 2.7.1 5.4.2 8.1A144 144 0 0 0 144 480h368a128 128 0 0 0 25.6-253.4zm-139.9 63.7l-10.8 10.8a24.09 24.09 0 0 1-34.5-.5L320 266.1V392a23.94 23.94 0 0 1-24 24h-16a23.94 23.94 0 0 1-24-24V266.1l-32.4 34.5a24 24 0 0 1-34.5.5l-10.8-10.8a23.9 23.9 0 0 1 0-33.9l92.7-92.7a23.9 23.9 0 0 1 33.9 0l92.7 92.7a24 24 0 0 1 .1 33.9z"
					class="fa-secondary" fill="var(--ion-color-primary)" />
				<path
					d="M397.7 290.3l-10.8 10.8a24.09 24.09 0 0 1-34.5-.5L320 266.1V392a23.94 23.94 0 0 1-24 24h-16a23.94 23.94 0 0 1-24-24V266.1l-32.4 34.5a24 24 0 0 1-34.5.5l-10.8-10.8a23.9 23.9 0 0 1 0-33.9l92.7-92.7a23.9 23.9 0 0 1 33.9 0l92.7 92.7a24 24 0 0 1 .1 33.9z"
					class="fa-primary" fill="var(--ion-color-primary)" />
			</svg>
		</div>
		<div class="progress-bar-con">
			<div>
				<span>{{pendingUploads.title || 'Quality Control Image Upload'}}</span>
			</div>
			<div>
				<progress *ngIf="pendingUploads?.total"
					value="{{(pendingUploads?.uploaded/pendingUploads?.total) * 100}}" max="100"></progress>
			</div>
		</div>
		<div class="upload-info">
			<div>
				{{pendingUploads.uploaded}} of {{pendingUploads.total}}
			</div>
			<div>
				{{((pendingUploads.uploaded / pendingUploads.total) * 100 ).toFixed(2)}}%
			</div>
		</div>
	</div>
</div>