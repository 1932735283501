import { Directive, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { ActionSheetController, ActionSheetOptions, Platform } from '@ionic/angular';

import { File } from '@ionic-native/file/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';

import { Camera, PictureSourceType, CameraOptions, DestinationType, EncodingType, MediaType } from '@ionic-native/camera/ngx';

import { FileModel } from '@data/models/shared.model';
import { AlertService } from '@services/alert.service';

@Directive({
    selector: '[file-upload]'
})
export class FileUploadDirective implements OnChanges {

    private readonly ACCEPT_CONSTANT: string = 'image/*';
    private accept: string = this.ACCEPT_CONSTANT;

    private isActionSheetOpen: boolean = false;

    @Input() header: string = '';
    @Input() multi: boolean = false;
    @Input() quality: number = 25;

    @Input() allowFile: boolean = false;
    @Input() allowVideo: boolean = false;

    @Input() disabled: boolean = false;

    @Output() callback: EventEmitter<FileModel[]> = new EventEmitter();
    @Output() errorCallback: EventEmitter<any> = new EventEmitter();

    constructor(
        private file: File,
        private camera: Camera,
        private chooser: Chooser,
        private platform: Platform,
        private alertService: AlertService,
        private actionSheetController: ActionSheetController,
    ) { }

    ngOnChanges(): void {
        this.accept = `${this.ACCEPT_CONSTANT}${this.allowVideo ? ',video/*' : ''}${this.allowFile ? ',application/pdf' : ''}`;
    }

    nativeSelector() {
        return this.chooser.getFile(this.accept).then((file) => {
            if (!file) return;

            const result: FileModel[] = [{
                fileName: file.name,
                fileType: file.mediaType,
                data: file.dataURI.split(',')[1],
                raw: file.dataURI,
            }];

            this.emitCallback(result);
        }).catch(() => {
            this.alertService.showErrorDialog('Please check the permissions for the storage and try again.');
        });
    }

    takePictureApp(sourceType: PictureSourceType) {
        const options: CameraOptions = {
            quality: this.quality,
            sourceType: sourceType,
            saveToPhotoAlbum: false,
            correctOrientation: true,
            encodingType: EncodingType.JPEG,
            destinationType: DestinationType.FILE_URL,
        };

        if (sourceType === PictureSourceType.PHOTOLIBRARY && this.allowVideo) {
            options.mediaType = MediaType.ALLMEDIA;
        }

        return this.camera.getPicture(options).then((fileUri) => {
            const fileName = fileUri.substring(fileUri.lastIndexOf('/') + 1, fileUri.length);
            const pathName = fileUri.substring(0, fileUri.lastIndexOf('/') + 1);
            const dirPath = pathName.includes('file:///') ? pathName : `file://${pathName}`;

            return this.file.readAsDataURL(dirPath, fileName).then((base64Data) => {
                const [type, data] = base64Data.split(',');

                const index = base64Data.indexOf('/') + 1;
                const extension = base64Data.substring(index, base64Data.indexOf(';base64')).split('+')[0];

                const result: FileModel[] = [{
                    fileName: `${Date.now()}.${extension}`,
                    fileType: type.split(':')[1].split(';')[0],
                    data: data,
                    raw: base64Data,
                }];
                this.emitCallback(result);
            });
        }).catch((error) => {
            if (error !== 'No Image Selected') {
                this.alertService.showErrorDialog('Please check the permissions for the camera and try again.');
                this.errorCallback.emit(error);
            }
        });
    }

    @HostListener('click')
    clickEvent() {
        if (this.disabled || this.isActionSheetOpen) return;
        this.isActionSheetOpen = true;

        const buttons = [
            {
                text: 'Library',
                role: 'library',
                handler: () => {
                    if (this.platform.is('android') || window.cordova?.platformId === 'android') {
                        return this.nativeSelector();
                    }
                    return this.takePictureApp(PictureSourceType.PHOTOLIBRARY);
                },
            },
            {
                text: 'Camera',
                role: 'camera',
                handler: () => this.takePictureApp(PictureSourceType.CAMERA),
            },
            {
                text: 'Cancel',
                role: 'cancel'
            }
        ];

        if (this.allowFile) {
            buttons.unshift({
                text: 'File',
                role: 'file',
                handler: () => this.nativeSelector(),
            });
        }

        const options: ActionSheetOptions = {
            buttons: buttons,
            mode: 'ios',
        };

        if (this.header) {
            options.header = this.header;
        }

        return this.actionSheetController.create(options).then((actionSheet) => {
            return actionSheet.present();
        }).finally(() => {
            this.isActionSheetOpen = false;
        });
    }

    emitCallback(files: FileModel[]) {
        for (const file of files) {
            const fileSize = (3 * (file.data?.length || 1) / 4);
            if (fileSize > (20 * 1024 * 1024)) {
                return this.alertService.showErrorDialog('File size should be less than 20 MB.');
            }
        }

        this.callback.emit(files);
    }
}