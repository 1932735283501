import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateService } from '@services/date-service';

@Pipe({ name: 'formatMessageTime' })
export class FormatMessageTime implements PipeTransform {
    constructor( public dateService:DateService){

    }
    transform(value: any): string {
        let now = moment().valueOf();
        let diff = now - moment(value).valueOf();

        //Boundary case scenario
        if (diff < 0) {
            return "now";
        }

        if (diff < 2 * 60 * 1000) { 
            return "now";
        } else if (diff < 60 * 60 * 1000) { 
            return (diff / (60 * 1000)).toFixed(0) + " mins ago";
        } else {
            return this.dateService.autoFormat(moment(value),"h:mma");
        }
    }
}