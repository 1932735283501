import { Injectable, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { groupBy } from 'lodash-es';
import * as moment from 'moment';

import { AuthenticationService } from '@services/auth.service';
import { TimeRangeItem } from '@data/models/shared.model';

@Injectable({
    providedIn: 'root'
})
export class DateService implements OnDestroy {

    company$: Subscription;
    company: any;

    constructor(
        private authenticationService: AuthenticationService,
    ) {
        this.company$ = this.authenticationService.company$.subscribe((company) => {
            this.company = company;
        });
    }
    public calculateDayDifferences(date1: Date, date2: Date) {
        const secondsDifference = (date2.valueOf() - date1.valueOf()) / 1000;
        return Math.floor(secondsDifference / 86400) + 1;
    }

    public generateTimeString(dateObj: moment.MomentInput) {
        const format = this.company?.timePreferences === 24 ? 'H:mm' : 'h:mma';
        return moment(dateObj).format(format);
    }

    public generateFullDateString(dateObj: Date) {
        const format = this.company?.datePreferences === 'dmy' ? 'D/M/YY' : 'M/D/YY';
        return moment(dateObj).format(format);
    }

    public generateDateMonthString(dateObj: Date) {
        const format = this.company?.datePreferences === 'dmy' ? 'D MMM' : 'MMM D';
        return moment(dateObj).format(format);
    }

    public generateDayString(dateObj: Date, isAbbr = false) {
        return isAbbr ? moment(dateObj).format('ddd') : moment(dateObj).format('dddd');
    }
    public shouldShowDateDivider(date1, date2) {
        date1 = new Date(moment(parseInt(date1)).startOf('day').valueOf());
        date2 = new Date(moment(parseInt(date2)).startOf('day').valueOf());
        let daysDifference = moment(date2.getTime()).diff(date1.getTime(), 'days');
        if (daysDifference > 0) {
            return true;
        }
        return false;
    }

    public formatDateDivider(timestamp) {
        const now = new Date(moment().startOf('day').valueOf());
        const date = new Date(parseInt(timestamp));

        const daysDifference = this.calculateDayDifferences(date, now);

        if (daysDifference === 0) {
            return 'Today, ' + this.generateTimeString(date);
        } else if (daysDifference === 1) {
            return 'Yesterday, ' + this.generateTimeString(date);
        } else if (daysDifference > 1 && daysDifference <= 7) {
            return this.generateDayString(date) + ', ' + this.generateTimeString(date);
        } else if (daysDifference > 7 && daysDifference <= 365) {
            return this.generateDayString(date, true) + ', ' + this.generateDateMonthString(date) + ', ' + this.generateTimeString(date);
        } else {
            return this.generateDateMonthString(date) + ', ' + date.getFullYear() + ', ' + this.generateTimeString(date);
        }
    }
    public dowString(timestamp: moment.MomentInput) {
        const format = this.company?.datePreferences === 'dmy' ? 'ddd Do MMM YY' : 'ddd MMM Do YY';
        return moment(timestamp).format(format)
    }

    public autoFormat(timestamp: moment.MomentInput, format: string, timezone: string = moment.tz.guess()) {
        if (!this.company) {
            return moment(timestamp).tz(timezone).format(format)
        }

        if (this.company?.datePreferences === 'dmy') {
            const monthArray = ['MMM', 'MM', 'M'];
            const dayArray = ['Do', 'DD', 'D']
            let searchArray = []
            for (let m in monthArray) {
                if (format.includes(monthArray[m])) {
                    searchArray.push(monthArray[m])
                    break
                }
            }
            for (let d in dayArray) {
                if (format.includes(dayArray[d])) {
                    searchArray.push(dayArray[d])
                    break
                }
            }
            if (searchArray.length == 2) {
                let searchreg = searchArray[0] + '|' + searchArray[1]
                let newStr = format.replace(new RegExp(searchreg, "g"), function (x) {
                    return x === searchArray[1] ? searchArray[0] : searchArray[1]
                })
                format = newStr
            }
        }

        if (this.company?.timePreferences) {
            if (this.company?.timePreferences === 24) {
                format = format.replace(/h/g, 'H').replace(/a/g, '');
            }
        }
        return moment(timestamp).tz(timezone).format(format);
    }

    convertTimeSlots(momentTime: moment.Moment) {
        if (this.company?.timePreferences === 24) {
            return {
                hour: momentTime.format('H'),
                minutes: momentTime.format('mm'),
                timeOfDay: momentTime.format('a')
            }
        }

        return {
            hour: momentTime.format('h'),
            minutes: momentTime.format('mm'),
            timeOfDay: momentTime.format('a')
        }
    }

    convertTimeSlotFormattedText(timeObj) {
        if (this.company?.timePreferences === 24) {
            return timeObj.hour + ':' + timeObj.minutes;
        }
        return timeObj.hour + ':' + timeObj.minutes + timeObj.timeOfDay;
    }

    getSowPreferences() {
        return !isNaN(this.company?.sowPreferences) ? (this.company.sowPreferences === -1 ? 0 : 1) : 1;
    }

    createChatGroups(conversation: any[], timeKey: string = 'createdOn') {
        conversation = conversation.sort((a, b) => a[timeKey] - b[timeKey]);

        const groupedConversation = [];
        const groups = groupBy(conversation, (item) => moment(item[timeKey]).format('MMM D YYYY'));
        for (const messages of Object.values(groups)) {
            groupedConversation.push({
                dateTime: this.formatDateDivider(moment(messages[0][timeKey]).valueOf()),
                messages: messages,
            });
        }

        return groupedConversation;
    }

    getTimeRange(): Array<TimeRangeItem> {
        const timeBetweenBookings = this.company?.timeBetweenBookings || { time: 0, min: 15 };

        const slots: Array<TimeRangeItem> = [];

        const startTime = moment().startOf('d');
        const endTime = moment().endOf('d');

        let i = 0;
        while (true) {
            if (startTime.isAfter(endTime)) {
                break;
            }

            slots.push({
                value: {
                    hour: startTime.hours(),
                    min: startTime.minutes(),
                },
                selectValue: `0${startTime.hours()}`.slice(-2) + `0${startTime.minutes()}`.slice(-2),
                display: this.autoFormat(startTime, 'h:mma'),
                index: i++,
            });
            startTime.add(timeBetweenBookings.time, 'h').add(timeBetweenBookings.min, 'm');
        }

        slots.push({
            value: {
                hour: endTime.hours(),
                min: endTime.minutes(),
            },
            selectValue: '2359',
            display: this.autoFormat(endTime.add(1, 'd').startOf('d'), 'h:mma'),
            index: i++,
        });

        return slots;
    }

    addPluralSuffixToTime(val: number, prefix: string) {
        return `${val} ${prefix}${val > 1 ? 's' : ''}`;
    }

    getDisplayTime(mins: number) {
        if (mins >= 60) {
            let hr = Math.floor(mins / 60);
            mins = Math.floor(mins % 60);

            if (hr >= 24) {
                const days = Math.floor(hr / 24);
                return `${this.addPluralSuffixToTime(days, 'day')}`;
            }

            if (mins === 0) {
                return `${this.addPluralSuffixToTime(hr, 'hour')}`;
            }
            return `${this.addPluralSuffixToTime(hr, 'hour')} ${this.addPluralSuffixToTime(mins, 'minute')}`;
        }
        return this.addPluralSuffixToTime(mins, 'minute');
    }

    convertSlotsToTime(noOfSlots: number): string {
        const timeBetweenBookings = this.company?.timeBetweenBookings || { time: 0, min: 15 };

        const slotSize = timeBetweenBookings.min + (timeBetweenBookings.time * 60);
        const timeInMin = slotSize * (noOfSlots || 0);

        return this.getDisplayTime(timeInMin);
    }

    getMultiDayDisplayTime(start: moment.Moment, end: moment.Moment, includeOutsideHours: boolean = false, companyHoursByDay: any = null) {
        const startDate = moment(start.format('ll'), 'll');
        const endDate = moment(end.format('ll'), 'll');
        let days = 0;
        if (includeOutsideHours) {
            days = endDate.diff(startDate, 'd') + 1;
        } else {
            let dayOfWeek = startDate.day();
            while (startDate.isSameOrBefore(endDate, 'day')) {
                if (companyHoursByDay[dayOfWeek].isWorkingDay) {
                    days++;
                }
                startDate.add(1, 'days');
                dayOfWeek = startDate.day();
            }
        }
        return `${this.addPluralSuffixToTime(days, 'day')}`;
    }

    ngOnDestroy() {
        this.company$?.unsubscribe();
    }
}