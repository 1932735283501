<div class="job-cal-con-b" [ngClass]="cssClass">

    <!-- Calendar Month -->
    <div class="job-cal-con-g">
        <div class="job-cal-month-con">
            <div class="job-cal-month-bg">
                <div class="job-cal-month">
                    <ion-datetime ngDefaultControl display-format="MMM" picker-format="MMM, YYYY" [(ngModel)]="pickerDate" [min]="disablePicker" (ionChange)="pickerDateChanged($event)"></ion-datetime>
                </div>
                <div class="job-cal-month-i">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm128 244a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-128a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm128 128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm128 128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-128a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm-80-180h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm-192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z"
                            class="menu-secondary" />
                        <path d="M448 112v80H0v-80a48 48 0 0 1 48-48h48v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h128v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48a48 48 0 0 1 48 48z"
                            class="menu-primary" />
                    </svg>
                </div>

                <div class="job-cal-month-down">
                    <svg width="16px" height="12px" viewBox="0 0 18 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.4">
                            <g id="POS-MASTER" transform="translate(-536.000000, -978.000000)" fill="var(--ion-color-primary)">
                                <g id="Group-4" transform="translate(0.000000, 961.000000)">
                                    <g id="Group-2" transform="translate(536.000000, 17.000000)">
                                        <path d="M4.48673259,-2.25553167 L3.93488768,-1.74402564 C3.45631377,-1.30043462 3.42795453,-0.552872235 3.87154555,-0.0742983225 C3.89185574,-0.0523864118 3.91298975,-0.0312527553 3.934902,-0.0109429278 L10.4201004,6 L3.934902,12.0109429 C3.45632076,12.454526 3.42794917,13.202088 3.87153229,13.6806692 C3.89184212,13.7025814 3.91297577,13.7237154 3.93488768,13.7440256 L4.48673259,14.2555317 C4.93983519,14.6755133 5.63999392,14.6755191 6.09310346,14.2555449 L14.0650825,6.86654799 C14.5436638,6.42296487 14.5720353,5.67540296 14.1284522,5.19682172 C14.108138,5.1749047 14.0869995,5.15376626 14.0650825,5.13345201 L6.09310346,-2.25554494 C5.63999392,-2.67551907 4.93983519,-2.67551329 4.48673259,-2.25553167 Z" id="Path" transform="translate(9.000000, 6.000000) rotate(90.000000) translate(-9.000000, -6.000000) "></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            
            <div [hidden]="showFirstAvail" class="job-cal-title">
                {{ title }}
            </div>
            
            <div [hidden]="!showFirstAvail" (click)="loadFirstAvailableSlot()" class="cal-first-a first-avail">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><defs>
                    <style>.fa-secondary{opacity:.4}</style></defs>
                    <path d="M208 80a128 128 0 1 1-90.51 37.49A127.15 127.15 0 0 1 208 80m0-80C93.12 0 0 93.12 0 208s93.12 208 208 208 208-93.12 208-208S322.88 0 208 0z" class="fa-secondary cal-svg"/>
                    <path d="M504.9 476.7L476.6 505a23.9 23.9 0 0 1-33.9 0L343 405.3a24 24 0 0 1-7-17V372l36-36h16.3a24 24 0 0 1 17 7l99.7 99.7a24.11 24.11 0 0 1-.1 34z" class="fa-primary cal-svg"/>
                </svg>
                    First Avail
            </div>

            <ng-content select=".dispatch-switch"></ng-content>
        </div>

        <!-- Calendar Days -->
        <div class="job-cal-days" (swipe)="swipeEvent($event)">
            <div class="booking-cal-con">
                <div class="cal-arrow-con">
                    <button class="week-arrows" (click)="onWeekChangedBtnClicked(false)">
                        <svg width="12px" height="18px" viewBox="0 0 12 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.4">
                                <g id="POS-MASTER" transform="translate(-52.000000, -228.000000)" fill="var(--ion-color-primary)">
                                    <g id="Group-17" transform="translate(52.000000, 228.000000)">
                                        <path d="M1.48673259,0.744468328 L0.934887683,1.25597436 C0.456313771,1.69956538 0.427954534,2.44712777 0.871545554,2.92570168 C0.891855743,2.94761359 0.912989749,2.96874724 0.934901995,2.98905707 L7.42010039,9 L0.934901995,15.0109429 C0.456320756,15.454526 0.427949172,16.202088 0.871532289,16.6806692 C0.891842116,16.7025814 0.912975773,16.7237154 0.934887683,16.7440256 L1.48673259,17.2555317 C1.93983519,17.6755133 2.63999392,17.6755191 3.09310346,17.2555449 L11.0650825,9.86654799 C11.5436638,9.42296487 11.5720353,8.67540296 11.1284522,8.19682172 C11.108138,8.1749047 11.0869995,8.15376626 11.0650825,8.13345201 L3.09310346,0.744455063 C2.63999392,0.324480927 1.93983519,0.324486709 1.48673259,0.744468328 Z" id="Path" transform="translate(6.000000, 9.000000) rotate(180.000000) translate(-6.000000, -9.000000) "></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
                <div class="cal-days-con">
                    <div class="days-box">
                        <div *ngFor="let day of currentWeek" class="day-ele dow">{{ day.date | formatDate: 'dd' }}</div>
                    </div>
                    <div class="days-box" style="margin-top: 3px;">
                        <div *ngFor="let day of currentWeek" class="day-ele">
                            <button type="button" class="days-btn" [class.has-data]="day?.data?.length"
                                [ngClass]="{'on-selected': day.date.valueOf() == date.valueOf(), 'today' : day.date.valueOf() == todaysDate.valueOf()}"
                                (click)="onDateSelected(day.date)" [disabled]="!day.enabled">
                                {{ day.date | formatDate: "DD" }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="cal-arrow-con">
                    <button class="week-arrows" (click)="onWeekChangedBtnClicked(true)">
                        <svg width="12px" height="18px" viewBox="0 0 12 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.4">
                                <g id="POS-MASTER" transform="translate(-524.000000, -228.000000)" fill="var(--ion-color-primary)">
                                    <g id="Group-17" transform="translate(52.000000, 228.000000)">
                                        <path d="M473.486733,0.744468328 L472.934888,1.25597436 C472.456314,1.69956538 472.427955,2.44712777 472.871546,2.92570168 C472.891856,2.94761359 472.91299,2.96874724 472.934902,2.98905707 L479.4201,9 L472.934902,15.0109429 C472.456321,15.454526 472.427949,16.202088 472.871532,16.6806692 C472.891842,16.7025814 472.912976,16.7237154 472.934888,16.7440256 L473.486733,17.2555317 C473.939835,17.6755133 474.639994,17.6755191 475.093103,17.2555449 L483.065083,9.86654799 C483.543664,9.42296487 483.572035,8.67540296 483.128452,8.19682172 C483.108138,8.1749047 483.087,8.15376626 483.065083,8.13345201 L475.093103,0.744455063 C474.639994,0.324480927 473.939835,0.324486709 473.486733,0.744468328 Z" id="Path"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>