import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterZeroValue'
})
export class FilterZeroValue implements PipeTransform {
    transform(arr: any[], key: string): any {        
        if (arr?.length) {
            return arr.filter(a => a[key] > 0);
        }
        return [];
    }
}