import { Directive, ElementRef, Input, OnDestroy, OnInit, Output, EventEmitter, Injector, HostBinding } from '@angular/core';
import { AbstractControl, ControlContainer, FormControlName, NG_ASYNC_VALIDATORS, NgControl, ValidationErrors, Validator } from '@angular/forms';

import { Subscription, delay, filter, map, of, take } from 'rxjs';
import { isEqual } from 'lodash-es';

import { observeIntersection, observeMutation } from '@utils/helper-functions';
import { ParsedGoogleAddress } from '@data/models/shared.model';
import { CommonHelperService } from '@services/helper.service';

@Directive({
    selector: 'input[googleAutocomplete]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: GoogleAutocompleteDirective,
        multi: true
    }]
})
export class GoogleAutocompleteDirective implements OnInit, OnDestroy, Validator {

    private parsedGoogleAddress: ParsedGoogleAddress;

    @Input()
    set preSelected(val: ParsedGoogleAddress) {
        if (val && !isEqual(val, this.parsedGoogleAddress)) {
            this.parsedGoogleAddress = val;
            this.control?.updateValueAndValidity();
        }
    }

    @Input() country: string | string[];

    @Input() onlyCities: boolean = false;
    @Input() geometry: boolean = true;
    @Input() utcOffset: boolean = false;

    @Output() selectAddress: EventEmitter<ParsedGoogleAddress> = new EventEmitter<ParsedGoogleAddress>();

    @HostBinding('class.flat-bottom-input')
    isPacConInView: boolean = false;

    private autocomplete: google.maps.places.Autocomplete;
    private autocompleteListener: google.maps.MapsEventListener;
    private options: google.maps.places.AutocompleteOptions = {
        fields: ['formatted_address', 'address_components'],
    };

    private control: AbstractControl;

    private pacCon: Element;
    private elementId: string = `google-autocomplete-${Date.now()}`;

    private subscriptions: Array<Subscription> = [];

    constructor(
        private el: ElementRef,
        private injector: Injector,
        private commonHelperService: CommonHelperService,
    ) { }

    ngOnInit(): void {
        const control = this.injector.get(NgControl);
        if (control instanceof FormControlName) {
            this.control = this.injector.get(ControlContainer).control.get(String(control.name));
        } else {
            this.control = control.control;
        }

        this.setupAutocompleteOptions();
        this.observeInView();
        this.createAutocomplete();
    }

    private setupAutocompleteOptions(): void {
        if (this.country) {
            this.options.componentRestrictions = { country: this.country };
        }
        if (this.onlyCities) {
            this.options.types = ['(cities)'];
        }
        if (this.geometry) {
            this.options.fields.push('geometry');
        }
        if (this.utcOffset) {
            this.options.fields.push('utc_offset_minutes');
        }
    }

    private createAutocomplete(): void {
        this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options);
        this.autocompleteListener = this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
    }

    private placeChanged() {
        const place = this.autocomplete.getPlace();

        if (!place || !place.formatted_address || place.name === '') {
            this.clearAddress();
            return;
        }

        this.parsedGoogleAddress = this.commonHelperService.parseGoogleAddress(place);
        this.el.nativeElement.value = this.parsedGoogleAddress.address;

        this.control?.updateValueAndValidity();
        this.selectAddress.emit(this.parsedGoogleAddress);
    }

    private observeInView(): void {
        observeMutation(document.body, { childList: true }).pipe(
            map(() => document.querySelector('body > .pac-container:not(.has-initialized)')),
            filter((pacCon) => !!pacCon),
            take(1),
        ).subscribe((pacCon) => {
            this.pacCon = pacCon;

            pacCon.classList.add('has-initialized');
            pacCon.classList.add('crm-pac-container');
            pacCon.setAttribute('id', this.elementId);

            this.subscriptions.push(
                observeIntersection(pacCon).subscribe((inView) => {
                    this.isPacConInView = inView;
                })
            );
        });
    }

    private clearAddress(): void {
        this.el.nativeElement.value = '';
        this.parsedGoogleAddress = null;
        this.control?.updateValueAndValidity();
        this.selectAddress.emit();
    }

    validate(control: AbstractControl): ValidationErrors {
        return (!control?.value || this.parsedGoogleAddress) ? of(null) : of({ googleAutocompleteInvalid: true }).pipe(
            delay(500)
        );
    }

    ngOnDestroy(): void {
        google.maps.event.removeListener(this.autocompleteListener);
        google.maps.event.clearInstanceListeners(this.autocomplete);

        this.pacCon?.remove();
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}