import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from '../../../services/auth.service';
import { DispatchService } from '../../../modules/dispatchModule/dispatch.service';
import { LocationTrackerService } from '../../../services/location-tracker.service';
import { BookingStatus } from '../../../data/models/enums';
import { LoadingService } from '../../../services/loading.service';
import { CommonService } from '../../../services/common.service';
import { AlertService } from '../../../services/alert.service';
import { CommonHelperService } from '../../../services/helper.service';
import * as _ from 'lodash-es';
import { PosService } from '../../../modules/posModule/pos.service';
import { FielddCurrencyPipe } from '../../../pipes/fielddCurrency.pipe';

@Component({
    selector: 'app-ondemand-modal',
    templateUrl: 'onDemandModal.component.html'
})
export class onDemandModalComponent implements OnInit {

    @Input() jobId: string = '';
    @Input() recurringJobDetails: any;
    @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

    public job: any = {};
    public company: any = {};
    public appSettings: any = {};
    public serviceImageUrl: string = '';
    public startAddress: any = {};
    public destinationAddress: any = {};
    public mapImageLoaded: boolean = false;
    public duration: string = '';
    public startTime: string = '';
    public endTime: string = '';
    public showInfo: boolean = false;
    public discountMessage: string = '';

    constructor(
        private alertService: AlertService,
        private commonService: CommonService,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private dispatchService: DispatchService,
        private locationService: LocationTrackerService,
        private authenticationService: AuthenticationService,
        private helperService: CommonHelperService,
        private posService: PosService,
        private currencyPipe: FielddCurrencyPipe,
    ) { }

    async ngOnInit() {
        if(this.recurringJobDetails?._id) {
            this.startTime = this.recurringJobDetails.startTime;
            this.endTime = this.recurringJobDetails.endTime;    
        }

        this.company = this.authenticationService.getCurrentCompany();
        this.appSettings = this.authenticationService.getAppSettings();

        await this.loadingService.showLoader(true);
        let response = await this.dispatchService.getOrder(this.jobId);
        await this.loadingService.hideLoader();
        if (response.success) {
            this.job = response.data;
            if(this.job.promoCode && this.job.discountedAmount) {
                this.redeemPromoCode(this.job.customerEmail, this.job.promoCode)
            }
            this.serviceImageUrl = this.job.variants?.[0]?.primaryImageUrl;
            this.startAddress = this.job.address;
            let fromGeo: any;
            setTimeout(() => {
                let elem = document.getElementById("ondemand-info-con-id");
                elem.scrollIntoView({ behavior: 'smooth', block: 'end'});
            }, 100)

            // To add history/full name in job variants booked via online portal
            if (this.job.variants && this.job.variants.length && !this.job.variants[0].history) {
                const service = this.authenticationService.companyServices;
                if (service) {
                    this.postGettingServices(service);
                }

                this.authenticationService.getServiceDetails().then(this.postGettingServices.bind(this));
            }
            
            if(this.job.routeAddresses?.[0]?.address) {
                this.destinationAddress = this.job.routeAddresses[0].address;
            } else {
                this.destinationAddress = 'Your location';
            }
            if(this.locationService.getCurrentLocation()) {
                fromGeo = this.locationService.getCurrentLocation()?.partnerLocation;
                
                const { latitude, longitude } = this.job.geolocation;
                let toGeo = { lat: latitude, lng: longitude };
                fromGeo = { lat: fromGeo.latitude, lng: fromGeo.longitude };
    
                try {
                    let distanceInfo: any = await this.locationService.getDistanceFromCoordinates(fromGeo, toGeo);
                    this.duration = distanceInfo.duration.text;
                } catch(e) {
                    //
                }
            }
        }
    }

    postGettingServices(service: any) {
        let variantId = null;
        if (this.job?.variants[0] && this.job?.variants[0].serviceId) {
            variantId = this.job.variants[0].id;
        }

        this.job.variant = {};
        _.forEach(service.variants, (variant) => {
            _.forEach(this.job.variants, (jobVariant) => {
                let history = this.helperService.getServiceHistory(variant, service)
                if (variant.id === jobVariant.id && !jobVariant.history) {
                    jobVariant['history'] = history || jobVariant.fullName
                }
                if (variant.id === variantId) {
                    this.job.variant = variant;
                    this.job.variant['history'] = history || this.job.variant.fullName
                }
            })
        });
    }

    redeemPromoCode(emailID, promoCode) {
        const dataToSend = {
            emailID, promoCode
        }
        this.posService.redeemPromoCode(dataToSend).subscribe(async response => {
            if (response && response.success) {
                
                let discountValue = (response.discount.type === 'fixed') ? this.currencyPipe.getCurrencySymbol() + response.discount.value : response.discount.value + '%';
                discountValue += ' off ';
                discountValue += (response.discount.applied_on === 'booking') ? '' : 'each service';
                this.discountMessage = discountValue;
            }
        }, async (error) => {
        });
    }

    onViewJobOpen() {
        this.showInfo = !this.showInfo;
        if(this.showInfo) {
            setTimeout(() => {
                let elem = document.querySelector(".ondemand-con");
                elem.scrollBy({ left: 0, top: 100000, behavior: 'smooth' });
            }, 100);
        }
    }

    async acceptRequest() {
        try {
            await this.loadingService.showLoader(true);

            let dataToSend = { status: BookingStatus.Accepted };
            let res = await this.commonService.updateOrderStatus(this.jobId, dataToSend).toPromise();
            await this.loadingService.hideLoader();
            if (res?.success) {
                if (res.hasOwnProperty('code')) {
                    return await this.alertService.showToastMessage("This job has already been accepted");
                }
                this.job.status = BookingStatus.Accepted;
                this.closeModal(true);
            }
        } catch(e) {
            await this.loadingService.hideLoader();
            this.alertService.showErrorDialog(e.error.message || "Temporary delay while accepting this job, please try again later");
        } 
    }

    closeModal(startJob = false) {
        this.modalClosed.emit(startJob ? this.job : false);
        this.modalController.dismiss();
    }
}