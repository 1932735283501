import { AbstractControl, FormGroup, NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';

import { EMPTY, Observable, of, mergeMap, map, distinctUntilChanged, catchError } from 'rxjs';
import { cloneDeep, keyBy } from 'lodash-es';

import { AssetDBItem, AssetItem, AssetItemResponse, AssetsInputTypes, MappedAsset } from '@modules/statusModule/status.model';

export function roundDecimal(n: number, precision: number = 2): number {
    precision = Math.pow(10, precision || 0);
    return Math.round(n * precision) / precision;
}

export function noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if ((control.value || '').trim().length !== 0) {
            return null;
        }
        return { required: true };
    };
}

export function checkFormValidity(formGroup: FormGroup | NgForm): boolean {
    if (!formGroup.valid) {
        for (const control in formGroup.controls) {
            if (!formGroup.controls[control].valid) {
                formGroup.controls[control].markAsTouched();
            }
        }
    }
    return formGroup.valid;
}

export function passwordValidator(form: FormGroup, passwordKey = 'password'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return form.get(passwordKey).value === control.value ? null : { validateEqual: true };
    };
}

export function observeIntersection(el: Element): Observable<boolean> {
    return new Observable((observer) => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            observer.next(entries);
        });
        intersectionObserver.observe(el);
        return () => intersectionObserver.disconnect();
    }).pipe(
        mergeMap((entries: IntersectionObserverEntry[]) => entries),
        map((entry) => entry.isIntersecting),
        catchError(() => of(true)),
        distinctUntilChanged(),
    );
}

export function observeResize(el: Element, onlyField?: string): Observable<any> {
    return new Observable((observer) => {
        const resizeObserver = new ResizeObserver((entries) => {
            observer.next(entries);
        });

        resizeObserver.observe(el);
        return () => resizeObserver.disconnect();
    }).pipe(
        mergeMap((entries: ResizeObserverEntry[]) => entries),
        map((entry) => onlyField && entry.contentRect ? entry.contentRect[onlyField] : entry.contentRect),
        catchError(() => EMPTY),
        distinctUntilChanged(),
    );
}

export function observeMutation(el: Element, options: MutationObserverInit = { childList: true, subtree: true }): Observable<MutationRecord> {
    return new Observable((observer) => {
        const mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
            observer.next(mutations);
        });

        mutationObserver.observe(el, options);
        return () => mutationObserver.disconnect();
    }).pipe(
        mergeMap((mutations: MutationRecord[]) => mutations),
        catchError(() => EMPTY),
        distinctUntilChanged(),
    );
}

export function mapAssetList(list: AssetDBItem[], company: any): MappedAsset[] {
    return (list || [])
        .filter((d) => !!Array.isArray(d?.data))
        .map((data) => {
            return mapAssetData(data, (company?.customerAssets?.items || []));
        });
}

export function mapAssetData(data: AssetDBItem, companyTemplate: AssetItem[]): MappedAsset {
    const keyByLabel = keyBy(data?.data || [], 'label');
    const MIGRATED_DATA = keyByLabel['License Plate'];

    let vehicle: any;

    let textInputValues = [];
    let profilePhoto: string;

    const assetItems = cloneDeep(companyTemplate)
        .sort((a, b) => a.position - b.position)
        .map((v) => {
            let value = keyByLabel[v.label];
            if (v.inputType === AssetsInputTypes.LICENSE_PLATE) {
                if (MIGRATED_DATA && !value) {
                    value = MIGRATED_DATA;
                }

                if (!vehicle && value?.value) {
                    vehicle = value;
                }
            }

            const returnVal: AssetItemResponse = {
                ...v,
                value: (value?.value || '').trim(),
            };

            if (returnVal?.value && [AssetsInputTypes.TEXT, AssetsInputTypes.PARAGRAPH].includes(v.inputType)) {
                textInputValues.push(returnVal.value);
            }

            if (value?.extraData) {
                returnVal.extraData = value.extraData;

                if (!profilePhoto && v.inputType === AssetsInputTypes.PHOTOS && Array.isArray(returnVal.extraData)) {
                    const imageUrl = returnVal.extraData.find((p: string) => !!p);
                    if (imageUrl) {
                        profilePhoto = imageUrl;
                    }
                }
            }

            return returnVal;
        });

    if (MIGRATED_DATA?.extraData || vehicle?.value) {
        vehicle = MIGRATED_DATA || vehicle;

        if (!textInputValues.length) {
            textInputValues.push(`${vehicle.extraData.year || ''} ${vehicle.extraData.model || ''} ${vehicle.extraData.make || ''}`.trim());
            textInputValues.push(`${vehicle.plate || ''}`);
        }

        if (!profilePhoto && vehicle.extraData.image) {
            profilePhoto = vehicle.extraData.image;
        }
    }

    return {
        _id: data?._id || '',
        data: assetItems,
        image: profilePhoto,
        profileImage: data?.profileImage || '',
        title: textInputValues[0] || '',
        subtitle: textInputValues[1] || '',
    };
}