import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-modal',
    templateUrl: 'customPopup.component.html'
})
export class CustomModalComponent implements OnInit {
    @Input() title: string = "Title Here";
    @Input() imageUrl: string = "default"
    @Input() description: any = "Description Here";
    @Input() cancelButtonText: string = "Cancel";
    @Input() okButtonText: string = "Continue";
    @Input() backButtonText: string;
    @Input() modalType: string = "custom";
    @Input() hideCancelButton: boolean = false;
    @Input() isFullModal: boolean = false;
    @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private modalController: ModalController,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.description = this.sanitizer.bypassSecurityTrustHtml(this.description);
    }

    closeModal(option, back = false) {
        this.modalController.dismiss('close');
        if (back) {
            this.modalClosed.emit(null);
        }
        else {
            this.modalClosed.emit(option);
        }
    }
}