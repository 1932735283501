import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageResizePipe } from './imageResize.pipe';

@Pipe({ name: 'datUri' })
export class DataURItoBlobPipe implements PipeTransform {

    private sanitizer = inject(DomSanitizer);
    private imageResizePipe = inject(ImageResizePipe);

    transform(base64Data: string, type: string) {
        if (base64Data?.startsWith('https://')) {
            return this.imageResizePipe.transform(base64Data);
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(`data:${type};base64, ${base64Data}`);
    }
}