import { Directive, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, NgControl, FormControlName, ControlContainer } from '@angular/forms';
import { take } from 'rxjs';

import { AuthenticationService } from '@services/auth.service';

import intlTelInput from 'intl-tel-input';
import * as intlTelInputUtils from 'intl-tel-input/build/js/utils';
declare var intlTelInputUtils;

@Directive({
    selector: 'input[type=tel][appIntlTelInput]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: IntlTelInputDirective,
        multi: true
    }]
})
export class IntlTelInputDirective implements OnInit, OnDestroy, Validator {

    @Input() preferredCountries: string[];
    @Input() allowInternational: boolean;
    @Input() onlyCountries: string[];

    @Input() validNumber: string;
    @Output() validNumberChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() dialCode: EventEmitter<string> = new EventEmitter<string>();

    private iti: intlTelInput.Plugin;
    private control: AbstractControl;

    constructor(
        private elementRef: ElementRef,
        private injector: Injector,
        private authService: AuthenticationService,
    ) { }

    ngOnInit() {
        const control = this.injector.get(NgControl);
        if (control instanceof FormControlName) {
            this.control = this.injector.get(ControlContainer).control.get(String(control.name));
        } else {
            this.control = control.control;
        }

        const inputElement = this.elementRef.nativeElement;
        const options: intlTelInput.Options = {
            autoPlaceholder: 'aggressive',
            utilsScript: intlTelInputUtils,
            allowDropdown: !!(this.allowInternational || this.onlyCountries?.length > 1),
            onlyCountries: this.allowInternational ? [] : (Array.isArray(this.onlyCountries) ? this.onlyCountries : []),
        };

        if (this.onlyCountries?.length === 1) {
            options.initialCountry = this.onlyCountries[0];
        }

        if (this.preferredCountries) {
            options.preferredCountries = this.preferredCountries;
        }

        this.iti = intlTelInput(inputElement, options);

        const itiContainer = inputElement.parentElement;
        if (itiContainer) {
            itiContainer.classList.add('iti--allow-dropdown');
            const selectedFlag = itiContainer.querySelector('.iti__selected-flag');
            if (selectedFlag) {
                selectedFlag.removeAttribute('tabindex');
            }
        }

        if (this.validNumber) {
            setTimeout(() => this.setNumber(this.validNumber));
        }
    }

    validate(control: AbstractControl): ValidationErrors {
        if (!control.value || !this.validateInput()) {
            return null;
        }

        return { phoneNumberInvalid: true };
    }

    validateInput(): boolean {
        const value = this.iti.getNumber();

        this.validNumber = value;
        this.validNumberChange.emit(value);

        const { dialCode } = this.iti.getSelectedCountryData();
        this.dialCode.emit(dialCode);

        return value && !this.iti.isValidNumber();
    }

    setNumber(number: string): void {
        this.authService.company$.pipe(
            take(1),
        ).subscribe((company) => {
            try {
                number = intlTelInputUtils.formatNumber(number, company?.activeCountry, intlTelInputUtils.numberFormat.E164);
                this.control?.setValue(number);
                this.iti.setNumber(number);
            } catch (error) {
                // 
            }
            this.control?.updateValueAndValidity();
        });
    }

    ngOnDestroy() {
        this.iti?.destroy();
    }
}