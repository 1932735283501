import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-modal',
    templateUrl: 'inputModal.component.html'
})
export class InputModalComponent implements OnInit {

    @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
    @Input() props: any = {};

    description: any;
    inputVal: string = '';
    isInputValid: boolean = true;

    constructor(
        private modalController: ModalController,
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit(): void {
        this.description = this.sanitizer.bypassSecurityTrustHtml(this.props.description || '');
    }

    closeModal(option: any) {
        if (option) {
            if (this.inputVal?.length) {
                option = this.inputVal;
            } else {
                this.isInputValid = false;
                return;
            }
        }
        this.modalController.dismiss();
        this.modalClosed.emit(option);
    }
}