import { Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgForm } from '@angular/forms';

import { checkFormValidity, mapAssetData } from '@utils/helper-functions';
import { ApiQuestionComponent } from '@components/api-question/api-question.component';

import { AssetDBItem, AssetItemResponse, AssetsInputTypes, MappedAsset } from '@modules/statusModule/status.model';

import { AlertService, AlertType } from '@services/alert.service';
import { LoadingService } from '@services/loading.service';
import { CommonService } from '@services/common.service';
import { FileModel } from '@data/models/shared.model';

enum Accordion {
    Details,
    History,
}

@Component({
    selector: 'asset-details',
    templateUrl: './asset-details.component.html',
    styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent {

    assetDetails: Array<AssetItemResponse> = [];
    private _asset: MappedAsset;

    @Input() company: any;
    @Input() customerId: string;
    @Input()
    public set asset(value: MappedAsset) {
        this._asset = mapAssetData(value, this.company?.customerAssets?.items || []);
        this.assetDetails = this._asset.data.map((item) => {
            if (AssetsInputTypes.PHOTOS === item.inputType) {
                item.extraData = item.extraData || [].constructor(5);
            }

            return item;
        });

        this.profileImage = this._asset?.profileImage || '';
    }
    public get asset(): MappedAsset {
        return this._asset;
    }

    Accordion: typeof Accordion = Accordion;
    AssetsInputTypes: typeof AssetsInputTypes = AssetsInputTypes;

    profileImage: string;

    isDeleting: boolean = false;
    isAdding: boolean = true;

    openAccordion: Accordion;

    orders: Array<any> = [];
    hasLoadedHistory: boolean = false;
    isLoadingHistory: boolean = true;

    isSaving: boolean = false;
    uploadingImage: boolean = false;

    @ViewChild('detailsForm') detailsForm: NgForm;
    @ViewChildren(ApiQuestionComponent) apiQuestionComponents: QueryList<ApiQuestionComponent>;

    constructor(
        private commonService: CommonService,
        private alertService: AlertService,
        private modalController: ModalController,
        private loadingService: LoadingService,
    ) { }

    ionViewWillEnter(): void {
        // 
    }

    getBookingHistory() {
        // if (this.hasLoadedHistory) return;
        // this.hasLoadedHistory = true;

        // this.orders = [];
        // this.isLoadingHistory = true;

        // const dataToSend: BookingHistoryRequest = {
        //     companyId: this.company._id,
        //     customerId: this.customer._id,
        //     assetId: this.asset._id,
        //     filter: 'assets',
        //     limit: -1,
        //     skip: 0,
        // };

        // this.bookingService.getUserBookingHistory(dataToSend).then((result) => {
        //     this.orders = result?.pastBookingList || [];
        // }).catch(() => {
        //     // 
        // }).finally(() => {
        //     this.isLoadingHistory = false;
        // });
    }

    saveDetails(form: NgForm) {
        let isValid = checkFormValidity(form);

        if (this.apiQuestionComponents?.length) {
            this.apiQuestionComponents.forEach((apiQuestionComponent) => {
                isValid = isValid && apiQuestionComponent.validateApiQuestion();
            });
        }

        if (!isValid) {
            if (!this.isAdding && this.openAccordion !== Accordion.Details) {
                this.toggleAccordion(Accordion.Details);
            }
            return this.alertService.showErrorDialog('Please complete all required fields');
        }

        const assetDetails: AssetItemResponse[] = this.assetDetails.map((item) => {
            const returnVal: AssetItemResponse = {
                inputType: item.inputType,
                label: item.label,
                value: item.value || '',
                extraData: item.extraData,
            };

            if (item.state) {
                returnVal.state = item.state;
            }

            if (item.plate) {
                returnVal.plate = item.plate;
            }

            return returnVal;
        });

        if (!this.asset?._id || this.asset?._id === 'new') {
            const newDetails: AssetDBItem = {
                _id: this.asset?._id,
                data: assetDetails,
                profileImage: this.profileImage,
            };

            return this.closeModal(newDetails);
        }

        // const dataToSend: any = {
        //     assetDetails: assetDetails,
        // };

        // if (this.isAdding && this.profileImage) {
        //     dataToSend.profileImage = this.profileImage;
        // }

        // if (this.isSaving) return;
        // this.isSaving = true;

        // const savePromise = this.isAdding ? this.commonService.addCustomerAsset(dataToSend) : this.commonService.updateCustomerAsset(this.asset._id, dataToSend);
        // return this.loadingService.httpWrapperLoader(savePromise, false).then((res) => {
        //     if (!res?.success) throw new Error();

        //     // this.authService.updateLocalAsset(res.data);
        //     this.alertService.showToastMessage('Asset details saved successfully', AlertType.Info);

        //     if (this.isAdding) {
        //         return this.closeModal(res.data);
        //     }
        // }).catch(() => {
        //     this.alertService.showToastMessage('Temporary delay saving asset details, try again later', AlertType.Error);
        // }).finally(() => {
        //     this.isSaving = false;
        // });
    }

    onProfileImageChange($event: FileModel[]) {
        const image = $event?.[0]?.raw;
        if (!image) return;

        this.profileImage = image;
        // if (this.isAdding || !this.asset?._id || this.asset?._id === 'new') {
        //     return;
        // }

        // if (this.uploadingImage) return;
        // this.uploadingImage = true;

        // const dataToSend = {
        //     profileImage: image,
        // };

        // return this.loadingService.httpWrapperLoader(this.commonService.updateCustomerAsset(this.asset._id, dataToSend)).then((res) => {
        //     if (!res?.success) throw new Error();

        //     // this.authService.updateLocalAsset(res.data);
        //     this.alertService.showToastMessage('Image uploaded successfully', AlertType.Info);
        // }).catch(() => {
        //     this.alertService.showToastMessage('Temporary delay uploading image, try again later', AlertType.Error);
        // }).finally(() => {
        //     this.uploadingImage = false;
        // });
    }

    deleteAsset() {
        if (this.isDeleting) return;
        this.isDeleting = true;

        const message = 'Are you sure you want to delete this asset?';

        return this.alertService.confirmMessage('Warning', message).then((confirmed) => {
            if (!confirmed) return;

            return this.loadingService.httpWrapperLoader(
                this.commonService.deleteCustomerAsset(this.asset._id),
                false
            ).then((response) => {
                if (response?.success) {
                    this.alertService.showToastMessage('Asset deleted successfully', AlertType.Info);

                    this.closeModal();
                }
            });
        }).catch(() => {
            // 
        }).finally(() => {
            this.isDeleting = false;
        });
    }

    setApiAnswer(extraData: any, item: AssetItemResponse) {
        item.value = extraData.questionValue;
        item.extraData = extraData.questionData;
        item.state = (extraData.questionData?.state || '').toUpperCase();
        item.plate = (extraData.questionData?.plate || '').toUpperCase();
    }

    // openOrderDetails(order: Order) {
    //     return this.router.navigate([`bookinghistory/detail/${order._id}`]);
    // }

    // bookNow() {
    //     const address = this.asset.data.find((d) => d.inputType === AssetsInputTypes.ADDRESS && !!Object.keys(d.extraData?.location || {}).length);
    //     const data: any = {
    //         assetId: this.asset._id,
    //     };
    //     if (address) {
    //         data.initialAddress = address.extraData;
    //     }
    //     return this.router.navigate(['/'], { state: data });
    // }

    closeModal(data?: AssetDBItem) {
        return this.modalController.dismiss(data);
    }

    toggleAccordion(accordion: Accordion) {
        if (this.openAccordion === accordion) {
            this.openAccordion = null;
        } else {
            this.openAccordion = accordion;
        }
    }

    trimText(str: string) {
        str = (str || '').trim();
    }

    trackByFn(index: number, item: AssetItemResponse) {
        return item.label || index;
    }

    ionViewDidLeave(): void {
        // 
    }
}