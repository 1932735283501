<div class="popup-small-con">
    <div class="popup-small-bg">
        <div class="popup-header">
            <img class="popup-icon" [src]="props.imageUrl">
        </div>
        <div class="popup-title">
            {{ props.title }}
        </div>
        <div class="popup-description">
            <div [innerHtml]="description"></div>
            <div class="input-field-con" style="margin-top: 15px;">
                <textarea type="text" autocomplete="off" class="input-field" [(ngModel)]="inputVal" rows="10"
                    [class.pos-has-error]="!isInputValid" [placeholder]="props.placeholderText || ''" required>
                </textarea>
            </div>
        </div>
        <div class="popup-buttons-bottom">
            <div class="popup-cancel-button" (click)="closeModal(false)" *ngIf="!props.hideCancelButton">
                {{ props.cancelButtonText }}
            </div>
            <div class="popup-ok-button" (click)="closeModal(true)">
                {{ props.okButtonText }}
            </div>
        </div>
    </div>
</div>