import { NgModule, Injectable } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { BuildInfo } from '@ionic-native/build-info/ngx';
import { Market } from '@ionic-native/market/ngx';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { reducers } from '@state/index';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../app/interceptors/auth.interceptor';
import { CustomModalComponent } from './common/modalPopups/customModal.component';

import { ConfirmationModalComponent } from './common/modalPopups/confirmationModal.component';
import { ZoomModalComponent } from './common/modalPopups/zoomModal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { Camera } from '@ionic-native/camera/ngx';
import { SharedModule } from '../app/common/shared.module';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FirsttimeModelComponent } from './common/modalPopups/firsttimeLoginModal.component';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { onDemandModalComponent } from './common/modalPopups/onDemandModal.component/onDemandModal.component';
import { InputModalComponent } from './common/modalPopups/inputModal.component/inputModal.component';
import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx'
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

@Injectable({
    providedIn: 'root'
})
export class HammerConfig extends HammerGestureConfig {
    overrides = {
        pinch: { enable: false },
        rotate: { enable: false },
    };
}

@NgModule({
    declarations: [
        AppComponent,
        CustomModalComponent,
        onDemandModalComponent,
        ZoomModalComponent,
        FirsttimeModelComponent,
        ConfirmationModalComponent,
        InputModalComponent,
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        SharedModule,
        HttpClientModule,
        NgxWebstorageModule.forRoot({ prefix: "fieldStaffApp", separator: "." }),
        IonicModule.forRoot({ 
            mode: 'ios',
            swipeBackEnabled: false,
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        HammerModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([]),
    ],
    providers: [
        WebView,
        StatusBar,
        SplashScreen,
        AppVersion,
        FirebaseX,
        Camera,
        BuildInfo,
        Market,
        BackgroundGeolocation,
        AndroidPermissions,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor, multi: true
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
