import { Injectable } from '@angular/core';
import { Camera, PictureSourceType, CameraOptions } from '@ionic-native/camera/ngx';
import { ActionSheetController, Platform } from '@ionic/angular';
import { environment } from '../../environments/environment'
import { AuthenticationService } from './auth.service';
import { File } from '@ionic-native/file/ngx';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { AlertService } from './alert.service';
import { FileModel } from '@data/models/shared.model';

/**
 * fieldd file management service
 */
@Injectable({
    providedIn: 'root'
})
export class FileManagementService {

    filesPath: string;
    fileType: string;
    filesName: string;
    backGroundUploads: any;
    company: any;
    partner: any;

    public pendingUploads: BehaviorSubject<{ uploaded: number, total: number }> = new BehaviorSubject<{ uploaded: number, total: number }>({ uploaded: 0, total: 0 });
    uploadImagesUrl: string = environment.apiURL + "images/partner/upload"
    deleteImageUrl: string = environment.apiURL + "images/partner/delete"
    originalLength: any;

    constructor(
        private camera: Camera,
        private actionSheetController: ActionSheetController,
        private authenticationService: AuthenticationService,
        private localStorageService: LocalStorageService,
        private http: HttpClient,
        private file: File,
        private alertService: AlertService,
        private platform: Platform,

    ) {
        this.platform.ready().then(() => {
            this.company = this.authenticationService.getCurrentCompany();
            this.partner = this.authenticationService.getCurrentUserData();
            this.backGroundUploads = this.localStorageService.retrieve("uploadPhotos");
            if (this.backGroundUploads?.photos?.length > 0) {
                this.originalLength = this.backGroundUploads?.photos?.length || 0;
                this.pendingUploads.next({ uploaded: 0, total: this.originalLength });
            }
            this.authenticationService.userLoggedIn$.subscribe(flag => {
                if (flag) {
                    this.company = this.authenticationService.getCurrentCompany();
                    this.partner = this.authenticationService.getCurrentUserData();
                }
            })

            this.startUploading();
        })
    }

    async selectImage(saveToGallery = false, dataUri = false) {
        return new Promise(async (res, rej) => {
            const actionSheet = await this.actionSheetController.create({
                buttons: [{
                    text: 'Choose from Library',
                    handler: () => {
                        this.takePhoto(saveToGallery, dataUri, false).then((data) => {
                            res(data);
                        }).catch((err) => {
                            rej(err);
                        });
                    }
                }, {
                    text: 'Take Photo',
                    handler: () => {
                        this.takePhoto(saveToGallery, dataUri, true).then((data) => {
                            res(data);
                        }).catch((err) => {
                            rej(err);
                        });
                    }
                }, {
                    text: 'Cancel',
                    role: 'cancel'
                }]
            });
            await actionSheet.present()
        })
    }

    async takePhoto(saveToGallery: boolean = false, dataUri: boolean = false, openCamera: boolean = true) {
        try {
            let data = await this.takePicture(openCamera ? this.camera.PictureSourceType.CAMERA : this.camera.PictureSourceType.PHOTOLIBRARY, saveToGallery, dataUri);
            if(data.lastIndexOf('?') > -1){
                data =data.substring(0,data.lastIndexOf('?'));
            }
            if (data && !dataUri) {
                if (data.indexOf('file') == -1) {
                    data = "file://" + data;
                }
            }
            return data;
        } catch (e) {
            if (e == 20) {//permission denied
                await this.alertService.showErrorDialog("We cannot access your camera/album, please check your phone settings and try again");
            }
            throw new Error(e);
        }
    }

    private takePicture(sourceType: PictureSourceType, saveToGallery: boolean = false, dataUri: boolean = false) {
        let options: CameraOptions = {
            quality: 25,
            sourceType: sourceType,
            correctOrientation: true,
            saveToPhotoAlbum: sourceType == PictureSourceType.CAMERA,
            destinationType: dataUri ? this.camera.DestinationType.DATA_URL : this.camera.DestinationType.FILE_URI,
        };
        return this.camera.getPicture(options);
    }

    async uploadImages(dataObj, imageData) {
        const postData = this.getUploadPostData(dataObj)

        if (dataObj.from === 'order') {
            if (dataObj.imageType.toLowerCase() === 'arrival' || dataObj.imageType.toLowerCase() === 'completion') {

                const pathName = imageData.substring(0, imageData.lastIndexOf('/') + 1);
                const fileName = imageData.substring(imageData.lastIndexOf('/') + 1, imageData.length)

                // let dirPath = this.file.tempDirectory ? this.file.tempDirectory : pathName.includes('file:///') ? pathName : `file://${pathName}`
                const dirPath = pathName.includes('file:///') ? pathName : `file://${pathName}`
                try {
                    imageData = await this.file.readAsDataURL(dirPath, fileName)
                } catch (e) {
                    throw new Error('Image Not Found');
                }
            }
            let splitBase64 = imageData.split(';base64,')[1] || imageData
            postData.append('file', this.dataURItoBlob(splitBase64), 'image.jpeg')
        } else {
            postData.append('file', this.dataURItoBlob(imageData), 'image.jpeg')
        }
        return lastValueFrom(this.http.post<any>(this.uploadImagesUrl, postData));
    }

    async uploadImagesV2(dataObj: any, imageData: FileModel) {
        const blob = this.dataURItoBlob(imageData.data, imageData.fileType);
        const postData = this.getUploadPostData(dataObj);

        postData.append('file', blob, imageData.fileName);

        return lastValueFrom(this.http.post<any>(this.uploadImagesUrl, postData));
    }

    deleteImage(data) {
        const postData = this.getDeletePostData(data)
        return lastValueFrom(this.http.post<any>(this.deleteImageUrl, postData));
    }

    getUploadPostData(dataObj: any): FormData {
        let postData = new FormData();
        postData.append('from', dataObj.from)
        postData.append('companyId', this.company._id)
        postData.append('partnerId', dataObj.partnerId || this.partner._id)
        postData.append('orderId', dataObj.orderId || '')
        postData.append('imageType', dataObj.imageType || '')
        return postData
    }

    getDeletePostData(dataObj) {
        return {
            from: dataObj.from,
            companyId: this.company._id,
            partnerId: dataObj.partnerId || this.partner._id,
            imageType: dataObj.imageType || '',
            filePath: dataObj.filePath,
        }
    }

    dataURItoBlob(dataURI: string, type = 'image/jpeg') {
        const byteString: string = window.atob(dataURI);
        const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([int8Array], { type });
    }

    async addToBackGroundUploads(uploadData) {
        this.backGroundUploads = this.localStorageService.retrieve("uploadPhotos");
        if (this.backGroundUploads?.photos?.length > 0) {
            this.backGroundUploads.photos = [...this.backGroundUploads.photos, ...uploadData.photos];
        } else {
            this.backGroundUploads = uploadData;
        }
        this.originalLength = this.backGroundUploads?.photos?.length || 0;
        this.localStorageService.store("uploadPhotos", this.backGroundUploads);
        if (this.backGroundUploads?.photos?.length > 0) {
            this.pendingUploads.next({ uploaded: 0, total: this.backGroundUploads.photos.length });
        }
        this.startUploading();
        return;
    }

    async startUploading() {

        while (this.backGroundUploads && this.backGroundUploads.photos.length > 0) {
            try {
                let currentItem = this.backGroundUploads.photos[0];
                const dataToSend = {
                    from: 'order',
                    orderId: currentItem.orderId,
                    imageType: currentItem.photoType,
                }
                //TODO: this is a highly inefficient way to upload images. It can be handled in two different ways.
                //Add all images to the form data and upload them together {{ will pose a problem if the combined size of images is too large }}.
                //Send all request together {{ will pose a problem if the number is too large }}.
                if (currentItem.uploadStarted && currentItem.errorCount === 0) {
                    if(!this.platform.is("ios")){
                        this.removeTopPhoto();
                        this.pendingUploads.next({ uploaded: this.originalLength - this.backGroundUploads.photos.length, total: this.originalLength });
                        continue;
                    }
                }
                this.backGroundUploads.photos[0].uploadStarted = true;
                this.localStorageService.store("uploadPhotos", this.backGroundUploads);
                await this.uploadImages(dataToSend, currentItem.filePath)
                this.removeTopPhoto()
                this.pendingUploads.next({ uploaded: this.originalLength - this.backGroundUploads.photos.length, total: this.originalLength });
            } catch (e) {
                console.log(e)
                this.backGroundUploads.photos[0].errorCount++;
                try {
                    if (e.message.includes('Not Found')) {
                        this.authenticationService.addQCErrorLog(this.backGroundUploads.photos[0].orderId, this.backGroundUploads.photos[0].photoType, "FILE_NOT_FOUND").subscribe();
                        this.removeTopPhoto()
                        this.pendingUploads.next({ uploaded: this.originalLength - this.backGroundUploads.photos.length, total: this.originalLength });
                    } else {
                        this.authenticationService.addQCErrorLog(this.backGroundUploads.photos[0].orderId, this.backGroundUploads.photos[0].photoType, "APP_TERMINATED").subscribe();
                        if (this.backGroundUploads.photos[0].errorCount > 2) {
                            this.removeTopPhoto()
                            this.pendingUploads.next({ uploaded: this.originalLength - this.backGroundUploads.photos.length, total: this.originalLength });
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
        this.pendingUploads.next({ uploaded: 0, total: 0 });
    }

    removeTopPhoto() {
        this.backGroundUploads.photos.splice(0, 1);
        this.localStorageService.store("uploadPhotos", this.backGroundUploads);
    }
}
