export enum ChatEvent {
    NEW_ORDER_MESSAGE = "new-message",
    NEW_DIRECT_MESSAGE = "new-direct-message",
    SEEN_DIRECT_MESSAGE = "seen-direct-message",
    REFRESH_DATA = "refresh-data",
    JOB_UPDATE = "job-update",
    LIVE_FEED = "live-feed",
}

export enum PushActions {
    new_booking = "NEW_BOOKING",
    reminder = "REMINDER",
    comms = "COMMS",
    order_comms = "ORDER_COMMS",
    schedule = "SCHEDULE_TAB",
    ondemand = "ONDEMAND_TAB"
}

export enum BookingStatus {
    Requested = 0,
    QuoteReq = 0.2,
    Quoted = 0.5,
    Accepted = 1,
    EnRoute = 2,
    Arrived = 3,
    ArrivalPhotos = 3.5,
    ArrivalChecklist = 3.6,
    ArrivalFormItems = 3.7,
    InProgress = 4,
    PhotoAdded = 4.5,
    CompletionChecklistItems = 4.6,
    CompletionFormItems = 4.7,
    Signature = 4.8,
    Done = 5,
    Cancelled = 6,
    Rejected = 7,
    PayByCash = 8,
    Attempted = 9,
    Attempting = 10,
    Reallocated = 11,
    Invoiced = 12,
    PendingInvBank = 12.5,
    PdInvCard = 13,
    PdInvBank = 14,
    InvVoid = 15,
    PayByCardRecord = 16,
    QuoteCompleted = 17,
    QuoteWon = 18,
    NoCharge = 19,
}