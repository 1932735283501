import { Component, Input } from '@angular/core';

@Component({
	selector: 'video-shell',
	templateUrl: './video-shell.component.html',
	styleUrls: ['./video-shell.component.scss']
})
export class VideoShellComponent {

	readonly FALLBACK_IMAGE = '../../../assets/img/blank_image.svg';

	private _src: string;

	@Input() lazyLoad: boolean = true;
	@Input() alt: string = '';

	@Input()
	set src(value) {
		this._src = value as string;
	}

	get src(): string {
		return this._src;
	}
}
