import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AutoLoginGuard implements CanActivate {

    constructor(private authService: AuthenticationService, private router: Router) { }

    canActivate(): boolean {
        if (this.authService.isUserLoggedIn()) {
            this.router.navigateByUrl('/dashboard', { replaceUrl: true });
            return false;
        }
        else {
            return true;
        }

    }
}
