import { EventEmitter, Injectable } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ConfirmationModalComponent } from '../common/modalPopups/confirmationModal.component';
import { CustomModalComponent } from '../common/modalPopups/customModal.component';
import { InputModalComponent } from '../common/modalPopups/inputModal.component/inputModal.component';

export enum AlertType {
    Info,
    Error,
    Success,
}

/**
 *Common Loading service to display loader
 */
@Injectable({
    providedIn: 'root'
})
export class AlertService {

    public modal: any;
    public toast: any;
    private alert: HTMLIonAlertElement;

    constructor(
        private modalController: ModalController,
        private toastController: ToastController,
        private alertController: AlertController,
    ) { }

    async showSuccessDialog(message?: string) {
        let modal: any = await this.modalController.create({
            component: CustomModalComponent,
            componentProps: {
                title: "Success",
                description: message,
                hideCancelButton: true,
                modalType: 'success',
                imageUrl: "assets/img/success.svg"
            },
            cssClass: 'alert-popup',
        });
        await modal.present();
    }

    async showErrorDialog(message?: string) {
        let modal: any = await this.modalController.create({
            component: CustomModalComponent,
            componentProps: {
                title: "Oops...",
                description: message,
                hideCancelButton: true,
                modalType: 'error',
                imageUrl: "assets/img/error.svg"
            },
            cssClass: 'alert-popup',
        });
        return await modal.present();
    }

    async showWarningDialog(title?: string, message?: string, okButtonText?: string, cancelButtonText?: string, modalClosedHandler?: EventEmitter<any>, hideCancelButton: boolean = false) {
        let modal: any = await this.modalController.create({
            component: CustomModalComponent,
            componentProps: {
                title: title || 'Confirm',
                description: message,
                modalType: 'warning',
                imageUrl: "assets/img/warning.svg",
                cancelButtonText: cancelButtonText || 'Cancel',
                okButtonText: okButtonText || 'Ok',
                hideCancelButton: hideCancelButton,
                modalClosed: modalClosedHandler
            },
            cssClass: 'alert-popup',
        });
        modal.onDidDismiss().then(({ data }) => {
            if (data !== 'close') {
                modalClosedHandler?.emit()
            }
        })
        return await modal.present();
    }

    async showToastMessage(message: string, alertType: AlertType = AlertType.Info, duration: number = 2400) {
        this.toast = await this.toastController.create({
            message: message,
            duration,
            color: this.getColorByAlertType(alertType),
            position: 'bottom'
        });
        await this.toast.present();
    }

    getColorByAlertType(alertType: AlertType): string {
        let color: string;

        switch (alertType) {
            case AlertType.Error:
                color = 'danger';
                break;
            case AlertType.Success:
                color = 'success';
                break;
        }

        return color;
    }

    confirmMessage(title: string, message: string, yesBtnText = 'Yes', NoBtnTxt = 'No'): Promise<boolean> {
        return new Promise((resolve) => {
            this.alertController.create({
                mode: 'ios',
                header: title?.replace(/\s/g, ' ') || '',
                message: message?.replace(/\s/g, ' ') || '',
                backdropDismiss: false,
                buttons: [
                    {
                        text: NoBtnTxt,
                        handler: () => resolve(false),
                    },
                    {
                        text: yesBtnText,
                        handler: () => resolve(true),
                    }
                ]
            }).then((alert) => {
                this.alert = alert;
                this.alert.present();
            });
        });
    }

    async showConfirmation(message:string, messageType:string,buttonText: string, dismissCb: (arg0: any) => void = null, confirmActionText?: string){
        let confirmationModal = await this.modalController.create({
            component:ConfirmationModalComponent,
            componentProps:{
                message: message,
                messageType: messageType,
                buttonText:buttonText,
                confirmActionText:confirmActionText,
            },
            cssClass: 'alert-popup full-popup'
        });
        confirmationModal.onDidDismiss().then((data) => {
            if (typeof dismissCb === 'function') {
                dismissCb(data);
            }
        });
        return await confirmationModal.present();
    }

    async showActionBox(title, message, okButtonText: string = "Yes", cancelButtonText: string = "No", modalType = "success") {
        return new Promise(async (resolve) => {
            let onModalClosed = new EventEmitter();
            onModalClosed.subscribe(res => {
                if (res) {
                    return resolve(res);
                }
                resolve(false);
    
            });
            let modal: any = await this.modalController.create({
                component: CustomModalComponent,
                componentProps: {
                    modalClosed: onModalClosed,
                    title: title,
                    description: message,
                    cancelButtonText: cancelButtonText,
                    okButtonText: okButtonText,
                    modalType: modalType,
                    imageUrl: "assets/img/custompin.svg",
                    isFullModal: true,
                },
                cssClass: 'alert-popup full-popup'
            });
            return await modal.present();
        })
    }

    showInputDialog(props: any = {}, cssClass: string = '') {
        return new Promise(async (resolve) => {
            let onModalClosed = new EventEmitter();
            onModalClosed.subscribe((res: any) => {
                if (res) {
                    return resolve(res);
                }
                resolve(false);
            });

            let defaultVal = {
                okButtonText: 'Yes',
                cancelButtonText: 'No',
                modalType: 'success',
                imageUrl: 'assets/img/warning.svg',
            };

            Object.entries(defaultVal).forEach(([key, value]) => {
                props[key] = props[key] || value;
            });

            let modal: any = await this.modalController.create({
                component: InputModalComponent,
                componentProps: {
                    modalClosed: onModalClosed,
                    props: props,
                },
                cssClass: 'alert-popup ' + cssClass,
            });
            return await modal.present();
        });
    }
}
