<div class="question" *ngIf="apiQuestion">
    <div class="question-item">
        <div class="question-item__title">
            <div>
                {{ apiQuestion.questionTitle }}
            </div>
        </div>

        <div *ngIf="!tempData.addMode">
            <!-- Vehicle -->
            <app-vehicle-description [vehicle]="apiQuestion.questionData"></app-vehicle-description>
        </div>

        <div *ngIf="tempData.addMode" class="question-item__api input-floating no-float" [class.loaded]="tempData.loaded">

            <!-- Vehicle -->
            <div *ngIf="apiQuestion.subType === 'vehicle'" (visibility)="onStateVisibilityChange($event)">
                <div class="license-plate-item" [class.loaded]="tempData.loaded" [class.is-invalid]="isSubmitted && !isApiQuestionValid">
                    <input type="text" name="plate" class="license-plate" [placeholder]="apiQuestion.questionPlaceholder || $any(apiQuestion).label || ''"
                        [(ngModel)]="tempData.plate" #plateInput="ngModel" (blur)="tempData.plate=tempData.plate?.toUpperCase();" [required]="apiQuestion.compulsory == true && tempData.addMode && !tempData.loaded"
                        [maxLength]="10" (change)="emitChange()" />
                        <!-- <control-messages [control]="plateInput" [message]="{ required: 'Required' }"></control-messages> -->
                    <!-- <label>{{apiQuestion.questionPlaceholder}}</label> -->

                    <select class="state-dropdown" name="state" (change)="emitChange()"
                        [(ngModel)]="tempData.state" #stateInput="ngModel"  [required]="apiQuestion.compulsory == true && tempData.addMode && !tempData.loaded">
                        <option *ngFor="let state of states" [value]="state">{{ state }}</option>
                    </select>

                    <button type="button" 
                        class="license-plate-button flat-btn grey-bg" 
                        (click)="fetchData(tempData, apiQuestion.subType)"
                        [disabled]="tempData.loading">
                        Search
                    </button>
                </div>
                <app-vehicle-description [vehicle]="apiQuestion.questionData" [newVehicle]="true"
                    *ngIf="tempData.loaded" (remove)="removeVehicle(apiQuestion)"></app-vehicle-description>
            </div>
        </div>

        <!-- Fallback Fields - Api Specific -->
        <div *ngIf="tempData.apiError">
            <div *ngFor="let option of API_QUES_ERROR[apiQuestion.subType]; let i = index;" class="manual-car-input">
                <div class="question-item__title" *ngIf="!floatingInput">{{ option }}</div>
                <div class="input-floating no-float">
                    <textarea
                        #fallbackInput="ngModel"
                        [name]="'fallbackInput' + i" 
                        (change)="emitChange()"
                        [(ngModel)]="_apiQuestion.questionData[option]"
                        [placeholder]="floatingInput ? ' ' : option"
                        [rows]="(option?.length || 1) | textareaRows"
                        [required]="apiQuestion.compulsory == true" 
                        clearInput autocapitalize="off"
                        appTextareaAutoresize
                    ></textarea>
                    <label *ngIf="floatingInput">{{ option }}</label>
                    <control-messages [control]="fallbackInput" [message]="{ required: 'Required' }"></control-messages>
                </div>
            </div>
        </div>

        <!-- Custom Fields -->
        <div *ngFor="let option of apiQuestion.subOptions;let i = index;" class="manual-car-input">
            <div class="question-item__title" *ngIf="!floatingInput">{{ option }}</div>
            <div class="input-floating no-float">
                <textarea #optionInput="ngModel"
                    [name]="'manualInput' + i" 
                    (change)="emitChange()"
                    [(ngModel)]="_apiQuestion.questionData[option]"
                    [placeholder]="floatingInput ? ' ' : option"
                    [rows]="(option?.length || 1) | textareaRows"
                    [required]="apiQuestion.compulsory == true" 
                    clearInput autocapitalize="off"
                    appTextareaAutoresize
                ></textarea>
                <label *ngIf="floatingInput">{{ option }}</label>
                <control-messages [control]="optionInput" [message]="{ required: 'Required' }"></control-messages>
            </div>
        </div>
    </div>
</div>

<!-- Vehicle Loading -->
<div *ngIf="tempData?.loading && apiQuestion?.subType === 'vehicle'" class="loader-con-license">
    <div *ngIf="tempData.loading"
        [style.background-image]="'url(https://infotracer.com/img/plates/blank/' + tempData.state + '.png)'"
        class="car-infographic-plate-loader fade-out-up-slow popup-fade-in-slow">
        <p>L</p>
        <p>o</p>
        <p>a</p>
        <p>d</p>
        <p>i</p>
        <p>n</p>
        <p>g</p>
    </div>
</div>