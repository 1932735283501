import { Injectable } from '@angular/core';

@Injectable({
    providedIn:'root'
})
export class FielddLocaleService {

  private defaultCurrency="USD";
  private listOfCurrencies = [
    {
      country: 'AF',
      symbol: 'AFN'
    },
    {
      country: 'AL',
      symbol: 'ALL'
    },
    {
      country: 'DZ',
      symbol: 'DZD'
    },
    {
      country: 'AS',
      symbol: 'USD'
    },
    {
      country: 'AD',
      symbol: 'EUR'
    },
    {
      country: 'AO',
      symbol: 'AOA'
    },
    {
      country: 'AI',
      symbol: 'USD'
    },
    {
      country: 'AQ',
      symbol: 'USD'
    },
    {
      country: 'AG',
      symbol: 'XCD'
    },
    {
      country: 'AR',
      symbol: 'ARS'
    },
    {
      country: 'AM',
      symbol: 'AMD'
    },
    {
      country: 'AW',
      symbol: 'AWG'
    },
    {
      country: 'AC',
      symbol: 'USD'
    },
    {
      country: 'AU',
      symbol: '$'
    },
    {
      country: 'AT',
      symbol: 'EUR'
    },
    {
      country: 'AZ',
      symbol: 'AZN'
    },
    {
      country: 'BS',
      symbol: 'BSD'
    },
    {
      country: 'BH',
      symbol: 'BHD'
    },
    {
      country: 'BD',
      symbol: 'BDT'
    },
    {
      country: 'BB',
      symbol: 'BBD'
    },
    {
      country: 'BY',
      symbol: 'BYN'
    },
    {
      country: 'BE',
      symbol: 'EUR'
    },
    {
      country: 'BZ',
      symbol: 'BZD'
    },
    {
      country: 'BJ',
      symbol: 'XOF'
    },
    {
      country: 'BM',
      symbol: 'USD'
    },
    {
      country: 'BT',
      symbol: 'BTN'
    },
    {
      country: 'BO',
      symbol: 'BOB'
    },
    {
      country: 'BA',
      symbol: 'USD'
    },
    {
      country: 'BW',
      symbol: 'BWP'
    },
    {
      country: 'BV',
      symbol: 'USD'
    },
    {
      country: 'BR',
      symbol: 'BRL'
    },
    {
      country: 'IO',
      symbol: 'USD'
    },
    {
      country: 'BN',
      symbol: 'BND'
    },
    {
      country: 'BG',
      symbol: 'BGN'
    },
    {
      country: 'BF',
      symbol: 'USD'
    },
    {
      country: 'BI',
      symbol: 'USD'
    },
    {
      country: 'KH',
      symbol: 'USD'
    },
    {
      country: 'CM',
      symbol: 'XAF'
    },
    {
      country: 'CA',
      symbol: 'CAD'
    },
    {
      country: 'CV',
      symbol: 'USD'
    },
    {
      country: 'KY',
      symbol: 'USD'
    },
    {
      country: 'CF',
      symbol: 'USD'
    },
    {
      country: 'TD',
      symbol: 'USD'
    },
    {
      country: 'CL',
      symbol: 'CLP'
    },
    {
      country: 'CN',
      symbol: 'CNY'
    },
    {
      country: 'CX',
      symbol: 'USD'
    },
    {
      country: 'CC',
      symbol: 'USD'
    },
    {
      country: 'CO',
      symbol: 'COP'
    },
    {
      country: 'KM',
      symbol: 'USD'
    },
    {
      country: 'CG',
      symbol: 'USD'
    },
    {
      country: 'CD',
      symbol: 'USD'
    },
    {
      country: 'CK',
      symbol: 'NZD'
    },
    {
      country: 'CR',
      symbol: 'CRC'
    },
    {
      country: 'CI',
      symbol: 'XOF'
    },
    {
      country: 'HR',
      symbol: 'EUR'
    },
    {
      country: 'CU',
      symbol: 'USD'
    },
    {
      country: 'CY',
      symbol: 'EUR'
    },
    {
      country: 'CZ',
      symbol: 'CZK'
    },
    {
      country: 'DK',
      symbol: 'DKK'
    },
    {
      country: 'DJ',
      symbol: 'USD'
    },
    {
      country: 'DM',
      symbol: 'XCD'
    },
    {
      country: 'DO',
      symbol: 'DOP'
    },
    {
      country: 'TP',
      symbol: 'USD'
    },
    {
      country: 'EC',
      symbol: 'USD'
    },
    {
      country: 'EG',
      symbol: 'EGP'
    },
    {
      country: 'SV',
      symbol: 'USD'
    },
    {
      country: 'GQ',
      symbol: 'USD'
    },
    {
      country: 'ER',
      symbol: 'ERN'
    },
    {
      country: 'EE',
      symbol: 'EUR'
    },
    {
      country: 'ET',
      symbol: 'ETB'
    },
    {
      country: 'FK',
      symbol: 'FKP'
    },
    {
      country: 'FO',
      symbol: 'DKK'
    },
    {
      country: 'FJ',
      symbol: 'FJD'
    },
    {
      country: 'FI',
      symbol: 'EUR'
    },
    {
      country: 'FR',
      symbol: 'EUR'
    },
    {
      country: 'GF',
      symbol: 'EUR'
    },
    {
      country: 'PF',
      symbol: 'USD'
    },
    {
      country: 'TF',
      symbol: 'EUR'
    },
    {
      country: 'GA',
      symbol: 'USD'
    },
    {
      country: 'GM',
      symbol: 'GMD'
    },
    {
      country: 'GE',
      symbol: 'USD'
    },
    {
      country: 'DE',
      symbol: 'EUR'
    },
    {
      country: 'GH',
      symbol: 'USD'
    },
    {
      country: 'GI',
      symbol: 'GBP'
    },
    {
      country: 'GR',
      symbol: 'EUR'
    },
    {
      country: 'GL',
      symbol: 'DKK'
    },
    {
      country: 'GD',
      symbol: 'XCD'
    },
    {
      country: 'GP',
      symbol: 'USD'
    },
    {
      country: 'GU',
      symbol: 'USD'
    },
    {
      country: 'GT',
      symbol: 'GTQ'
    },
    {
      country: 'GG',
      symbol: 'GBP'
    },
    {
      country: 'GN',
      symbol: 'USD'
    },
    {
      country: 'GW',
      symbol: 'USD'
    },
    {
      country: 'GY',
      symbol: 'GYD'
    },
    {
      country: 'HT',
      symbol: 'USD'
    },
    {
      country: 'HM',
      symbol: 'USD'
    },
    {
      country: 'HN',
      symbol: 'HNL'
    },
    {
      country: 'HK',
      symbol: 'HKD'
    },
    {
      country: 'HU',
      symbol: 'HUF'
    },
    {
      country: 'IS',
      symbol: 'ISK'
    },
    {
      country: 'IN',
      symbol: 'INR'
    },
    {
      country: 'ID',
      symbol: 'IDR'
    },
    {
      country: 'IR',
      symbol: 'IRR'
    },
    {
      country: 'IQ',
      symbol: 'IQD'
    },
    {
      country: 'IE',
      symbol: 'EUR'
    },
    {
      country: 'IM',
      symbol: 'GBP'
    },
    {
      country: 'IL',
      symbol: 'ILS'
    },
    {
      country: 'IT',
      symbol: 'EUR'
    },
    {
      country: 'JM',
      symbol: 'JMD'
    },
    {
      country: 'JP',
      symbol: 'JPY'
    },
    {
      country: 'JE',
      symbol: 'GBP'
    },
    {
      country: 'JO',
      symbol: 'JOD'
    },
    {
      country: 'KZ',
      symbol: 'USD'
    },
    {
      country: 'KE',
      symbol: 'KES'
    },
    {
      country: 'KI',
      symbol: 'USD'
    },
    {
      country: 'KP',
      symbol: 'KRW'
    },
    {
      country: 'KR',
      symbol: 'KRW'
    },
    {
      country: 'KW',
      symbol: 'KWD'
    },
    {
      country: 'KG',
      symbol: 'USD'
    },
    {
      country: 'LA',
      symbol: 'USD'
    },
    {
      country: 'LV',
      symbol: 'LVL'
    },
    {
      country: 'LB',
      symbol: 'LBP'
    },
    {
      country: 'LS',
      symbol: 'ZAR'
    },
    {
      country: 'LR',
      symbol: 'USD'
    },
    {
      country: 'LY',
      symbol: 'LYD'
    },
    {
      country: 'LI',
      symbol: 'CHF'
    },
    {
      country: 'LT',
      symbol: 'LTL'
    },
    {
      country: 'LU',
      symbol: 'EUR'
    },
    {
      country: 'MO',
      symbol: 'MOP'
    },
    {
      country: 'MK',
      symbol: 'MKD'
    },
    {
      country: 'MG',
      symbol: 'USD'
    },
    {
      country: 'MW',
      symbol: 'MWK'
    },
    {
      country: 'MY',
      symbol: 'MYR'
    },
    {
      country: 'MV',
      symbol: 'USD'
    },
    {
      country: 'ML',
      symbol: 'USD'
    },
    {
      country: 'MT',
      symbol: 'EUR'
    },
    {
      country: 'MH',
      symbol: 'USD'
    },
    {
      country: 'MQ',
      symbol: 'EUR'
    },
    {
      country: 'MR',
      symbol: 'USD'
    },
    {
      country: 'MU',
      symbol: 'MUR'
    },
    {
      country: 'YT',
      symbol: 'EUR'
    },
    {
      country: 'MX',
      symbol: 'MXN'
    },
    {
      country: 'FM',
      symbol: 'USD'
    },
    {
      country: 'MD',
      symbol: 'USD'
    },
    {
      country: 'MC',
      symbol: 'EUR'
    },
    {
      country: 'MN',
      symbol: 'USD'
    },
    {
      country: 'MS',
      symbol: 'XCD'
    },
    {
      country: 'MA',
      symbol: 'MAD'
    },
    {
      country: 'MZ',
      symbol: 'USD'
    },
    {
      country: 'MM',
      symbol: 'USD'
    },
    {
      country: 'NA',
      symbol: 'USD'
    },
    {
      country: 'NR',
      symbol: 'AUD'
    },
    {
      country: 'NP',
      symbol: 'NPR'
    },
    {
      country: 'NL',
      symbol: 'EUR'
    },
    {
      country: 'AN',
      symbol: 'ANG'
    },
    {
      country: 'NC',
      symbol: 'USD'
    },
    {
      country: 'NZ',
      symbol: 'NZD'
    },
    {
      country: 'NI',
      symbol: 'NIO'
    },
    {
      country: 'NE',
      symbol: 'USD'
    },
    {
      country: 'NG',
      symbol: 'NGN'
    },
    {
      country: 'NU',
      symbol: 'NZD'
    },
    {
      country: 'NF',
      symbol: 'AUD'
    },
    {
      country: 'MP',
      symbol: 'USD'
    },
    {
      country: 'NO',
      symbol: 'NOK'
    },
    {
      country: 'OM',
      symbol: 'OMR'
    },
    {
      country: 'PK',
      symbol: 'PKR'
    },
    {
      country: 'PW',
      symbol: 'USD'
    },
    {
      country: 'PS',
      symbol: 'USD'
    },
    {
      country: 'PA',
      symbol: 'PAB'
    },
    {
      country: 'PG',
      symbol: 'PGK'
    },
    {
      country: 'PY',
      symbol: 'PYG'
    },
    {
      country: 'PE',
      symbol: 'PEN'
    },
    {
      country: 'PH',
      symbol: 'PHP'
    },
    {
      country: 'PN',
      symbol: 'USD'
    },
    {
      country: 'PL',
      symbol: 'PLN'
    },
    {
      country: 'PT',
      symbol: 'EUR'
    },
    {
      country: 'PR',
      symbol: 'USD'
    },
    {
      country: 'QA',
      symbol: 'QAR'
    },
    {
      country: 'RE',
      symbol: 'USD'
    },
    {
      country: 'RO',
      symbol: 'RON'
    },
    {
      country: 'RU',
      symbol: 'RUB'
    },
    {
      country: 'RW',
      symbol: 'RWF'
    },
    {
      country: 'KN',
      symbol: 'XCD'
    },
    {
      country: 'LC',
      symbol: 'XCD'
    },
    {
      country: 'VC',
      symbol: 'XCD'
    },
    {
      country: 'WS',
      symbol: 'WST'
    },
    {
      country: 'SM',
      symbol: 'EUR'
    },
    {
      country: 'ST',
      symbol: 'USD'
    },
    {
      country: 'SA',
      symbol: 'SAR '
    },
    {
      country: 'SN',
      symbol: 'USD'
    },
    {
      country: 'CS',
      symbol: 'EUR'
    },
    {
      country: 'RS',
      symbol: 'RSD'
    },
    {
      country: 'SC',
      symbol: 'USD'
    },
    {
      country: 'SL',
      symbol: 'USD'
    },
    {
      country: 'SG',
      symbol: 'SGD'
    },
    {
      country: 'SK',
      symbol: 'USD'
    },
    {
      country: 'SI',
      symbol: 'EUR'
    },
    {
      country: 'SB',
      symbol: 'SBD'
    },
    {
      country: 'SO',
      symbol: 'USD'
    },
    {
      country: 'ZA',
      symbol: 'R'
    },
    {
      country: 'GS',
      symbol: 'USD'
    },
    {
      country: 'ES',
      symbol: 'EUR'
    },
    {
      country: 'LK',
      symbol: 'LKR'
    },
    {
      country: 'SH',
      symbol: 'USD'
    },
    {
      country: 'PM',
      symbol: 'USD'
    },
    {
      country: 'SD',
      symbol: 'USD'
    },
    {
      country: 'SR',
      symbol: 'USD'
    },
    {
      country: 'SJ',
      symbol: 'USD'
    },
    {
      country: 'SZ',
      symbol: 'SZL'
    },
    {
      country: 'SE',
      symbol: 'SEK'
    },
    {
      country: 'CH',
      symbol: 'CHF'
    },
    {
      country: 'SY',
      symbol: 'SYP'
    },
    {
      country: 'TW',
      symbol: 'TWD'
    },
    {
      country: 'TJ',
      symbol: 'USD'
    },
    {
      country: 'TZ',
      symbol: 'TZS'
    },
    {
      country: 'TH',
      symbol: 'THB'
    },
    {
      country: 'TG',
      symbol: 'USD'
    },
    {
      country: 'TK',
      symbol: 'USD'
    },
    {
      country: 'TO',
      symbol: 'TOP'
    },
    {
      country: 'TT',
      symbol: 'TTD'
    },
    {
      country: 'TN',
      symbol: 'TND'
    },
    {
      country: 'TR',
      symbol: 'TRY'
    },
    {
      country: 'TM',
      symbol: 'USD'
    },
    {
      country: 'TC',
      symbol: 'USD'
    },
    {
      country: 'TV',
      symbol: 'USD'
    },
    {
      country: 'UM',
      symbol: 'USD'
    },
    {
      country: 'UG',
      symbol: 'UGX'
    },
    {
      country: 'UA',
      symbol: 'UAH'
    },
    {
      country: 'AE',
      symbol: 'AED'
    },
    {
      country: 'UK',
      symbol: '£'
    },
    {
      country: 'GB',
      symbol: 'GBP'
    },
    {
      country: 'US',
      symbol: 'USD'
    },
    {
      country: 'UY',
      symbol: 'USD'
    },
    {
      country: 'UZ',
      symbol: 'UZS'
    },
    {
      country: 'VU',
      symbol: 'VUV'
    },
    {
      country: 'VA',
      symbol: 'USD'
    },
    {
      country: 'VE',
      symbol: 'VEF'
    },
    {
      country: 'VN',
      symbol: 'VND'
    },
    {
      country: 'VG',
      symbol: 'USD'
    },
    {
      country: 'VI',
      symbol: 'USD'
    },
    {
      country: 'WF',
      symbol: 'USD'
    },
    {
      country: 'EH',
      symbol: 'USD'
    },
    {
      country: 'YE',
      symbol: 'YER'
    },
    {
      country: 'ZM',
      symbol: 'USD'
    },
    {
      country: 'ZW',
      symbol: 'ZWL'
    }
  ];
  constructor() { }

  public getCurrentCurrencySymbol(country: string): string {
    let currency = this.listOfCurrencies.find(list => list.country === country);
    return currency?currency.symbol:this.defaultCurrency
  }

}