import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../app/guards/auth.guard';
import { AutoLoginGuard } from '../app/guards/autoLogin.guard';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
    {
        path: 'auth',
        canActivate: [AutoLoginGuard],
        loadChildren: () => import("./modules/authModule/auth.module").then(m => m.AuthModule)
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import("./modules/dashBoardModule/dashboard.module").then(m => m.DashboardModule)
    },
    {
        path: 'calendar',
        canActivate: [AuthGuard],
        loadChildren: () => import("./modules/calendarModule/calendar.module").then(m => m.CalendarModule)
    },
    {
        path: 'history',
        canActivate: [AuthGuard],
        loadChildren: () => import("./modules/historyModule/history.module").then(m => m.HistoryModule)
    },
    {
        path: 'customer',
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () => import("./modules/customerModule/customer.module").then(m => m.CustomerModule)
    },
    {
        path: 'staff',
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: () => import("./modules/staffModule/staff.module").then(m => m.StaffModule)
    },
    {
        path: 'timeSheets',
        canActivate: [AuthGuard],
        loadChildren: () => import("./modules/timeSheetsModule/timeSheets.module").then(m => m.TimeSheetModule)
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import("./modules/accountProfileModule/accountProfile.module").then(m => m.AccountProfileModule)
    },
    {
        path: 'status',
        canActivate: [AuthGuard],
        loadChildren: () => import("./modules/statusModule/status.module").then(m => m.StatusModule)
    },
    {
        path: 'customerChat/:orderId',
        canActivate: [AuthGuard],
        loadChildren: () => import("./modules/customerChatModule/customerChat.module").then(m => m.CustomerChatModule)
    },
    {
        path: '**',
        redirectTo: '/auth/login',
        pathMatch: 'full'
    }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
