import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';

import { Subscription, lastValueFrom } from 'rxjs';

import { FileModel, ModalComponents, ParsedGeolocation, ParsedGoogleAddress } from '@data/models/shared.model';

import { ModalService } from '@services/modal.service';
import { AlertService, AlertType } from '@services/alert.service';
import { CommonService } from '@services/common.service';
import { LoadingService } from '@services/loading.service';
import { AuthenticationService } from '@services/auth.service';
import { FileManagementService } from '@services/file-management.service';
import { LocationTrackerService } from '@services/location-tracker.service';

import { FormatAddressPipe } from '@pipes/address.pipe';

import { StaffService } from '../../staff.service';
import { AccountProfileService } from '@modules/accountProfileModule/accountProfile.service';
import { checkFormValidity } from '@utils/helper-functions';

@Component({
    selector: 'app-staff-details',
    templateUrl: 'staff-details.component.html',
    styleUrls: ['staff-details.component.scss']
})
export class StaffDetailsComponent implements OnInit, OnDestroy {

    isOnProfile = false;

    company: any;
    currentUser: any;
    staff: any;

    staffStats: any = {
        onTime: 0,
        completed: 0,
        rating: 0,
        ratedJobs: 0
    };

    staffId: string;
    deviceType: string;
    version: string;

    staffForm: FormGroup = new FormGroup({
        emailId: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')])),
        firstName: new FormControl('', Validators.compose([Validators.required])),
        lastName: new FormControl('', Validators.compose([Validators.required])),
        address: new FormControl('', Validators.compose([Validators.required])),
        phone: new FormControl('', Validators.compose([Validators.required])),
        bsb: new FormControl('', Validators.compose([])),
        account: new FormControl('', Validators.compose([])),
        bio: new FormControl('', Validators.compose([])),
        abn: new FormControl('', Validators.compose([])),
    });

    jobs: any[] = [];
    isLoadingHistory: boolean = false;

    validPhoneNumber: string;

    addressObject: { address_city: string; address_country: string; address_line1: string; address_postcode: string; address_state: string; };
    staffLocation: ParsedGeolocation;

    validationMessages = {
        email: {
            required: 'Required',
            pattern: 'Enter a valid email',
        },
    };

    isLoading: boolean = false;

    Accordion: typeof Accordion = Accordion;
    openAccordion: Accordion;

    private subscriptions: Array<Subscription> = [];

    constructor(
        private router: Router,
        private platform: Platform,
        private route: ActivatedRoute,
        private staffService: StaffService,
        private alertService: AlertService,
        private modalService: ModalService,
        private navController: NavController,
        private commonService: CommonService,
        private addressPipe: FormatAddressPipe,
        private loadingService: LoadingService,
        private authService: AuthenticationService,
        private profileService: AccountProfileService,
        private fileManagementService: FileManagementService,
        private locationTrackerService: LocationTrackerService,
    ) { }

    ngOnInit() {
        if (this.router.url === '/profile') {
            this.isOnProfile = true;

            this.deviceType = this.platform.is('ios') ? 'iOS' : 'Android';
            this.version = this.authService.getAppVersion();

            this.loadingService.httpWrapperLoader(
                this.authService.refreshPartnerProfile()
            ).catch(() => {
                this.alertService.showErrorDialog('Temporary delay getting staff details, please try again');
            });
        } else {
            this.subscriptions.push(
                this.route.paramMap.subscribe((params) => {
                    this.staffId = params.get('id');
                    this.getStaffDetails();
                    this.getStaffStats();
                    this.getStaffJobs();
                })
            );
        }

        this.subscriptions.push(
            this.authService.company$.subscribe((company) => {
                this.company = company;
            })
        );

        let hasInitialized = false;

        this.subscriptions.push(
            this.authService.staff$.subscribe((staff) => {
                this.currentUser = staff;

                if (this.isOnProfile) {
                    this.staffId = staff._id;
                    this.staff = JSON.parse(JSON.stringify(this.currentUser));
                    this.addressObject = this.staff.address;

                    if (!hasInitialized) {
                        hasInitialized = true;

                        this.getStaffStats();
                        this.setupStaffForm();
                    }
                }
            })
        );
    }

    setupStaffForm() {
        this.staffForm.patchValue({
            emailId: this.staff.emailID,
            firstName: this.staff.firstName,
            lastName: this.staff.lastName,
            address: this.addressPipe.transform(this.staff.address),
            phone: this.staff.phone,
            bsb: this.staff.recipientData?.bank_account?.bsb || '',
            account: this.staff.recipientData?.bank_account?.number || '',
            bio: this.staff.bio,
            abn: this.staff.abn,
        });

        this.staff.additionalImages = this.staff.additionalImages || [];

        this.validPhoneNumber = this.staff.phone;
    }

    getStaffDetails() {
        return this.loadingService.httpWrapperLoader(
            this.staffService.getStaffDetails({ partnerId: this.staffId })
        ).then((res) => {
            if (res?.details) {
                this.staff = res.details;
                this.addressObject = this.staff.address;
                if (this.staff.device?.[0]?.deviceType) {
                    this.deviceType = this.staff.device[0].deviceType;
                    this.version = this.staff.appVersion;
                }
                this.setupStaffForm();
                return;
            } 
            throw new Error();
        }).catch(() => {
            this.alertService.showErrorDialog('Temporary delay getting staff details, please try again');
        });
    }

    getStaffStats() {
        return this.profileService.getProfileStats(this.staffId).then((res) => {
            if (res?.success) {
                this.staffStats = res.data;
            }
        }).catch(() => {
            // 
        });
    }

    getStaffJobs() {
        this.isLoadingHistory = true;

        const dataToSend = {
            partnerId: this.staffId,
            skip: 0,
            limit: 10,
            sortOn: 'scheduledOn'
        };

        return this.staffService.getStaffJobs(dataToSend).then((res) => {
            if (res?.jobs) {
                this.jobs = [...this.jobs, ...res.jobs.map((j: any) => ({
                    ...j,
                    mainVariant: j.variants?.length ? (j.variants[0].fullName || j.variants[0].name) : j.inventory[0].name,
                    ...this.commonService.getStatusTagAndColor(j.status),
                }))];
            } else {
                this.alertService.showErrorDialog('Temporary delay getting staff jobs, please try again');
            }
        }).catch(() => {
            this.alertService.showErrorDialog('Temporary delay getting staff jobs, please try again');
        }).finally(() => {
            this.isLoadingHistory = false;
        });
    }

    afterUpdating(data: any) {
        this.staff.phone = data.phone;
        this.staff.emailID = data.emailID;
        this.staff.address = data.address;
        this.staff.firstName = data.firstName;
        this.staff.lastName = data.lastName;
        this.staff.bio = data.bio;
        this.staff.bank_account = {
            bsb: data.bsb,
            number: data.accountNumber,
        };
        this.staff.bio = data.bio;

        if (data.location) {
            this.staff.location = data.location;
        }

        if (this.isOnProfile || this.staffId === this.currentUser._id) {
            Object.entries(this.staff).forEach(([key, value]) => {
                this.currentUser[key] = value;
            });
            this.authService.setCurrentUserData(this.currentUser);
        }
    }

    updateStaff() {
        if (!checkFormValidity(this.staffForm)) {
            return this.alertService.showToastMessage('Make sure you have filled all the fields, then try again', AlertType.Error, 1500);
        }

        if (this.isLoading) return;
        this.isLoading = true;

        const formData = this.staffForm.value;

        const dataToSend: any = {
            phone: this.validPhoneNumber,
            emailID: formData.emailId,
            address: this.addressObject,
            firstName: formData.firstName,
            lastName: formData.lastName,
            bio: formData.bio || '',
            bsb: formData.bsb || '',
            accountNumber: formData.account || '',
        };

        if (this.isOnProfile && this.staffLocation) {
            dataToSend.location = this.staffLocation;
        }

        return this.loadingService.httpWrapperLoader(
            this.staffService.updateStaffDetails(this.staffId, dataToSend),
            false
        ).then((res) => {
            if (res?.success) {
                this.afterUpdating(dataToSend);
                return this.alertService.showSuccessDialog('Staff Profile Updated');
            }
            this.alertService.showErrorDialog(res?.message || 'Temporary delay updating staff, please try again');
        }).catch((e) => {
            this.alertService.showErrorDialog('Temporary delay updating staff, please try again');
        }).finally(() => {
            this.isLoading = false;
        });
    }

    uploadImage(files: FileModel[], imageType: string) {
        const dataToSend = {
            from: 'staff',
            imageType: imageType,
            partnerId: this.staffId,
        };

        return this.loadingService.httpWrapperLoader(
            this.fileManagementService.uploadImagesV2(dataToSend, files[0])
        ).then((res) => {
            if (!res?.success) throw new Error();

            switch (imageType) {
                case 'profile':
                    this.staff.image = res.imagePath;
                    break;
                case 'bio':
                    this.staff.additionalImages.push(res.imagePath);
                    break;
            }
        }).catch(() => {
            this.alertService.showErrorDialog('Temporary delay uploading image, please try again');
        });
    }

    zoomImage(imageIndex: number) {
        const imageSource = this.staff.additionalImages[imageIndex];
        if (!imageSource) return;

        //when delete is clicked in zoom modal component
        const deleteClicked = new EventEmitter();
        deleteClicked.subscribe(() => {
            const dataToSend = {
                from: 'staff',
                imageType: 'bio',
                filePath: imageSource,
                partnerId: this.staffId,
            }

            this.fileManagementService.deleteImage(dataToSend).then((response) => {
                if (!response?.success) throw new Error();
                this.alertService.showSuccessDialog('Photo removed');
                this.staff.additionalImages[imageIndex] = '';
            }).catch(() => {
                this.alertService.showErrorDialog('Temporary delay deleting image, please try again');
            });
        });

        return this.commonService.zoomImage(imageSource, deleteClicked, false);
    }

    showAllJob() {
        this.modalService.openComponentModal(ModalComponents.JobListComponent, { selectedId: this.staffId, userType: 'staff', jobs: this.jobs });
    }

    startJob(jobId: string) {
        return this.loadingService.httpWrapperLoader(
            lastValueFrom(this.commonService.getOrderDetails(jobId))
        ).then((response) => {
            if (response?.success && response?.data?._id) {
                this.commonService.setCurrentJob(response.data, 'staff/' + this.staffId);
                return this.navController.navigateRoot('/status', { animationDirection: 'forward' });
            }
            throw new Error();
        }).catch(() => {
            this.alertService.showErrorDialog('Temporary delay getting job details, please try again');
        });
    }

    resetList() {
        this.jobs = [];
    }

    placeSelected(googleAddress: ParsedGoogleAddress) {
        const { addressObject, location } = googleAddress;

        this.addressObject = {
            address_city: addressObject.city,
            address_country: addressObject.country,
            address_line1: addressObject.street,
            address_postcode: addressObject.postcode,
            address_state: addressObject.state,
        };

        this.staffLocation = location;
    }

    goBack() {
        return this.navController.navigateRoot(this.isOnProfile ? '/dashboard' : '/staff', { animationDirection: 'back' });
    }

    openDeleteStaffPopUp() {
        this.loadingService.httpWrapperLoader(this.staffService.getPendingJobs(this.staffId), true).then(data => {
            if (data?.success) {

                let string = `Are you sure you want to delete your profile?`;

                if (data.jobs) {
                    string += `\n\nYou have ${data.jobs} jobs booked which will also delete. \n\nThis cannot be undone.`
                }

                this.alertService.showConfirmation(string, 'Delete Profile', 'Back', (data) => {
                    if (data?.data) {
                        this.deleteStaff();
                    }
                }, 'Delete Profile');
            } else {
                this.alertService.showErrorDialog('Temporary delay deleting staff, please try again');
            }
        }).catch(e => {
            this.alertService.showErrorDialog('Temporary delay deleting staff, please try again');
        })
    }

    deleteStaff() {
        this.loadingService.httpWrapperLoader(this.staffService.deleteStaff(this.staffId), true).then(data => {
            if (data?.success) {
                this.alertService.showSuccessDialog('Your profile has been deleted');
                this.authService.resetStyleAndLogout();
            } else {
                this.alertService.showErrorDialog('Temporary delay deleting staff, please try again');
            }
        }).catch(e => {
            this.alertService.showErrorDialog('Temporary delay deleting staff, please try again');
        })
    }

    logout() {
        if (this.fileManagementService?.backGroundUploads?.photos?.length > 0) {
            let responseHandler = new EventEmitter<any>();
            this.alertService.showWarningDialog('Warning', 'You have pending uploads, are sure your want to cancel uploads and logout?', 'Continue', 'No', responseHandler);
            responseHandler.subscribe((res: boolean) => {
                if (res) {
                    this.fileManagementService.pendingUploads.next({ uploaded: 0, total: 0 });
                    this.doLogout();
                }
            });
        } else {
            this.doLogout();
        }
    }

    doLogout() {
        if (this.locationTrackerService.backgroundLocationPermission) {
            this.locationTrackerService.stopBackgroundTracking();
        }
        this.authService.logout();
    }

    toggleAccordion(accordion: Accordion) {
        if (this.openAccordion === accordion) {
            this.openAccordion = null;
        } else {
            this.openAccordion = accordion;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}

enum Accordion {
    Details,
    History,
}