import { Directive, HostListener, Input } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx'

@Directive({
    selector: '[vibratable]'
})
export class VibratableDirective {


    @Input("vibrateDuration") vibrateDuration: number = 30;
    constructor(
        private vibration: Vibration,
    ) {
    }

    @HostListener('click', ['$event.target'])
    onClick(target) {
        if(this.vibration){
            this.vibration.vibrate(this.vibrateDuration);
        }
        
    }
}