import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as moment from 'moment-timezone';
import { BookingStatus } from '../../../data/models/enums';
import { AuthenticationService } from '../../../services/auth.service';
import { CommonService } from '../../../services/common.service';
import { CommonHelperService } from '../../../services/helper.service';
import { DateService } from '@services/date-service';

@Component({
    selector: 'schedule-job-item',
    templateUrl: 'scheduleJobItem.component.html'
})
export class ScheduleItemComponent implements OnInit {

    private _job: any = {};
    get job() {
        return this._job;
    }

    @Input() set job(val: any) {
        this._job = val;

        if (val._id === 'LINE') {
            return;
        }

        if (val?.variants?.length) {
            this.serviceDescription = this.formatVariantName(val.variants?.[0]);
        } else if (val?.inventory?.length) {
            this.serviceDescription = this.getInventoryDescription(val);
        }
    }
    
    @Input('jobSection') jobSection: any = {};
    @Input('isOnDemandJob') isOnDemandJob: boolean = false;
    @Input('hasNotification') hasNotification: boolean = false;
    @Input() set jobIndex(val: number) {
        val++;
        if (val > 15) {
            val = val % 15;
            val === 0 ? 1 : val;
        }
        this.animationIndex = val;
    };
    @Input('animate') animate: boolean = false;
    @Output('jobClicked') jobClicked = new EventEmitter<any>();
    @Input() showPartnerName: boolean = false;

    animationIndex: number = 0;
    company: any = {};
    hidePricing: boolean = false;

    startTime: string = '';
    endTime: string = '';
    createdOn: string = '';
    serviceTime: any;

    variantImageUrl: string;
    bookingStatus: typeof BookingStatus = BookingStatus;

    serviceDescription: string = '';

    constructor(private commonService: CommonService,
        private helperService: CommonHelperService,
        private authenticationService: AuthenticationService,
        private navController: NavController,
        private dateService: DateService
    ) {
        this.company = this.authenticationService.getCurrentCompany()
        this.hidePricing = !this.commonService.shouldShowPricing();
    }

    ngOnInit() {
        this.startTime = this.job.recurringBookingId?._id ? this.job.recurringBookingId.startTime : this.changeFormat(this.job, 'h:mma');
        // let jobTime = moment(this.job.createdOn).tz(this.job.timezone);
        // if(jobTime.isSame(moment(), 'day')){
        //     this.createdOn = this.dateService.autoFormat(jobTime,'h:mma');
        // }
        // else{
        //     this.createdOn = this.dateService.autoFormat(jobTime,'MMM Do');
        // }
        
        if(this.job.mergeSlotCount) {
            let timeSlots = this.job.mergeSlotCount;
            let hr = this.company.timeBetweenBookings.time;
            let min = this.company.timeBetweenBookings.min;

            let serviceMin = (timeSlots * (hr * 60 + min));

            this.serviceTime = `${Math.floor(serviceMin / 60).toFixed(0)}h ${Math.floor(serviceMin % 60).toFixed(0)}m`;

           
            const startTime = moment(this.startTime, ['h:mma']);
            const endTime = startTime.clone().add(serviceMin, 'm');

            const inLunchHours = this.helperService.checkInLunchHours(this.company.companyHours, startTime, endTime);
            if (inLunchHours) {
                endTime.add(inLunchHours, 'm');
            }
            this.endTime = this.dateService.autoFormat(endTime,'h:mma');
        }

        if(this.job.multiDays?.enabled) {
            const days = this.job.multiDays.workingDays?.length || 1;
            this.serviceTime = `${days} day${days > 1 ? 's' : ''}`;

            this.endTime = this.dateService.autoFormat(moment(this.job.multiDays?.endScheduledOn), 'h:mma');
        }

        this.variantImageUrl = this.getVariantImageUrl(this.job);
    }

    changeFormat(job: any, format: string) {
        if (job.refreshNow && job.time == 0 && job.min == 0 && !job.recurringBookingId) {
            return this.helperService.formatSlotTimeLocal(job, 'createdOn', format)
        }
        return this.helperService.formatSlotTimeLocal(job, 'scheduledOn', format)
    }

    statusFormat(status: any) {
        if (this.isOnDemandJob) {
            return "Accept ?"
        } else if (status === BookingStatus.Accepted || status === BookingStatus.QuoteReq || status >= BookingStatus.Done) {
            if((this.jobSection === 'today' || this.jobSection === 'overdue') && status < BookingStatus.Done){
                return "Start";
            }
            return "View";
        } else if (status === BookingStatus.Quoted) {
            return 'Quoted'
        } else {
            return "In Progress";
        }
    }

    isJobCompleted(status: number) {
        return status === BookingStatus.Done || status === BookingStatus.PayByCash || status >= BookingStatus.Invoiced;
    }

    clickJob() {
        this.jobClicked.emit(this.job)
    }

    formatVariantName(variant: any) {
        if (variant) {
            let name = variant.fullName;
            if (name) {
                if (!name.includes('•')) {
                    return name;
                }
                name = name.split("•");
                name = name.reverse();
                return name.map((name) => name.trim()).join(' - ');
            } else if (variant.name) {
                return variant.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    getInventoryDescription(job: any) {
        return job.inventory?.[0]?.name;
    }

    getVariantImageUrl(job: any) {
        let imageUrl = job.variants?.[0]?.primaryImageUrl;
        if (imageUrl) {
            return imageUrl;
        }

        const inv = job.inventory?.[0];
        return inv?.primaryImageUrl || inv?.image;
    }

    navigateToComms(event: any) {
        event.stopPropagation();
        this.navController.navigateRoot(`/customerChat/${this.job._id}`, { animated: true, animationDirection: 'forward', state: { fromNotification: true } });
    }
}