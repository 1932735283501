<div class="car-info-graphic" [class.no-border]="isCompact || newVehicle" [class.is-compact]="isCompact" *ngIf="vehicle">
    <div class="car-info-graphic__header">
        <div class="car-info-graphic__header--data">
            <div class="car-info-graphic__header--data-title">{{vehicle.year}} {{vehicle.make}}</div>
            <div>{{vehicle.model}}</div>
        </div>
        <div class="car-info-graphic__header--image">

            <!-- Plate -->
            <div class="car-info-graphic-plate" [ngStyle]="{ 'background-image': 'url('+ vehicle.backgroundPlate +')' }" *ngIf="!isCompact">
                <p>{{vehicle.plate}}</p>
            </div>

            <!-- Car -->
            <div class="car-info-graphic-picture" *ngIf="isCompact">
                <app-image-shell [src]="vehicle.image"></app-image-shell>
            </div>
        </div>

    </div>
    <div class="car-info-graphic__container" *ngIf="!isCompact">
        <!-- Last Service Details -->
        <div class="car-info-graphic__container--data" *ngIf="!newVehicle && serviceData">
            <div class="car-info-graphic__container--data-title">
                Last Service
            </div>
            <div>3+ months ago</div>
        </div>
         <!-- New Vehicle -->
        <div class="car-info-graphic-picture" [class.car-info-graphic__container--image]="!newVehicle && serviceData">
            <app-image-shell [src]="vehicle.image" [alt]="'https://fieldd-images.s3.ap-southeast-2.amazonaws.com/fieldd_default_images/no-photo-available.png'"></app-image-shell>
        </div>
    </div>

    <div class="car-info-graphic__footer" *ngIf="newVehicle">
        <div>{{ vehicle.description }}</div>
        <svg-shell *ngIf="newVehicle" (click)="removeVehicle()" src="fi-rr-cross-circle.svg"></svg-shell>
    </div>
</div>