import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationService } from '../services/auth.service';
import { HttpRequest, HttpHandler, HttpHeaders, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EMPTY } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { CustomModalComponent } from '../common/modalPopups/customModal.component';
import { BuildInfo } from '@ionic-native/build-info/ngx';
import { Market } from '@ionic-native/market/ngx';
@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor {

    modalOpen: boolean = false;
    
    constructor(
        private authenticationService: AuthenticationService,
        private modalController: ModalController,
        private buildInfo: BuildInfo,
        private market: Market,
    ) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler) {
        let token = this.authenticationService.getAuthToken();
        let isLoggedIn = this.authenticationService.isUserLoggedIn();

        let headers = new HttpHeaders()
                        .set('source', "SERVICEAPP")
                        .set('api_key', environment.apiKey)
                        .set('companyId', environment.companyID)
                        .set('app_version', this.authenticationService.getAppVersion());

        if (isLoggedIn) {
            headers = headers.set('auth_token', token);
        }
        if(httpRequest.headers.has('ignore-version-check')) {
            headers = headers.set('ignore-version-check', 'true');
        }

        httpRequest = httpRequest.clone({ headers });
        return next.handle(httpRequest).pipe(tap(async(event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {

            }
        }, async (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this.authenticationService.resetStyleAndLogout();
                    window.location.reload();
                    return EMPTY;
                } else if(err.status === 420) {
                    const isLoggedIn = this.authenticationService.isUserLoggedIn();

                    let onModalClosed = new EventEmitter();     
                    onModalClosed.subscribe(async res => {
                        this.modalOpen = false
                        if (isLoggedIn) {
                            this.authenticationService.logout();
                        }
                        let appId = this.getAppId();
                        if (appId) {
                            this.market.open(appId);
                        }
                        return;
                    });
                    
                    if(!this.modalOpen) {
                        const modalResponse = await this.modalController.create({
                            component: CustomModalComponent,
                            componentProps: {
                                modalClosed: onModalClosed,
                                title: "Important App Update",
                                description: "Please visit App Store/Google Play Store to update your app",
                                hideCancelButton: true,
                                okButtonText: "Ok",
                                imageUrl: "assets/img/app-version.svg"
                            },
                            swipeToClose: false,
                            backdropDismiss: false,
                            cssClass: 'alert-popup'
                        })
                        if(modalResponse) {
                            this.modalOpen = true
                            await modalResponse.present();
                        }
                    }
                    return EMPTY;
                }
            }
        }));
    }

    getAppId() {
        try {
            return this.buildInfo.packageName;
        } catch (e) {
            return false;
        }
    }
}
