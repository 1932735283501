import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {

    transform(address: any): string {
        if (typeof address === 'string') {
            let addressArray = address.split(',');
            addressArray = addressArray.filter(a => a.trim() != 'undefined');
            if (addressArray.length > 2) {
                addressArray.splice(-1);
            }
            return addressArray.join(', ');
        } else if (address?.address_line1) {
            let { address_line1, address_city, address_state } = address;
            return `${address_line1}, ${address_city}, ${address_state}`;
        }
        return address?.address_city;
    }
}

@Pipe({
    name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform {
    transform(address: any, short = false): string {
        const streetWords = {
            'apartment': '#',
            'apt': '#',
            'expressway': 'Expy',
            'po box': '#',
            'suite': '#',
            'ste': '#',
            'avenue': 'Ave',
            'boulevard': 'Blvd',
            'circle': 'Cir',
            'court': 'Ct',
            'crt': 'Ct',
            'drive': 'Dr',
            'lane': 'Ln',
            'mount': 'Mt',
            'highway': 'Hwy',
            'parkway': 'Pkwy',
            'place': 'Pl',
            'street': 'St',
            'road': 'Rd'
        };

        const directionalWords = {
            'east': 'E',
            'west': 'W',
            'south': 'S',
            'north': 'N',
        };

        const stateCodes = {
            'Alabama': "AL",
            'Alaska': "AK",
            'American Samoa': "AS",
            'Arizona': "AZ",
            'Arkansas': "AR",
            'California': "CA",
            'Colorado': "CO",
            'Connecticut': "CT",
            'Delaware': "DE",
            'District Of Columbia': "DC",
            'Federated States Of Micronesia': "FM",
            'Florida': "FL",
            'Georgia': "GA",
            'Guam': "GU",
            'Hawaii': "HI",
            'Idaho': "ID",
            'Illinois': "IL",
            'Indiana': "IN",
            'Iowa': "IA",
            'Kansas': "KS",
            'Kentucky': "KY",
            'Louisiana': "LA",
            'Maine': "ME",
            'Marshall Islands': "MH",
            'Maryland': "MD",
            'Massachusetts': "MA",
            'Michigan': "MI",
            'Minnesota': "MN",
            'Mississippi': "MS",
            'Missouri': "MO",
            'Montana': "MT",
            'Nebraska': "NE",
            'Nevada': "NV",
            'New Hampshire': "NH",
            'New Jersey': "NJ",
            'New Mexico': "NM",
            'New York': "NY",
            'North Carolina': "NC",
            'North Dakota': "ND",
            'Northern Mariana Islands': "MP",
            'Ohio': "OH",
            'Oklahoma': "OK",
            'Oregon': "OR",
            'Palau': "PW",
            'Pennsylvania': "PA",
            'Puerto Rico': "PR",
            'Rhode Island': "RI",
            'South Carolina': "SC",
            'South Dakota': "SD",
            'Tennessee': "TN",
            'Texas': "TX",
            'Utah': "UT",
            'Vermont': "VT",
            'Virgin Islands': "VI",
            'Virginia': "VA",
            'Washington': "WA",
            'West Virginia': "WV",
            'Wisconsin': "WI",
            'Wyoming': "WY"
        };

        if (typeof address === 'string') {
            const addressArray = address.split(',').filter(a => a.trim() != 'undefined');
            if (addressArray.length > 2) {
                addressArray.splice(-1);
            }
            if (short && addressArray.length > 2) {
                addressArray.splice(-1);
            }
            if (short && addressArray.length > 2) {
                addressArray.splice(-1);
            }
            const formattedAddress = addressArray.map(a => a.trim()).join(', ');
            return formattedAddress?.trim();
        } else if (address && address.address_line1) {
            let address_line1 = address.address_line1;
            address_line1 = String(address_line1).split(' ').filter(a => a.trim() != 'undefined');
            address_line1.forEach((word, i) => {
                let lowerCaseWord = word.toLowerCase();
                if (streetWords[lowerCaseWord] && i == address_line1.length - 1) {
                    word = streetWords[lowerCaseWord];
                }
                if (directionalWords[lowerCaseWord] && i < address_line1.length - 2) {
                    word = directionalWords[lowerCaseWord];
                }
                address_line1[i] = word;
            });
            address_line1 = address_line1.join(" ");
            let formattedAddress = `${address_line1}`.trim();
            if (address?.address_city) {
                formattedAddress += `, ${address.address_city}`;
            }
            if (address?.address_state) {
                let address_state = address.address_state;
                if (stateCodes[address_state]) {
                    address_state = stateCodes[address_state];
                }
                formattedAddress += `, ${address_state?.trim()}`;
            }
            if (address?.address_postcode) {
                formattedAddress += ` ${String(address.address_postcode || '').trim()}`;
            }
            return formattedAddress?.trim();
        } else {
            let formattedAddress = (address?.address_city || '')?.trim();
            if (address?.address_state) {
                let address_state = address.address_state;
                if (stateCodes[address_state]) {
                    address_state = stateCodes[address_state];
                }
                formattedAddress += `, ${address_state?.trim()}`;
            }
            if (address?.address_postcode) {
                formattedAddress += ` ${String(address.address_postcode || '').trim()}`;
            }
            return formattedAddress?.trim();
        }
    }
}
