import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { DateService } from '@services/date-service';

@Component({
    selector: 'app-custom-calendar',
    templateUrl: 'customCalendar.component.html',
    styleUrls: ['./customCalendar.component.scss']
})
export class CustomCalenderComponent {

    @Input() cssClass: string = '';
    @Input() title: string = '';
    @Input() showFirstAvail: boolean = false

    @Output() loadFirstAvailable: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDateChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onWeekChange: EventEmitter<any> = new EventEmitter<any>();

    public emitDateOnPickerChange = true;
    public currentWeek = [];
    public weekChange = [];
    public todaysDate: any;
    public disableBefore: any;
    public disablePicker: string;
    public date: any;
    public activeWeekDate: any;

    public pickerDate: any;

    _options: any = {}
    get options() {
        return this._options
    }

    @Input() set options(val: any) {
        this._options = val
        this.onDateOptionsChange()
    }

    constructor(private dateService:DateService) { }

    onDateOptionsChange() {
        if(this.options.emitDateOnPickerChange === false) {
            this.emitDateOnPickerChange = false;
        }
        this.date = this.options.startDate ? moment(this.options.startDate).startOf('d') : moment().startOf('d');
        this.disableBefore = this.options.disableBefore ? moment(this.options.disableBefore).startOf('d') : null;
        this.disablePicker = this.options.disableBefore ? new Date(this.disableBefore.valueOf()).toISOString() : new Date(moment().set({ year: 1900 }).valueOf()).toISOString();
        this.todaysDate = moment().startOf('d');
        this.activeWeekDate = moment().startOf('d');
        this.currentWeek = this.getCurrentWeek(this.date);
        this.weekChange = this.currentWeek;
        this.onWeekChange.emit(this.weekChange);

        this.pickerDate = new Date(this.date.valueOf()).toISOString();
    }

    onDateSelected(date: any) {
        if(!date.isSame(this.date)) {
            this.date = date;
            this.onDateChange.emit(this.date.clone());
            this.updateDatePicker();
        }
    }

    pickerDateChanged(event: any) {
        if (!event.detail.value) {
            return;
        }
        this.pickerDate = event.detail.value;
        let newDate = moment(new Date(this.pickerDate)).startOf('d');
        if(!(newDate.isSame(this.weekChange[3].date) || newDate.isSame(this.date))) {
            this.date = newDate.clone();
            if (this.emitDateOnPickerChange) {
                this.onDateChange.emit(this.date.clone());
            }
            this.activeWeekDate = this.date.clone();
            this.weekChange = this.getCurrentWeek(this.date);
            this.onWeekChange.emit(this.weekChange);
            this.currentWeek = this.weekChange;
        }
    }

    getCurrentWeek(currentDate: any) {
        let sowPreferences = this.dateService.getSowPreferences();
        let weekStart = moment(currentDate).startOf('w').add(sowPreferences,'d');
        let days = [];
        for (let i = 0; i < 7; i++) {
            let calculatedDate = moment(weekStart).add(i, 'days');
            if (!this.disableBefore || calculatedDate.startOf('day').diff(this.disableBefore, 'days') >= 0) {
                days.push({
                    enabled: true,
                    date: calculatedDate
                });
            } else {
                days.push({
                    enabled: false,
                    date: calculatedDate
                });
            }
        }
        return days;
    }

    onWeekChangedBtnClicked(next: boolean) {
        if (next) {
            this.weekSwipedRight();
        } else {
            this.weekSwipedLeft();
        }
        this.currentWeek = this.weekChange;
    }

    weekSwipedRight() {
        this.activeWeekDate = moment(this.activeWeekDate).add(7, 'days');
        this.weekChange = this.getCurrentWeek(this.activeWeekDate);
        this.onWeekChange.emit(this.weekChange);
        this.updateDatePicker(true);
    }

    weekSwipedLeft() {
        let newDate = moment(this.activeWeekDate).subtract(7, 'days');
        if (this.disableBefore && newDate.startOf('day').diff(moment(this.disableBefore).startOf('day'), 'days') <= -7) {
            return;
        } else {
            this.activeWeekDate = moment(this.activeWeekDate).subtract(7, 'days');
            this.weekChange = this.getCurrentWeek(this.activeWeekDate);
            this.onWeekChange.emit(this.weekChange);
            this.updateDatePicker(true);
        }
    }

    swipeEvent(event: any) {
        event.srcEvent.stopPropagation();
        event.srcEvent.stopImmediatePropagation();
        switch (event.direction) {
            case 2:
                this.weekSwipedRight();
                break;
            case 4:
                this.weekSwipedLeft();
                break;
        }
        this.currentWeek = this.weekChange;
    }

    updateDatePicker(weekChanged: boolean = false) {
        let oldDate = moment(this.pickerDate).startOf('d');
        let newDate = weekChanged ? this.weekChange[3].date : this.date;
        if(!oldDate.isSame(newDate, 'y') || !oldDate.isSame(newDate, 'M')) {
            setTimeout(() => {
                this.pickerDate = new Date(newDate.valueOf()).toISOString();
            });
        }
    }

    loadFirstAvailableSlot() {
        this.loadFirstAvailable.emit()
        this.onDateSelected(moment().startOf('d'))
        this.currentWeek = this.getCurrentWeek(this.date)
		this.weekChange = this.currentWeek
    }
}