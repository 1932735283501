import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    private listCustomersUrl: string = environment.apiURL + "partner/customers";
    private customerDetailsUrl: string = environment.apiURL + "partner/customer";
    private getCustomerJobsUrl: string = environment.apiURL + "admin/client/orders";
    private updateCustomerUrl: string = environment.apiURL + "admin/client/update";

    constructor(
        private http: HttpClient
    ) { }

    listCustomers(data: any) {
        return this.http.post<any>(this.listCustomersUrl, data);
    }

    getCustomerDetails(id: string): Promise<any> {
        return this.http.get<any>(`${this.customerDetailsUrl}/${id}`).toPromise();
    }

    updateCustomerDetails(data: any): Promise<any> {
        return this.http.put<any>(`${this.updateCustomerUrl}`, data).toPromise();
    }
    
    getCustomerJobs(data: any): Promise<any> {
        return this.http.post<any>(`${this.getCustomerJobsUrl}`, data).toPromise();
    }
}