import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

/**
 *Common Loading service to display loader
 */
@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private loaders: Array<HTMLIonLoadingElement> = [];
    constructor(private loadingController: LoadingController) { }

    showLoader(isBlocking: boolean = false, showSpinner: boolean = true): Promise<void> {
        return this.loadingController.create({
            showBackdrop: false,
            spinner: showSpinner ? 'crescent' : null,
            duration: isBlocking ? 0 : 5000,
        }).then((loader) => {
            this.loaders.push(loader);
            loader.present();
        });
    }

    hideLoader(): Promise<boolean> {
        return Promise.resolve(this.loaders?.length ? this.loaders.pop().dismiss() : true);
    }

    httpWrapperLoader<T>(httpPromise: Promise<T>, showSpinner: boolean = true): Promise<T> {
        return new Promise((resolve, reject) => {
            this.showLoader(true, showSpinner).then(() =>
                httpPromise.then((httpResponse) =>
                    this.hideLoader().then(() => {
                        resolve(httpResponse);
                    })
                )
            ).catch((error) =>
                this.hideLoader().then(() => {
                    reject(error);
                })
            );
        });
    }
}