import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { FilterModalGroup } from '@data/models/shared.model';

@Component({
    selector: 'filter-modal',
    templateUrl: './filter-modal.component.html',
    styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent {

    @Input() modalIcon: string;
    @Input() modalTitle: string;

    @Input() maxSelection: number = 0; // 0 === infinite
    @Input() groupedData: Array<FilterModalGroup> = [];

    selectedData: Map<string, any> = new Map();

    constructor(
        private modalController: ModalController,
    ) { }

    toggleItem(id: string, data: any) {
        if (this.selectedData.has(id)) {
            this.selectedData.delete(id);
            return;
        }

        if (this.maxSelection === 1 && this.selectedData.size > 0) {
            this.selectedData.clear();
        }

        if (this.maxSelection > 0 && this.selectedData.size >= this.maxSelection) {
            return;
        }

        this.selectedData.set(id, data);
    }

    selectData() {
        return this.closeModal(Array.from(this.selectedData.values()));
    }

    closeModal(data?: any) {
        return this.modalController.dismiss(data);
    }
}