
import { EventEmitter, Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthenticationService } from './auth.service';
import { ZoomModalComponent } from '../common/modalPopups/zoomModal.component';
import { BookingStatus } from '../data/models/enums';
import { LocationTrackerService } from './location-tracker.service';
import { AssetDBItem, AssetItemResponse, GetFreeSlotsRequest, RescheduleRequest, Variant } from '../modules/statusModule/status.model';
import { EMPTY, Observable, catchError, lastValueFrom, map } from 'rxjs';

import * as _ from 'lodash-es';
import { AlertService, AlertType } from './alert.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private openedModals = []

    updateOrderStatusUrl: string = environment.apiURL + "partner/refreshrequest/";
    processPaymentUrl: string = environment.apiURL + "partner/pos/processPayment";
    checkVersionUrl: string = environment.apiURL + "partner/checkVersion";
    getOrderDetailsUrl:string = environment.apiURL + "partner/getBooking/"
    private getZoneUrl = environment.apiURL + 'staff/getZones'
    private getFreeSlotsUrl = environment.apiURL + 'user/portal/freeslots'
    private rescheduleJobUrl = environment.apiURL  + 'admin/orders/reschedule'
    private staffNamesListUrl = environment.apiURL + 'admin/listPartnerNames'

    private questionLookUpUrl = environment.apiURL + 'lookups/question';

    private addCustomerAssetUrl = environment.apiURL + 'v2/user/asset';
    private updateCustomerAssetUrl = environment.apiURL + 'v2/user/asset/';
    private deleteCustomerAssetUrl = environment.apiURL + 'v2/user/asset/';

    private orderQuery = {
        params: {
            separateCustomerNotes: true,
            separateCustomerAssets: true,
        },
    };

    constructor(
        private http: HttpClient,
        private modalController: ModalController,
        private localStorageService: LocalStorageService,
        private authenticationService: AuthenticationService,
        private locationTrackerService: LocationTrackerService,
        private navController: NavController,
        private alertService: AlertService, 
    ) { }

    updateOrderStatus(orderId: string, data: any) {
        return this.http.put<any>(this.updateOrderStatusUrl + orderId, data, this.orderQuery);
    }

    processPayment(data){
        return this.http.post<any>(this.processPaymentUrl,data);
    }

    getCurrentJob(){
        return this.localStorageService.retrieve("activeJob");
    }

    setCurrentJob(job,source){
        this.localStorageService.store("activeJob",{job:job,source:source});
    }

    clearActiveJob(){
        this.localStorageService.clear("activeJob");
    }

    getOrderDetails(orderId: string) {
        return this.http.get<any>(this.getOrderDetailsUrl + orderId, this.orderQuery);
    }

    getZone(): Promise<any> {
        return this.http.get<any>(this.getZoneUrl).toPromise()
    }

    rescheduleJob(rescheduleRequest: RescheduleRequest): Observable<any> {
        return this.http.put<any>(this.rescheduleJobUrl, rescheduleRequest)
    }

    getStaffNamesList(): Observable<any> {
        return this.http.get(this.staffNamesListUrl)
    }

    getLookupData(data: { subType: string, data: string }): Promise<{ success: boolean, data: any, value?: string }> {
		return lastValueFrom(
            this.http.post<{ success: boolean, data: any, value?: string }>(`${this.questionLookUpUrl}`, data).pipe(
                map((response) => response),
                catchError(() => EMPTY),
            )
        );
	}

    addCustomerAsset(dataToSend: { assetDetails?: Array<AssetItemResponse>, profileImage?: string}) {
        return lastValueFrom(this.http.post<{ success: boolean, data: AssetDBItem }>(this.addCustomerAssetUrl, dataToSend));
    }

    updateCustomerAsset(assetId: string, dataToSend: { assetDetails?: Array<AssetItemResponse>, profileImage?: string}) {
        return lastValueFrom(this.http.put<{ success: boolean, data: AssetDBItem }>(this.updateCustomerAssetUrl + assetId, dataToSend));
    }

    deleteCustomerAsset(assetId: string) {
        return lastValueFrom(
            this.http.delete<{ success: boolean }>(this.deleteCustomerAssetUrl + assetId)
        );
    }

    getRequiredSlots(company: any, variant: Variant){
        if(!company || !variant){
          return 1;
        }
    
        let slotsRequired = variant.numberOfBookings;
        if(variant.combineMultipleJobSlots){
          slotsRequired = 1;
        }
    
        if(variant.staffRequired > 1){
          slotsRequired *= variant.staffRequired;
        }
    
        return slotsRequired;
      }

    shouldShowPricing(){
        let company = this.authenticationService.getCurrentCompany();
        let partner = this.authenticationService.getCurrentUserData();
        let defaultShouldHidePricing = company.hidePricing;
        let partnerShouldShowPricing = false;
        if(company.limitPricingForPartners && company.limitPricingForPartners.length>0){
            partnerShouldShowPricing = !!(company.limitPricingForPartners.find(x=>{return x== partner.emailID}));
        }
        return !defaultShouldHidePricing || partnerShouldShowPricing;
    }

    get hasEnhancePermission(): boolean {
        return !!this.authenticationService.getCurrentUserData().hasEnhancePermission;
    }

    async zoomImage(src: string, deleteEvent?: any, disableDelete: boolean = true, editMode: boolean = false, returnUrlHandler?: EventEmitter<any>, index?: number) {
        let modal = await this.modalController.create({
            component: ZoomModalComponent,
            componentProps: {
                disableDelete,
                editMode: editMode,
                imageUrl: src,
                imageDeleteClicked: deleteEvent,
                returnUrlHandler: returnUrlHandler,
                index: index
            },
        });
        return await modal.present();
    }

    getContractorShare(jobPercentage: number, company: any, partner?: any): number {
        if (jobPercentage > -1) {
            return jobPercentage;
        }
        return company.variableContractorPercentage ? (partner ? partner.individualPercentage : jobPercentage) : company.contractorPercentage;
    }

    calculateContractorEarning(job: any, company: any, partner?: any): number {
        // const variantAmount = job.variants?.reduce((sum: number, variant: any) => sum + parseFloat(parseFloat(variant.price).toFixed(2)) * (variant.numberOfBookings || 1), 0) || 0;
        // const inventoryAmount = job.inventory?.reduce((sum: number, inventory: any) => sum + parseFloat(parseFloat(inventory.salePrice).toFixed(2)) * (inventory.quantity || 1), 0) || 0;
        const contractorPercentage = this.getContractorShare(job.contractorPercentage, company, partner);

        return (job.amount + (job.discountedAmount || 0)) * (contractorPercentage / 100);
    }

    getStatusTagAndColor(status: number): any {
        switch(status) {
            case BookingStatus.Requested:
                return {
                    statusTag: 'Requested',
                    statusColor: 'red',
                };
            case BookingStatus.QuoteReq:
                return {
                    statusTag: 'Quote Req',
                    statusColor: 'orange',
                };
            case BookingStatus.Quoted:
                return {
                    statusTag: 'Quoted',
                    statusColor: 'green',
                };
            case BookingStatus.Accepted:
                return {
                    statusTag: 'Accepted',
                    statusColor: 'blue',
                };
            case BookingStatus.EnRoute:
                return {
                    statusTag: 'En Route',
                    statusColor: 'orange',
                };
            case BookingStatus.Arrived:
                return {
                    statusTag: 'Arrived',
                    statusColor: 'orange',
                };
            case BookingStatus.ArrivalPhotos:
                return {
                    statusTag: 'Before Photos',
                    statusColor: 'orange',
                };
            case BookingStatus.ArrivalChecklist:
                return {
                    statusTag: 'Before Check',
                    statusColor: 'orange',
                };
            case BookingStatus.ArrivalFormItems:
                return {
                    statusTag: 'Before Forms',
                    statusColor: 'orange',
                };
            case BookingStatus.InProgress:
                return {
                    statusTag: 'In Progress',
                    statusColor: 'orange',
                };
            case BookingStatus.PhotoAdded:
                return {
                    statusTag: 'After Photos',
                    statusColor: 'orange',
                };
            case BookingStatus.Signature:
                return {
                    statusTag: 'Signature',
                    statusColor: 'orange',
                };
            case BookingStatus.CompletionFormItems:
                return {
                    statusTag: 'After Forms',
                    statusColor: 'orange',
                };
            case BookingStatus.CompletionChecklistItems:
                return {
                    statusTag: 'After Check',
                    statusColor: 'orange',
                };
            case BookingStatus.Done:
                return {
                    statusTag: 'Paid-Card',
                    statusColor: 'green',
                };
            case BookingStatus.Cancelled:
                return {
                    statusTag: 'Cancelled',
                    statusColor: 'red',
                };
            case BookingStatus.Rejected:
                return {
                    statusTag: 'Rejected',
                    statusColor: 'red',
                };
            case BookingStatus.PayByCash:
                return {
                    statusTag: 'Paid-Cash',
                    statusColor: 'green',
                };
            case BookingStatus.Attempted:
                return {
                    statusTag: 'Attempted',
                    statusColor: 'yellow',
                };
            case BookingStatus.Attempting:
                return {
                    statusTag: 'Attempting',
                    statusColor: 'yellow',
                };
            case BookingStatus.Reallocated:
                return {
                    statusTag: 'Rescheduled',
                    statusColor: 'yellow',
                };
            case BookingStatus.Invoiced:
                return {
                    statusTag: 'Invoiced',
                    statusColor: 'orange',
                };
            case BookingStatus.PdInvCard:
                return {
                    statusTag: 'Inv-Card',
                    statusColor: 'green',
                };
            case BookingStatus.PdInvBank:
                return {
                    statusTag: 'Inv-Bank',
                    statusColor: 'green',
                };
            case BookingStatus.InvVoid:
                return {
                    statusTag: 'Inv-Void',
                    statusColor: 'orange',
                };
            case BookingStatus.PayByCardRecord:
                return {
                    statusTag: 'Card-Rec',
                    statusColor: 'green',
                };
            case BookingStatus.QuoteCompleted:
                return {
                    statusTag: 'Quote Sent',
                    statusColor: 'orange',
                };
            case BookingStatus.QuoteWon:
                return {
                    statusTag: 'Quote Won',
                    statusColor: 'green',
                };
            case BookingStatus.NoCharge:
                return {
                    statusTag: 'No Charge',
                    statusColor: 'green',
                };
        }
    }

    async acceptOndemandRequest(onDemandComponentRef, job: any) {
        let modalClosedSubscriber = new EventEmitter<any>();
        modalClosedSubscriber.subscribe(async (jobToStart: any) => {
            if (jobToStart) {
                try {
                    let res = await this.locationTrackerService.checkLocationAccess();
                    if (res) {
                        this.setCurrentJob(jobToStart, "dispatch");
                        this.navController.navigateRoot("/status", { animated: true, animationDirection: 'forward' });
        
                    }
                } catch (e) {
                    //
                }
            }
        });
        
        let modal: any = await this.modalController.create({
            component: onDemandComponentRef,
            cssClass: 'modal-fullscreen',
            componentProps: {
                jobId: job._id,
                recurringJobDetails: job.recurringBookingId,
                modalClosed: modalClosedSubscriber
            },
        });
        return await modal.present();
    }

    public async openModal(componentName, properties, dismissCb = null, callDismissOnClose = false) {
        if (this.openedModals.find(a => a === componentName)) {
          return;
        }
        
        this.openedModals.push(componentName);
        const modal = await this.modalController.create({
          component: componentName,
          componentProps: properties,
          mode: 'ios',
          swipeToClose: true,
          backdropDismiss: false,
        });
    
        modal.onDidDismiss().then(data => {
          const index = this.openedModals.indexOf(componentName);
          if (index > -1) {
            this.openedModals.splice(index, 1);
          }
          if((data?.data || callDismissOnClose) && dismissCb){
            dismissCb(data.data);
          }
        })
    
        return await modal.present();
    }

    getFreeSlots(slotsRequest: GetFreeSlotsRequest): Observable<any> {
        return this.http.post(this.getFreeSlotsUrl, slotsRequest)
    }

    copyToClipboard(text: string, successMessage: string = 'Copied to Clipboard', showToastr: boolean = true) {
        return new Promise<void>((resolve, reject) => {
            if ('clipboard' in navigator && window.isSecureContext) {
                navigator.clipboard.writeText(text);
                return resolve();
            }

            const textArea = document.createElement('textarea');
            textArea.value = text;

            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';

            document.body.appendChild(textArea);

            textArea.focus();
            textArea.select();

            document.execCommand('copy') ? resolve() : reject();
            textArea.remove();
        }).then(() => {
            if (showToastr) {
                this.alertService.showToastMessage(successMessage, AlertType.Success);
            }
            return true;
        }).catch((r) => {
            console.log(r)
            return false;
        });
    };
}