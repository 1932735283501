 <!-- Summary -->
<ul class="list">
    <!-- Quote Service -->
    <li class="selected-item" *ngIf="job.requiresQuote">
        <div class="left-flex">
            <div class="pos-summary-col-1">
                <img src="assets/img/wallet.svg">
            </div>
            <div class="pos-summary-col-2 pos-disc-col">
                {{ job.variant ? job.variant.name : '' }}
            </div>
        </div>
        <div class="right-flex">
            <div class="pos-summary-col-4 orange-color">
                Free Quote
            </div>
        </div>
    </li> 
    <!-- Services Summary -->
    <li *ngFor="let variant of job.variants">
        <div *ngIf="variant?.id?.includes('custom_quote')" >
            <div class="selected-item" *ngFor="let x of variant.lineItems" [ngStyle]="{'align-items': (x?.history?.length > 20 &&  x?.showCompleteName) ? 'end' : 'center'}">
                <div class="left-flex" style="z-index: 999999;" [ngStyle]="{'align-items': (x?.history?.length > 20 &&  x?.showCompleteName) ? 'end' : 'center'}">
                    <div class="pos-summary-col-3">
                        <span *ngIf="hidePricing && x?.quantity > 1" (click)="clearRowSummary(variant)">{{ x.quantity }}x</span>

                        <div *ngIf="!x?.history">
                            {{ x.name || '' }}
                        </div>

                        <div *ngIf="x?.history" (click)="x.showCompleteName = !x.showCompleteName" style="display: flex;z-index: 99999;">
                            <svg 
                            class="fad fa-chevron-circle-up down-icon" *ngIf="x?.history?.length > 20" [class.rotate]="x.showCompleteName"
                            width="12px" height="8px" viewBox="0 0 18 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.8">
                                    <g id="POS-MASTER" transform="translate(-536.000000, -978.000000)" fill="var(--ion-color-primary)">
                                        <g id="Group-4" transform="translate(0.000000, 961.000000)">
                                            <g id="Group-2" transform="translate(536.000000, 17.000000)">
                                                <path d="M4.48673259,-2.25553167 L3.93488768,-1.74402564 C3.45631377,-1.30043462 3.42795453,-0.552872235 3.87154555,-0.0742983225 C3.89185574,-0.0523864118 3.91298975,-0.0312527553 3.934902,-0.0109429278 L10.4201004,6 L3.934902,12.0109429 C3.45632076,12.454526 3.42794917,13.202088 3.87153229,13.6806692 C3.89184212,13.7025814 3.91297577,13.7237154 3.93488768,13.7440256 L4.48673259,14.2555317 C4.93983519,14.6755133 5.63999392,14.6755191 6.09310346,14.2555449 L14.0650825,6.86654799 C14.5436638,6.42296487 14.5720353,5.67540296 14.1284522,5.19682172 C14.108138,5.1749047 14.0869995,5.15376626 14.0650825,5.13345201 L6.09310346,-2.25554494 C5.63999392,-2.67551907 4.93983519,-2.67551329 4.48673259,-2.25553167 Z" id="Path" transform="translate(9.000000, 6.000000) rotate(90.000000) translate(-9.000000, -6.000000) "></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <div class="collapsible-div" [class.collapsed]="x.history.length > 20 && !x.showCompleteName" style="display: flex;">
                                {{ x.history }}
                            </div>
                        </div>

                        
                    </div>
                </div>
                <div class="right-flex" *ngIf="!hidePricing" [class.service-price]="variant.numberOfBookings * variant.price >= 2000" (click)="clearRowSummary(variant)">
                    <div style="flex-direction: row;display: flex;justify-content: end; align-items: center;">
                        <div *ngIf="x.discountApplied && !discountAppliedOnAll" class="pos-summary-col-1 discount-badge">
                            <img src="assets/img/badge-percent.svg">
                        </div>
                        <div>
                            <div class="pos-summary-col-3" *ngIf="x?.quantity > 1">
                                <span>{{ x.quantity }}x {{ x.price | fielddCurrency:'symbol-narrow'}}</span>
                            </div>
                            <div class="pos-summary-col-4">
                                {{ (x.quantity * x.price) | fielddCurrency:'symbol-narrow' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!variant?.id?.includes('custom_quote')" class="selected-item" [ngStyle]="{ 'align-items': (variant?.history?.length > 20 &&  variant?.showCompleteName) ? 'end' : 'center','border-bottom': hidePricing ? '0px' : '1px solid var(--main-grey-support)' }">
            <div class="left-flex" style="z-index: 99999999;" [ngStyle]="{'align-items': (variant?.history?.length > 20 &&  variant?.showCompleteName) ? 'end' : 'center'}">
                <div class="pos-summary-col-1">
                    <img *ngIf="variant.primaryImageUrl" [src]="variant.primaryImageUrl | imageResize: '120x120'" class="pos-summary-col-1" style="min-width: 25px;" (click)="clearRowSummary(variant)">
                    <svg *ngIf="!variant.primaryImageUrl" style="width: 100%; height: 100%;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <defs><style>.fa-secondary{opacity:.4}</style></defs>
                        <path d="M448 128c-106 0-192 86-192 192s86 192 192 192 192-86 192-192-86-192-192-192zm114.1 147.8l-131 130a11 11 0 0 1-15.6-.1l-75.7-76.3a11 11 0 0 1 .1-15.6l26-25.8a11 11 0 0 1 15.6.1l42.1 42.5 97.2-96.4a11 11 0 0 1 15.6.1l25.8 26a11 11 0 0 1-.1 15.5z"
                            class="fa-secondary" fill="var(--ion-color-primary)" />
                        <path d="M240 0H98.6a47.87 47.87 0 0 0-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240zm208 80a221.93 221.93 0 0 1 27.2 1.7l-16.3-48.8A47.83 47.83 0 0 0 413.4 0H272v157.4C315.9 109.9 378.4 80 448 80zM208 320a238.53 238.53 0 0 1 20.2-96H0v240a48 48 0 0 0 48 48h256.6C246.1 468.2 208 398.6 208 320zm354.2-59.7l-25.8-26a11 11 0 0 0-15.6-.1l-97.2 96.4-42.1-42.5a11 11 0 0 0-15.6-.1l-26 25.8a11 11 0 0 0-.1 15.6l75.7 76.3a11 11 0 0 0 15.6.1l131-130a11 11 0 0 0 .1-15.5z"
                            class="fa-primary" fill="var(--ion-color-primary)" />
                    </svg>
                </div>
                <div class="pos-summary-col-2" (click)="variant.showCompleteName = !variant.showCompleteName" style="display: flex;z-index: 999999;">
                    <svg 
                    class="fad fa-chevron-circle-up down-icon" *ngIf="variant?.history?.length > 20" [class.rotate]="variant.showCompleteName"
                    width="12px" height="8px" viewBox="0 0 18 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.8">
                            <g id="POS-MASTER" transform="translate(-536.000000, -978.000000)" fill="var(--ion-color-primary)">
                                <g id="Group-4" transform="translate(0.000000, 961.000000)">
                                    <g id="Group-2" transform="translate(536.000000, 17.000000)">
                                        <path d="M4.48673259,-2.25553167 L3.93488768,-1.74402564 C3.45631377,-1.30043462 3.42795453,-0.552872235 3.87154555,-0.0742983225 C3.89185574,-0.0523864118 3.91298975,-0.0312527553 3.934902,-0.0109429278 L10.4201004,6 L3.934902,12.0109429 C3.45632076,12.454526 3.42794917,13.202088 3.87153229,13.6806692 C3.89184212,13.7025814 3.91297577,13.7237154 3.93488768,13.7440256 L4.48673259,14.2555317 C4.93983519,14.6755133 5.63999392,14.6755191 6.09310346,14.2555449 L14.0650825,6.86654799 C14.5436638,6.42296487 14.5720353,5.67540296 14.1284522,5.19682172 C14.108138,5.1749047 14.0869995,5.15376626 14.0650825,5.13345201 L6.09310346,-2.25554494 C5.63999392,-2.67551907 4.93983519,-2.67551329 4.48673259,-2.25553167 Z" id="Path" transform="translate(9.000000, 6.000000) rotate(90.000000) translate(-9.000000, -6.000000) "></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span *ngIf="hidePricing && variant?.numberOfBookings > 1">{{ variant.numberOfBookings }}x</span>
                    <div class="collapsible-div" [ngClass]="{'widthInOndemand': isInOnDemand && (variant?.numberOfBookings > 1)}" [class.collapsed]="(variant?.history?.length + ((variant?.showTaxes === false && job?.taxExclusive) ? 8 : 0 ) ) > 20 && !variant.showCompleteName">
                        {{ variant?.history }}
                        <span *ngIf="variant?.showTaxes === false && job.taxExclusive">({{ (variant?.showTaxes === false && job.taxExclusive) ? '0% tax' : '' }})</span>
                    </div>
                </div>
            </div>
            <div class="right-flex" (click)="clearRowSummary(variant)" [class.service-price]="variant.numberOfBookings * variant.price >= 2000" *ngIf="!hidePricing" >
                <div *ngIf="variant.discountApplied && !discountAppliedOnAll" class="pos-summary-col-1 discount-badge">
                    <img src="assets/img/badge-percent.svg">
                </div>
                <div class="pos-summary-col-3" *ngIf="!hidePricing && variant?.numberOfBookings > 1">
                    {{ variant.numberOfBookings }}x {{ variant.price | fielddCurrency:'symbol-narrow'}}
                </div>
                <div class="pos-summary-col-4">
                    {{ (variant.numberOfBookings * variant.price) | fielddCurrency:'symbol-narrow' }}
                </div>
            </div>
        </div>
    </li>
    <!-- Products Summary -->
    <li class="selected-item" *ngFor="let inventory of job.inventory" [ngStyle]="{ 'align-items': (inventory?.name?.length > 20 &&  inventory?.showCompleteName) ? 'end' : 'center', 'border-bottom': hidePricing ? '0px' : '1px solid var(--main-grey-support)' }">
        <div class="left-flex" [ngStyle]="{'align-items': (inventory?.name?.length > 20 &&  inventory?.showCompleteName) ? 'end' : 'center'}">
            <div class="pos-summary-col-1">
                <img *ngIf="inventory.primaryImageUrl" [src]="inventory.primaryImageUrl | imageResize: '120x120'" (click)="clearRowSummary(inventory,true)">
                <svg *ngIf="!inventory.primaryImageUrl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <defs><style>.fa-secondary{opacity:.4}</style></defs>
                    <path d="M448 128c-106 0-192 86-192 192s86 192 192 192 192-86 192-192-86-192-192-192zm114.1 147.8l-131 130a11 11 0 0 1-15.6-.1l-75.7-76.3a11 11 0 0 1 .1-15.6l26-25.8a11 11 0 0 1 15.6.1l42.1 42.5 97.2-96.4a11 11 0 0 1 15.6.1l25.8 26a11 11 0 0 1-.1 15.5z"
                        class="fa-secondary" fill="var(--ion-color-primary)" />
                    <path d="M240 0H98.6a47.87 47.87 0 0 0-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240zm208 80a221.93 221.93 0 0 1 27.2 1.7l-16.3-48.8A47.83 47.83 0 0 0 413.4 0H272v157.4C315.9 109.9 378.4 80 448 80zM208 320a238.53 238.53 0 0 1 20.2-96H0v240a48 48 0 0 0 48 48h256.6C246.1 468.2 208 398.6 208 320zm354.2-59.7l-25.8-26a11 11 0 0 0-15.6-.1l-97.2 96.4-42.1-42.5a11 11 0 0 0-15.6-.1l-26 25.8a11 11 0 0 0-.1 15.6l75.7 76.3a11 11 0 0 0 15.6.1l131-130a11 11 0 0 0 .1-15.5z"
                        class="fa-primary" fill="var(--ion-color-primary)" />
                </svg>
            </div>
            <div class="pos-summary-col-2" (click)="inventory.showCompleteName = !inventory.showCompleteName" style="display: flex;z-index: 999999;">
                <svg 
                    class="fad fa-chevron-circle-up down-icon" *ngIf="inventory?.name?.length > 20" [class.rotate]="inventory.showCompleteName"
                    width="12px" height="8px" viewBox="0 0 18 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.8">
                            <g id="POS-MASTER" transform="translate(-536.000000, -978.000000)" fill="var(--ion-color-primary)">
                                <g id="Group-4" transform="translate(0.000000, 961.000000)">
                                    <g id="Group-2" transform="translate(536.000000, 17.000000)">
                                        <path d="M4.48673259,-2.25553167 L3.93488768,-1.74402564 C3.45631377,-1.30043462 3.42795453,-0.552872235 3.87154555,-0.0742983225 C3.89185574,-0.0523864118 3.91298975,-0.0312527553 3.934902,-0.0109429278 L10.4201004,6 L3.934902,12.0109429 C3.45632076,12.454526 3.42794917,13.202088 3.87153229,13.6806692 C3.89184212,13.7025814 3.91297577,13.7237154 3.93488768,13.7440256 L4.48673259,14.2555317 C4.93983519,14.6755133 5.63999392,14.6755191 6.09310346,14.2555449 L14.0650825,6.86654799 C14.5436638,6.42296487 14.5720353,5.67540296 14.1284522,5.19682172 C14.108138,5.1749047 14.0869995,5.15376626 14.0650825,5.13345201 L6.09310346,-2.25554494 C5.63999392,-2.67551907 4.93983519,-2.67551329 4.48673259,-2.25553167 Z" id="Path" transform="translate(9.000000, 6.000000) rotate(90.000000) translate(-9.000000, -6.000000) "></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                <span *ngIf="hidePricing && inventory?.quantity > 1">{{inventory.quantity}}x</span>
                <div class="collapsible-div" style="display: flex;" [ngClass]="{'widthInOndemand': isInOnDemand && (inventory?.quantity > 1)}" [class.collapsed]="inventory?.name?.length > 20 && !inventory.showCompleteName">
                    {{ inventory?.name }}
                </div>
            </div>
        </div>
        
        <div class="right-flex" style="flex-direction: row;" [class.service-price]="inventory.quantity * inventory.salePrice >= 1000" (click)="clearRowSummary(inventory,true)">
            <div *ngIf="inventory.discountApplied && !discountAppliedOnAll" class="pos-summary-col-1 discount-badge">
                <img src="assets/img/badge-percent.svg">
            </div>
            <div style="width: unset !important; display: flex;">
                <div class="pos-summary-col-3" *ngIf="!hidePricing">
                    <span *ngIf="inventory.quantity > 1">{{inventory.quantity}}x {{ inventory.salePrice | fielddCurrency:'symbol-narrow'}}</span>
                </div>
                <div class="pos-summary-col-4" *ngIf="!hidePricing || (!isAdminJob && !inventory.id)">
                    {{ (inventory.quantity * inventory.salePrice) | fielddCurrency:'symbol-narrow' }}
                </div>
            </div>
        </div>
    </li>
    <!-- Travel Charges Summary -->
    <li class="selected-item" *ngIf="calculateTravelCharge()" [ngStyle]="{ 'border-bottom': hidePricing ? '0px' : '1px solid var(--main-grey-support)' }">
        <div class="left-flex">
            <div class="pos-summary-col-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><defs><style>.fa-secondary{opacity:.4}</style></defs>
                    <path d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8ZM358.24,177.74,262.29,385.63c-11.2,24-46.38,16-46.38-9.59V288H128c-25.59,0-33.58-35.18-9.59-46.38L326.3,145.71c19.15-8,39.94,12.84,31.94,32Z"
                    class="fa-secondary" fill="var(--ion-color-primary)"/><path d="M118.36,241.7l207.89-95.95c19.2-8,40,12.8,32,32l-96,207.88c-11.2,24-46.38,16-46.38-9.59V288H128c-25.64,0-33.63-35.14-9.64-46.34Z"
                    class="fa-primary" fill="var(--ion-color-primary)"/>
                </svg>
            </div>
            <div class="pos-summary-col-2">
              Travel Charges
            </div>
        </div>
        <div class="right-flex">
            <div *ngIf="discountAppliedOnTravelCharges && !discountAppliedOnAll" class="pos-summary-col-1 discount-badge">
                <img src="assets/img/badge-percent.svg">
            </div>
            <div class="pos-summary-col-4">
                <span *ngIf="!hidePricing">{{ calculateTravelCharge() | fielddCurrency:'symbol-narrow' }}</span>
            </div>
        </div>
    </li>
    <!-- Travel Charges Summary -->
    <li class="selected-item" *ngIf="job.zoneTravelCharges?.appliedCharges > 0" [ngStyle]="{ 'border-bottom': hidePricing ? '0px' : '1px solid var(--main-grey-support)' }">
        <div class="left-flex">
            <div class="pos-summary-col-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><defs><style>.fa-secondary{opacity:.4}</style></defs>
                    <path d="M248,8C111,8,0,119,0,256S111,504,248,504,496,393,496,256,385,8,248,8ZM358.24,177.74,262.29,385.63c-11.2,24-46.38,16-46.38-9.59V288H128c-25.59,0-33.58-35.18-9.59-46.38L326.3,145.71c19.15-8,39.94,12.84,31.94,32Z"
                    class="fa-secondary" fill="var(--ion-color-primary)"/><path d="M118.36,241.7l207.89-95.95c19.2-8,40,12.8,32,32l-96,207.88c-11.2,24-46.38,16-46.38-9.59V288H128c-25.64,0-33.63-35.14-9.64-46.34Z"
                    class="fa-primary" fill="var(--ion-color-primary)"/>
                </svg>
            </div>
            <div class="pos-summary-col-2">
                {{ job?.zoneTravelCharges?.name || 'Travel Charges'}}
            </div>
        </div>
        <div class="right-flex">
            <div *ngIf="discountAppliedOnTravelCharges && !discountAppliedOnAll" class="pos-summary-col-1 discount-badge">
                <img src="assets/img/badge-percent.svg">
            </div>
            <div class="pos-summary-col-4">
                <span *ngIf="!hidePricing">{{ job.zoneTravelCharges?.appliedCharges | fielddCurrency:'symbol-narrow' }}</span>
            </div>
        </div>
    </li>
    <!-- Repeat Job Discount -->
    <li class="selected-item" *ngIf="repeatJobDiscount?.discountedAmount > 0">
        <div class="left-flex">
            <div class="pos-summary-col-1">
                <img src="assets/img/badge-percent.svg">
            </div>
            <div class="pos-summary-col-2 pos-disc-col">
                {{company?.repeatDiscountTitle || 'Repeat Job Discount'}}
            </div>
        </div>
        <div class="right-flex" *ngIf="!hidePricing">
            <div class="pos-summary-col-4 green-color">
                -{{ repeatJobDiscount.discountedAmount | fielddCurrency:'symbol-narrow'}}
            </div>
            </div>
    </li>
    <!-- Discounts Summary -->
    <li class="selected-item" *ngIf="formattedDiscountAmount > 0"
        (click)="removePromoCode()" [ngStyle]="{ 'border-bottom': hidePricing ? '0px' : '1px solid var(--main-grey-support)' }">
        <div class="left-flex">
            <div class="pos-summary-col-1">
                <img src="assets/img/badge-percent.svg">
            </div>
            <div class="pos-summary-col-2 pos-disc-col">
                "{{ promoCode }}" {{ discountAppliedOnAll ? defaultDiscountMessage : discountMessage }}
            </div>
        </div>
        <div class="right-flex" *ngIf="!hidePricing">
            <div class="pos-summary-col-4 green-color">
                -{{ formattedDiscountAmount | fielddCurrency: 'symbol-narrow' }}
            </div>
        </div>
    </li>
    
    <!-- Invite code summary -->
    <li class="selected-item" *ngIf="job.clientCodeAmount && job.clientCodeAmount > 0"
        [ngStyle]="{ 'border-bottom': hidePricing ? '0px' : '1px solid var(--main-grey-support)' }">
        <div class="left-flex">
            <div class="pos-summary-col-1">
                <img src="assets/img/badge-percent.svg">
            </div>
            <div class="pos-summary-col-2 pos-disc-col">
                "Invite code: " {{ job.clientCode }}
            </div>
        </div>
        <div class="right-flex" *ngIf="!hidePricing">
            <div class="pos-summary-col-4 green-color">
                -{{ job.clientCodeAmount | fielddCurrency: 'symbol-narrow' }}
            </div>
        </div>
    </li>

    <!-- Share Code Summary -->
    <li class="selected-item" *ngFor="let shareCode of job.freeCreditObj" [ngStyle]="{ 'border-bottom': hidePricing ? '0px' : '1px solid var(--main-grey-support)' }">
        <div class="left-flex">
            <div class="pos-summary-col-1">
                <img src="assets/img/badge-percent.svg">
            </div>
            <div class="pos-summary-col-2 pos-disc-col">
                Share Code: {{shareCode.firstName}}
            </div>
        </div>
        <div class="right-flex">
            <div class="pos-summary-col-4 green-color">
                <span *ngIf="!hidePricing">-{{ shareCode.amount | fielddCurrency:'symbol-narrow'}}</span>
            </div>
        </div>
    </li>
    <!-- Taxes -->
    <li class="selected-item" *ngIf="fromPos && isCompanyTaxApplicable() && !job._id && (job.variants?.length || job.inventory?.length) && !showEditTax() && !hidePricing" (click)="editTaxList()">
        <div class="right-flex">
            <div class="pos-summary-col-4 add-taxes">
                Add taxes
            </div>
        </div>
    </li>
    <li class="selected-item" [hidden]="!totalTime || tabType !== 'quote'" >
        <div class="right-flex">
            <div class="pos-summary-col-4">
        <custom-time-picker [cssClass]="'pos-datetime'" [time]="totalTime" (onTimeChanged)="onTotalTimeChanged($event)" [format]="'H:mm'" [label]="'Est. time to complete quoted job:'" [company]="company"></custom-time-picker>
            </div>
        </div>
    </li>

    <li class="selected-item" *ngFor="let tax of job.taxList | filterZeroValue : 'taxAmount'; let i = index;" (click)="fromPos && isCompanyTaxApplicable() && i === 0 && !job._id ? editTaxList() : ''">
        <div class="right-flex" *ngIf="!hidePricing">
            <div class="pos-summary-col-3" style="display: flex; align-items: center;">
                {{ job.taxExclusive ? '+' : 'Incl. ' }}{{ tax.name }} {{ tax.tax }}%:
            </div>
            <div class="pos-summary-col-4">
                {{ tax.taxAmount | fielddCurrency:'symbol-narrow' }}
            </div>
        </div>
    </li>

    <!-- Sub Total -->
    <li class="selected-item pos-sub-total" *ngIf="subTotal > 0 && !hidePricing" [hidden]=" subTotal == (amount + (job.tip || tip || 0))">
        <div class="left-flex">
            <div class="pos-summary-col-1">

            </div>
            <div class="pos-summary-col-2">

            </div>
        </div>
        <div class="right-flex">
            <div class="pos-summary-col-3">
                Sub Total:
            </div>
            <div class="pos-summary-col-4 black-color">
                {{ subTotal | fielddCurrency:'symbol-narrow' }}
            </div>
        </div>
    </li>


    <li class="selected-item" *ngIf="!hidePricing && (job.tip || tip)">
        <div class="right-flex">
            <div class="pos-summary-col-3" style="display: flex; align-items: center;">
                Tip:
            </div>
            <div class="pos-summary-col-4">
                {{ (job.tip ? job.tip : (tip || 0)) | fielddCurrency:'symbol-narrow' }}
            </div>
        </div>
    </li>

    <!-- Total -->
    <li class="selected-item pos-total" *ngIf="!hidePricing">
        <div class="left-flex" *ngIf="!job?.isPrePaid">
            <div class="pos-summary-col-1 pos-footer">
                <div *ngIf="amount > 0 && fromPos">
                    <!-- Invoice Details -->
                    <span *ngIf="tabType === 'invoice'">
                        {{ company.isInvoiceDueDateOfMonth ? 'This invoice can be paid online via link in email' : ('This ' + company.invoiceDueDays + ' day invoice can be paid online  via link in email')}}
                    </span>

                    <!-- Quote Info -->
                    <!-- <span *ngIf="tabType === 'quote'">
                        This quote will be sent by email
                    </span> -->
                </div>
            </div>
        </div>
        <div class="right-flex" [ngClass]="{'job-detail-shrink': animation === 'cost'}">
            <div class="pos-summary-col-3">
                Total:
            </div>
            <div class="pos-summary-col-4 black-color">
                {{ (amount + (job.tip || tip || 0)) | fielddCurrency:'symbol-narrow' }}
            </div>
        </div>
    </li>

    <!-- Pre Paid Summary -->
    <ng-container *ngIf="job.isPrePaid">
        <li class="selected-item pos-sub-total margin-top" *ngIf="!job?.paymentLogs?.length">
            <div class="right-flex">
                <div class="pos-summary-col-3">
                    {{ job.isPartiallyPaid ? 'Part Pre-Payment' : 'Full Pre-Payment'}}:
                </div>
                <div class="pos-summary-col-4">
                    <span *ngIf="!hidePricing">-{{ job.prePaidAmount | fielddCurrency:'symbol-narrow'}}</span>
                </div>
            </div>
        </li>
        <li class="selected-item pos-sub-total" *ngFor="let log of job.paymentLogs; first as isFirst" [class.margin-top]="isFirst">
            <div class="right-flex">
                <div class="pos-summary-col-3">
                    {{log.scheme | cardScheme: 'scheme'}} *{{log.cardNumber?.toString().slice(-4) || 'XXXX'}}:
                </div>
                <div class="pos-summary-col-4">
                    <span *ngIf="!hidePricing">-{{ log.amount | fielddCurrency:'symbol-narrow' }}</span>
                </div>
            </div>
        </li>
    </ng-container>
    
    <!-- Total -->
    <li class="selected-item pos-total" *ngIf="!hidePricing && job?.isPrePaid">
        <div class="left-flex">
            <div class="pos-summary-col-1 pos-footer">
                <div *ngIf="amount > 0 && fromPos">
                    <!-- Invoice Details -->
                    <span *ngIf="tabType === 'invoice'">
                        {{ company.isInvoiceDueDateOfMonth ? 'This invoice can be paid online via link in email' : ('This ' + company.invoiceDueDays + ' day invoice can be paid online  via link in email')}}
                    </span>

                    <!-- Quote Info -->
                    <!-- <span *ngIf="tabType === 'quote'">
                        This quote will be sent by email
                    </span> -->
                </div>
            </div>
        </div>
        <div class="right-flex" [ngClass]="{'job-detail-shrink': animation === 'cost'}">
            <div class="pos-summary-col-3">
                Balance:
            </div>
            <div class="pos-summary-col-4 black-color">
                {{ ((job.prePaidAmount > 0 && amount > 0 ? amount - job.prePaidAmount : amount) + (tip || 0)) | fielddCurrency:'symbol-narrow' }}
            </div>
        </div>
    </li>

    <!-- Contractor Earnings -->
    <li class="selected-item pos-total" *ngIf="job.contractorEarnings > 0 && !fromPos">
        <div class="left-flex">
            <div class="pos-summary-col-1">

            </div>
            <div class="pos-summary-col-2">

            </div>
        </div>
        <div class="right-flex">
            <div class="pos-summary-col-3">
                Your Earnings:
            </div>
            <div class="pos-summary-col-4 black-color">
                {{ job.contractorEarnings | fielddCurrency:'symbol-narrow' }}
            </div>
        </div>
    </li>
</ul>
