import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate,CanActivateChild {

    constructor(private authService: AuthenticationService, private router: Router) { }

    canActivate(): boolean {
        if (this.authService.isUserLoggedIn()) {
            return true;
        }
        else {
            this.router.navigateByUrl('/', { replaceUrl: true });
            return false;
        }
    }
    canActivateChild(): boolean {
        if (this.authService.isUserLoggedIn()) {
            return true;
        }
        else {
            this.router.navigateByUrl('/', { replaceUrl: true });
            return false;
        }
    }
}
