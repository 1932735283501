import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CommonService } from '../services/common.service';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
    constructor(
        private router: Router,
        private commonService: CommonService,
    ) { }

    canActivate() {
        if (!this.commonService.hasEnhancePermission) {
            return this.router.navigateByUrl('/dashboard', { replaceUrl: true });
        }
        return true;
    }
}