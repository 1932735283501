import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import moment, { Moment } from 'moment-timezone';

import { CalendarDay, CalendarOptions } from '@data/models/shared.model';

@Component({
    selector: 'monthly-calendar',
    templateUrl: './monthly-calendar.component.html',
    styleUrls: ['./monthly-calendar.component.scss']
})
export class MonthlyCalendarComponent implements OnChanges {
    
    readonly calendarFormat: string = 'YYYY-MM-DD';

    activeMonthDate: string = moment().format(this.calendarFormat);
    monthChange: Array<CalendarDay>;
    currentMonth: Array<CalendarDay>;

    todaysStr: string = moment().format(this.calendarFormat);
    
    private _todaysDate: Moment = moment();
    public get todaysDate(): Moment {
        return this._todaysDate;
    }
    public set todaysDate(value: Moment) {
        this._todaysDate = value;
        this.todaysStr = value.format(this.calendarFormat);
    }

    @Input() date: string;
    @Input() calendarOptions: CalendarOptions;

    @Output() dateChange: EventEmitter<string> = new EventEmitter<string>();

    ngOnChanges(changes: SimpleChanges) {
        if (changes.calendarOptions) {
            this.initializeCalendar();
        }

        if (changes.date && changes.date.currentValue === -1) {
            this.date = this.calendarOptions?.minDate || this.todaysDate.clone().format(this.calendarFormat);
        }
    }

    initializeCalendar() {
        if (!this.calendarOptions) {
            this.calendarOptions = {};
        }
        if (!this.calendarOptions.timezone) {
            this.calendarOptions.timezone = moment.tz.guess();
        }

        this.activeMonthDate = this.calendarOptions.minDate;
        this.currentMonth = this.getCurrentMonth();
        this.monthChange = this.currentMonth;
    }

    getCurrentMonth() {
        return this.getCalendarDays();
    }

    onMonthChangedBtnClicked(next: boolean) {
        if (next) {
            this.monthSwipedRight();
        } else {
            this.monthSwipedLeft();
        }
        this.currentMonth = this.monthChange;
    }

    monthSwipedRight() {
        this.activeMonthDate = moment(this.activeMonthDate).add(1, 'month').format(this.calendarFormat);
        this.monthChange = this.getCurrentMonth();
    }

    monthSwipedLeft() {
        let newDate = moment(this.activeMonthDate).startOf('d').subtract(32, 'days');

        if (newDate.startOf('day').diff(moment(this.todaysDate).startOf('day'), 'days') <= -32) {
            return
        } else {
            this.activeMonthDate = moment(this.activeMonthDate).subtract(1, 'month').format(this.calendarFormat);
            this.monthChange = this.getCurrentMonth();
        }
    }

    swipeEvent(event: any) {
        this.onMonthChangedBtnClicked(event?.direction === 2);
        //ToDo: Add animation for this transition
        this.currentMonth = this.monthChange;
    }

    daySelected(date: string) {
        if (this.date === date) return;
        
        this.date = date;
        this.dateChange.emit(this.date);
    }

    getCalendarDays(): Array<CalendarDay> {
        let sow = this.calendarOptions.sowPreferences === -1 ? 0 : 1;

        let monthStart = moment(this.activeMonthDate).startOf('month');
        let endDate = moment(this.activeMonthDate).endOf('month');
        let isoMonth = moment(this.activeMonthDate).clone().startOf('month').startOf('week').add(sow, 'd');
        let beforeDays = monthStart.diff(isoMonth, 'day')
        if (beforeDays < 0) {
            isoMonth = isoMonth.add(-7, 'd')
            beforeDays = monthStart.diff(isoMonth, 'day')
        }
        let numberOfDays = monthStart.daysInMonth();
        let leftoverDays = 7 - ((beforeDays + numberOfDays) % 7)
        let days = [];
        for (let i = 0; i <= beforeDays - 1; i++) {
            days.push({
                enabled: false,
                date: isoMonth.format(this.calendarFormat),
                display: false
            });
            isoMonth.add(1, 'day');
        }
        this.todaysDate = moment().tz(this.calendarOptions.timezone);
        for (let i = 0; i < numberOfDays; i++) {
            let calculatedDate = monthStart.clone();

            days.push({
                enabled: this.isEnabled(calculatedDate),
                date: monthStart.format(this.calendarFormat),
                display: true
            });
            monthStart.add(1, 'day')
        }
        if (leftoverDays < 7) {
            for (let i = 0; i < leftoverDays; i++) {
                endDate.add(1, 'day');
                days.push({
                    enabled: false,
                    date: endDate.format(this.calendarFormat),
                    display: false
                });
            }
        }
        return days;
    }

    isEnabled(date: any): boolean {
        if (this.calendarOptions.disablePastDates && date.startOf('day').diff(this.todaysDate.startOf('day'), 'days') < 0) {
            return false;
        } else if (this.calendarOptions.disableFutureDates && date.startOf('day').diff(this.todaysDate.startOf('day'), 'days') > 0) {
            return false;
        } else if (this.calendarOptions.minDate && date.startOf('day').diff(moment(this.calendarOptions.minDate).startOf('day'), 'days') < 0) {
            return false;
        } else if (this.calendarOptions.maxDate && date.startOf('day').diff(moment(this.calendarOptions.maxDate).startOf('day'), 'days') > 0) {
            return false;
        }
        return true;
    }
}