
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';

/**
 *Common Loading service to display loader
 */
@Injectable({
    providedIn: 'root'
})
export class PosService {

    public trackerSubscription: any;
  
    getCategoriesUrl: string = environment.apiURL + "company/inventory-app-category/"
    getInventoryUrl: string = environment.apiURL +"user/app/inventory"
    checkCustomerEmailUrl:string = environment.apiURL + "user/portal/getuser";
    checkPromoCodeUrl:string = environment.apiURL + "partner/redeem/promocode";
    sendQuoteUrl:string = environment.apiURL + "user/booking/portal/sendQuote";
    getVariantsPriceListUrl: string = environment.apiURL + "company/variantPriceList";
    //Stripe api calls
    getStripePublicKeyUrl:string = environment.apiURL + "v2/user/getStripePublicKey"
    getQualPayKeyUrl:string = environment.apiURL + "v2/user/getQualPayKey";
    getPayFabricSessionUrl: string = environment.apiURL + 'v2/payFabric/getToken';
    private getPayFabricCardUrl = environment.apiURL + 'v2/payFabric/getCard';
    processOneTimeStripePaymentUrl:string= environment.apiURL + "v2/user/processOneTimeStripePayment"
    getPartnerTaxListUrl:string= environment.apiURL + "partner/taxList"
    constructor(
        private http: HttpClient) {

    }
    
    getCategories(companyId):Observable<any>{
        return this.http.get(this.getCategoriesUrl+companyId);
    }

    getInventories(data):Observable<any>{
        return this.http.post(this.getInventoryUrl,data);
    }

    checkCustomerEmail(data){
        return this.http.post<any>(this.checkCustomerEmailUrl,data);
    }

    redeemPromoCode(data){
        return this.http.post<any>(this.checkPromoCodeUrl,data);
    }

    sendQuote(data){
        return this.http.post<any>(this.sendQuoteUrl,data);
    }

    getVariantPriceList(data){
        return this.http.post<any>(this.getVariantsPriceListUrl ,data);
    }

    //Stripe functions
    getStripePublicKey(data){
        return lastValueFrom(this.http.post<any>(this.getStripePublicKeyUrl ,data));
    }

    processOneTimeStripePayment(data){
        return this.http.post<any>(this.processOneTimeStripePaymentUrl ,data);
    }

    getPartnerTaxList(){
        return this.http.post<any>(this.getPartnerTaxListUrl, null);
    }

    getQualPayKey() {
        return lastValueFrom(this.http.get<any>(this.getQualPayKeyUrl))
    }

    getPayFabricSession(companyId) {
        return lastValueFrom(this.http.post<any>(`${this.getPayFabricSessionUrl}/${companyId}`, null))
    }

    getPayFabricCardDetails(dataToSend: { companyId: string, transactionId: string }): Promise<any> {
        return lastValueFrom(this.http.post<any>(`${this.getPayFabricCardUrl}`, dataToSend))
    }

}
