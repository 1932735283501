import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment-timezone';
import * as _ from 'lodash-es';

import { BookingSource } from '@data/models/shared.model';

import { CommonHelperService } from '../../../services/helper.service';
import { CommonService } from '../../../services/common.service';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
    selector: 'job-information',
    templateUrl: 'jobInformation.component.html'
})
export class JobInformationComponent implements OnInit {

    isAdminJob: boolean = false;

    private _job: any = {};
    public get job(): any {
        return this._job;
    }
    @Input()
    public set job(value: any) {
        this._job = value;

        this.isAdminJob = [BookingSource.ADMIN, BookingSource.STAFF_APP].includes(value?.bookingSource);
    }

    @Input() fromPos: boolean = false;
    @Input() tabType: string = '';
    @Input() amount: number = 0;
    @Input() tip: number = 0;
    @Input() discountDetail: any = {};
    @Input() discountMessage: string = '';
    @Input() defaultDiscountMessage: string = '';
    @Input() isInOnDemand: boolean = false;
    @Input() promoCode: string = '';
    @Input() animation: string = '';
    @Input() bypassDiscountAppliedOn: boolean = false;
    @Input() discountAppliedOnTravelCharges: boolean = false;
    @Input() discountAppliedOnAll: boolean = true;
    private _discountedAmount: number;
    @Input() set discountedAmount(value: number) {
        this._discountedAmount = value;
        this.formatDiscountedAmount();
        this.getRepeatJobDiscount();
    }
    get discountedAmount(): number {
        return this._discountedAmount;
    }
    @Input() totalTime: any;

    @Output() deleteItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() removePromo: EventEmitter<any> = new EventEmitter<any>();
    @Output() openTaxListModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() totalTimeChanged: EventEmitter<any> = new EventEmitter<any>();


    hidePricing = false;
    company: any;

    public formattedDiscountAmount: number;
    public repeatJobDiscount: any;

    constructor(
        private helperService: CommonHelperService,
        private commonService: CommonService,
        private authenticationService: AuthenticationService,
    ) { }

    ngOnInit() {
        this.hidePricing = !this.commonService.shouldShowPricing();
        this.company = this.authenticationService.getCurrentCompany();
        this.formatDiscountedAmount()
        if(this.isAdminJob) {
            this.formatVariants()
        }
    }

    formatVariants() {
        for(let i = 0; i < this.job?.variants?.length; i++) {
            this.job.variants[i]['history'] = this.job.variants[i]['history'] || this.job.variants[i].name
        }
        this.formatDiscountedAmount();
        this.getRepeatJobDiscount()
    }

    getRepeatJobDiscount() {
        try {
            // new api
            if(this.job?.discountDetails && this.job?.discountDetails?.length) {
                return this.repeatJobDiscount = this.job.discountDetails.filter(discount => discount.category === 'repeat' && discount.isUsed)[0]; 
            }
        }
        catch (err) {}

        // old api
        if (this.job?.repeatJobDiscount) {
            let discount = this.job.repeatJobDiscount
            if (discount?.discountedAmount >= this.formattedDiscountAmount) {
                return this.repeatJobDiscount = discount;
            }
        }

        this.repeatJobDiscount = null;
    }

    formatDiscountedAmount() {
        this.formattedDiscountAmount = this.discountedAmount
        if(this.job.clientCodeAmount && this.job.clientCodeAmount > 0) {
            this.formattedDiscountAmount -= this.job.clientCodeAmount || 0
        }

        if(this.job.freeCreditObj && this.job.freeCreditObj.length) {
            for(let i = 0; i < this.job.freeCreditObj.length; i++) {
                const shareCode = this.job.freeCreditObj[i]
                this.formattedDiscountAmount -= shareCode.amount
            }
        }
    }

    getFormattedDate() {
        let formattedSlot = this.helperService.getFormattedSlot(this.job.time, this.job.min);
        return formattedSlot + " " + moment(this.job.scheduledOn).format("ddd, MMM, DD");
    }

    calculateTravelCharge() {
        return this.helperService.calculateTravelCharge(this.job)
    }

    calculateZoneTravelCharge() {
        return this.helperService.calculateZoneTravelCharge(this.job)
    }

    editTaxList() {
        this.openTaxListModal.emit();
    }

    isCompanyTaxApplicable() {
        return (((!this.company?.companyOperationModel && this.company?.GSTRegistered) /*Employee and Company has set up tax */
          || (this.company?.companyOperationModel && this.company?.contractorGSTRegistered)));
    }

    showEditTax(){
        if(this.job.taxList?.length && this.job.taxList.find((x) => x.taxAmount > 0)){
            return true;
        }
        return false;
    }

    get subTotal():number {
        let total = this.amount;
        return total;
    }

    getSubTotal() {
        let total = this.amount;
        if(this.job.taxList) {
            this.job.taxList.forEach((tax: any) => total -= tax.taxAmount);
        }
        return this.job.prePaidAmount ? total - this.job.prePaidAmount : total;
    }

    clearRowSummary(variant: any, isInventory: boolean = false) {
        this.deleteItem.emit({ variant, isInventory });
    }

    removePromoCode() {
        this.removePromo.emit();
    }

    onTotalTimeChanged(time){
        this.totalTimeChanged.emit(time)
    }
}