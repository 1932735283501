export enum ModalComponents {
    JobListComponent,
    AddJobComponent,
    SuggestedStaffComponent,
    JobWarningsComponent,
    AssetDetailsComponent,
    FilterModalComponent,
    PriceListComponent,
}

export interface AnyKeyValue {
    [key: string]: any;
}

export interface CalendarDay {
    enabled: boolean;
    display: boolean;
    date: string;
}

export interface ParsedGeolocation {
    latitude: number;
    longitude: number;
}

export interface AddressObject {
    country: string;
    countryCode: string;
    state: string;
    postcode: string;
    city: string;
    street: string;
}

export interface ParsedGoogleAddress {
    address: string;
    address_components: google.maps.GeocoderAddressComponent[];
    location?: ParsedGeolocation;
    addressObject?: AddressObject;
    utcOffset?: number;
}

export enum PaymentGateways {
    SQUARE = "square",
    STRIPE = "stripe",
    PAY_FABRIC = "payFabric",
    PIN = "pin",
    QUALPAY = "qualpay",
    QUAL_FIELDD = "qualFielddPay",
    FINIX_FIELDD = "finixFielddPay",
    ADYEN_FIELDD = "adyenFielddPay",
    PIN_FIELDD = "pinFielddPay",
}

export interface CardFields {
    _id: string;
    name?: string;
    number: string;
    cardToken: string;
    scheme: string;
    expiry_month: string;
    defaultCard: boolean;
    expiry_year: string;//full year
    verificationToken?: any;
}

export interface GatewayCard {
    cardNumber?: string;
    cvc?: string;
    expiry_month?: string;
    expiry_year?: string;
    scheme?: string;
    cardToken?: string;
    verificationToken?: string;
}

export interface CardResponse {
    success: boolean;
    cardData?: GatewayCard;
}

export interface TimeRangeItem {
    value: {
        hour: number;
        min: number;
    };
    selectValue: string;
    display: string;
    index: number;
}

export interface FileModel {
    fileName: string;
    data: string;
    fileType: string;
    raw?: string;
}

export interface CalendarOptions {
    timezone?: string,
    disablePastDates?: boolean,
    disableFutureDates?: boolean,
    sowPreferences?: number;
    minDate?: any,
    maxDate?: any,
}

export interface MergeSlot {
    hour: number;
    minutes: number;
    epoch: number;
    startTime: number;
    endTime: number;
    staffId: string;
    mergeCount: number;
    key: string;
    show: boolean;
}

export interface FilterModalItem {
    id: string;
    title: string;
    data: any;
    subtitle?: string;
    image?: string;
}

export interface FilterModalGroup {
    title: string;
    items: Array<FilterModalItem>;
}

export enum BookingSource {
    ONLINE = 'ONLINE',
    CLIENT_APP = 'CLIENTAPP',
    ADMIN = 'admin',
    STAFF_APP = 'STAFF_APP',
    FIELDD_POS = 'FielddPOS',
    ADMIN_INVOICE = 'adminInvoice',
}

export interface SignUpRequest {
    name: string;
    emailID: string;
    contactPersonFirstName: string;
    contactPersonLastName: string;
    address: {
        country: string;
        state: string;
        street: string;
        city: string;
        postcode: string;
        location: {
            latitude: number;
            longitude: number;
        };
    };
    activeCountry: string;
    password: string;
    foundSource: string;
    phone: string;
    dialCode: string;
    businessCategory: string;
    website: string;
    registerationSource: string;
    termsAndCondition: boolean;
}

export const Booking_Status = {
    0: 'Requested',
    0.2: 'Quote Req',
    0.5: 'Quoted',
    1: 'Accepted',
    2: 'En-Route',
    3: 'Arrived',
    3.5: 'Arrival Photos',
    3.6: 'Arrival Checklist',
    3.7: 'Arrival Forms',
    4: 'In Progress',
    4.5: 'Photo Added',
    4.6: 'Completion Checklist',
    4.7: 'Completion Forms',
    4.8: 'Signature',
    5: 'Done',
    6: 'Cancelled',
    7: 'Rejected',
    8: 'Pay by cash',
    9: 'Attempted',
    10: 'Attempting',
    11: 'Reallocated',
    12: 'Invoiced',
    12.5: 'Pending Inv Bank',
    13: 'Pd-Inv-Card',
    14: 'Pd-Inv-Bank',
    15: 'Inv-Void',
    16: 'PayByCardRecord',
    17: 'QuoteCompleted',
    18: 'QuoteWon',
    19: 'NoCharge',
} as const;

export const Request_Status = {
    'New': 0,
    'Quote Req': 0.2,
    'Quoted': 0.5,
    'Accepted': 1,
    'En route': 2,
    'Arrived': 3,
    'arrivalPhotos': 3.5,
    'arrivalChecklistItems': 3.6,
    'arrivalFormItems': 3.7,
    'Refreshing': 4,
    'photoAdded': 4.5,
    'completionChecklistItems': 4.6,
    'completionFormItems': 4.7,
    'signature': 4.8,
    'refreshed': 5,
    'cancel': 6,
    'Reject': 7,
    'PayByCash': 8,
    'Attempted': 9,
    'Reallocated': 11,
    'Attempting': 10,
    'Invoiced': 12,
    'PendingInvBank': 12.5,
    'Pd-Inv-Card': 13,
    'Pd-Inv-Bank': 14,
    'Inv-Void': 15,
    'PayByCardRecord': 16,
    'QuoteCompleted': 17,
    'QuoteWon': 18,
    'NoCharge': 19,
} as const;