<ion-content>
	<div class="popup-small-bg">
		<div class="popup-header">
			<img class="popup-icon" src="assets/img/frb.svg" style="width: 175px; max-height: 60px;">
		</div>
		<div class="popup-title">
			Welcome to the Worker App!
		</div>
		<div class="popup-description">
			This app is for your worker to complete jobs and take payments in the field. <br /><br />
			To book jobs and manage your business, this is done in the CRM. <span style="color:#0F69FF">We highly recommend</span> you visit the fieldd CRM from your desktop computer.
			Our CRM is included with this app and is used to setup your profile and manage multiple staff.<br /><br />
			Simply login to the fieldd CRM with the same login details as this app.
		</div>
		<div class="popup-buttons-bottom">
			<div class="popup-cancel-button" (click)="closeModal()">
				Close
			</div>
			<div class="popup-ok-button" (click)="visitCrm()">
				Visit CRM
			</div>
		</div>
	</div>
</ion-content>