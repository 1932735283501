import { NavOptions, createAnimation } from '@ionic/core';

export function modalRtEnterAnimation(baseEl: HTMLElement, opts?: NavOptions) {
    const root = baseEl;

    const backdropAnimation = createAnimation()
        .addElement(root.querySelector('ion-backdrop')!)
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
        .addElement(root.querySelector('.modal-wrapper')!)
        .keyframes([
            { offset: 0, transform: 'translateX(100%)' },
            { offset: 1, transform: 'translateX(0)' },
        ]);

    return createAnimation()
        .addElement(baseEl)
        .duration(opts?.duration || 333)
        .easing('cubic-bezier(0.7, 0, 0.3, 1)')
        .addAnimation([backdropAnimation, wrapperAnimation]);
}

export function modalRtLeaveAnimation(baseEl: HTMLElement, opts?: NavOptions) {
    return modalRtEnterAnimation(baseEl, opts).direction('reverse');
}