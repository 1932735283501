import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: 'confirmationModal.component.html'
})
export class ConfirmationModalComponent {
    
    @Input() message: string = "Default message";
    @Input() messageType: string = "Success"
    @Input() buttonText: string = "Continue";
    @Input() confirmActionText: string;
    @Input() loadCompleteClass: string = "";
    @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private modalController: ModalController
    ) { }

    ionViewDidEnter() {
        setTimeout(() => {
            this.loadCompleteClass = this.messageType == 'Success' ? 'load-complete' : 'load-fail';
        }, 800);
    }

    closeModal(data?: any) {
        this.modalController.dismiss(data);
        this.modalClosed.emit();
    }
}