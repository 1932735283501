import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingService } from '../../../services/loading.service';
import { AuthenticationService } from '../../../services/auth.service';

@Component({
    selector: 'app-job-iframe',
    templateUrl: 'jobIFrame.component.html',
})
export class JobIFrameComponent implements OnInit {

    iFrameSrc: string;

    constructor(
        private modalController: ModalController,
        private loadingService: LoadingService,
        private authenticationService: AuthenticationService,
    ) { }

    ngOnInit() {
        this.loadingService.showLoader(true).then(() => {
            const company = this.authenticationService.getCurrentCompany();
            this.iFrameSrc = `https://${company.domainUrl}.fieldd.co`;
        });
    }

    iFrameLoadComplete() {
        this.loadingService.hideLoader();
    }

    closeModal() {
        this.modalController.dismiss();
    }
}