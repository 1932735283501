<ion-header [hidden]="editMode">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">
                <img src="assets/img/close-black.svg" class="header-close-i">
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="zoom-modal-main" [scrollY]="false">
    <div>
        <img 
            id="image-edit" 
            [src]="imageUrl | imageResize" 
            class="zoom-img" 
            (load)="imageLoaded($event)" 
            (error)="imageErrorHandler()" 
            [hidden]="hideImage"
        />
    </div>
</ion-content>

<ion-footer *ngIf="!disableDelete">
    <div class="button-center-con safe-mr-bottom">
        <div class="full-button delete-button"(click)="deleteImage()">
            Delete Image
        </div>
    </div>
</ion-footer>