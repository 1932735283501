import { Component, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Component({
	selector: 'empty-state',
	templateUrl: './empty-state.component.html',
	styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {
    @Input() icon: string;

    @Input() title: string = '';
    @Input() subtitle: string;

    @Output() click: EventEmitter<void> = new EventEmitter<void>();
}