import { Component, ElementRef, Input, OnChanges, Renderer2, SecurityContext, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { Storage } from '@ionic/storage';

import { lastValueFrom } from 'rxjs';

const EMPTY_SVG = `<svg viewBox="0 0 10 10"></svg>`;
@Component({
    selector: 'svg-shell, [svg-shell]',
    template: EMPTY_SVG,
    styleUrls: ['./svg-shell.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SvgShellComponent implements OnChanges {

    private readonly FOLDER_PATH = '/assets/svg/';
    private readonly FALLBACK_IMAGE = 'fi-rr-picture.svg';

    @Input() src: string;

    @Input('svg-shell') 
    set svgShell(src: string | void) {
        if (src) {
            this.src = src;
        }
    }

    constructor(
        private http: HttpClient,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private nativeStorage: Storage,
        private domSanitizer: DomSanitizer,
    ) { }

    ngOnChanges(): void {
        this.getSVG(this.src || this.FALLBACK_IMAGE);
    }

    getSVG(file: string) {
        this.setSVG(); // Resets existing

        return this.getFile(`${this.FOLDER_PATH}${file}`).then((svgString) => {
            this.setSVG(svgString);
        }).catch(() => {
            if (file !== this.FALLBACK_IMAGE) {
                this.getSVG(this.FALLBACK_IMAGE);
            }
        });
    }

    async getFile(src: string): Promise<string> {
        const data = await this.nativeStorage.get(src);
        const file = !data?.file ? (await this.fetchFile(src)) : data.file;

        if (!file) {
            throw new Error('Not Found');
        }

        return file;
    }

    async fetchFile(src: string): Promise<string> {
        const headers = new HttpHeaders();
        headers.set('Accept', 'image/svg+xml');

        const options = {
            headers,
            responseType: 'text',
        } as const;

        const svgString = await lastValueFrom(this.http.get(src, options));
        this.nativeStorage.set(src, { lastUsed: Date.now(), file: svgString });

        return svgString;
    }

    setSVG(svgString: string = EMPTY_SVG) {
        svgString = this.elementRef.nativeElement.innerHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.domSanitizer.bypassSecurityTrustHtml(svgString));
        this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', svgString);
    }
}