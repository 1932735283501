import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { ModalController, NavController, Platform } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AuthenticationService } from './services/auth.service';
import { LocationTrackerService } from './services/location-tracker.service';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { ChatService } from './services/chat.service';

import { PushActions } from '../../src/app/data/models/enums'
import { onDemandModalComponent } from './common/modalPopups/onDemandModal.component/onDemandModal.component';
import { LoadingService } from './services/loading.service';
import { DispatchService } from './modules/dispatchModule/dispatch.service';
import { CommonService } from './services/common.service';
import { FileManagementService } from './services/file-management.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    animations: [
        trigger(
            'enterAnimation',
            [
                transition(
                    ':leave', [
                    style({ height: 300, opacity: 1 }),
                    animate('1s ease-in', style({ height: 0, opacity: 0 }))
                ])
            ]
        )
    ]
})
export class AppComponent {
    pendingUploads: any = { uploaded: 0, total: 0 };
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private authenticationService: AuthenticationService,
        private firebaseX: FirebaseX,
        private locationTrackingService: LocationTrackerService,
        private modalController: ModalController,
        private vibration: Vibration,
        private chatService: ChatService,
        private router: Router,
        private navController: NavController,
        private loadingService: LoadingService,
        private dispatchService: DispatchService,
        private commonService: CommonService,
        private fileManagementService: FileManagementService,
    ) {
        if (this.authenticationService.isUserLoggedIn()) {
            this.chatService.establishConnection(this.authenticationService.getCurrentUserData()._id);
            this.chatService.registerDefaultListeners();
        }

        this.platform.ready().then(() => {
            this.initializeApp();
            this.initializeFileUploads()
        });
    }

    initializeApp() {
        try {
            if (this.authenticationService.isUserLoggedIn()) {
                this.authenticationService.getServiceDetails();
                this.authenticationService.refreshCompanyAndPartner(true).then((response) => {
                    this.chatService.hasNewDirectMessage.next(!!response.partner?.hasNewDirectMessages);
                });
                this.locationTrackingService.startPartnerLocationTracking();

                // create local db
                // await this.authenticationService.createIndexDb()

            } else if (this.authenticationService.isWhiteLabelApp) {
                this.authenticationService.getServiceAppSettings();
            } else {
                this.authenticationService.updateStyles(null);
            }

            this.authenticationService.appSettingsChanged$.subscribe((flag) => {
                const appSettings = this.authenticationService.getAppSettings();
                if (flag) {
                    setTimeout(() => {
                        this.splashScreen?.hide();
                        this.authenticationService.setThemeStyle(appSettings);
                    }, 3000);
                } else if (appSettings) {
                    this.authenticationService.setThemeStyle(this.authenticationService.getAppSettings());
                }
            });

            setTimeout(async () => {
                if (this.platform.is("cordova")) {
                    let hasPermission = await this.firebaseX.hasPermission();
                    if (!hasPermission) {
                        let gotPermission = await this.firebaseX.grantPermission();
                        if (gotPermission) {
                            this.updateNotificationPermission(true);
                        }
                        else {
                            this.updateNotificationPermission(false);
                        }
                    }
                    else {
                        this.updateNotificationPermission(true);
                    }

                    // remove badge when app is opened
                    this.firebaseX.setBadgeNumber(0).then(res => {
                        console.log(res)
                    }).catch(err => {
                        console.log(err)
                    })

                    this.authenticationService.getFirebaseToken();
                    this.firebaseX.onTokenRefresh().subscribe(token => {
                        console.log("firebaseToken2", token);
                        let deviceType = this.platform.is("ios") ? "iOS" : "Android";
                        let deviceObj = {
                            "device": {
                                deviceId: token,
                                deviceType: deviceType
                            }
                        }
                        this.authenticationService.storeFireBaseToken(deviceObj);
                        this.authenticationService.registerFireBaseTokenForPartner();
                    })
                    this.firebaseX.onMessageReceived().subscribe(this.runHandler.bind(this));
                }
            }, 300);
        } catch (err) {
            console.log(err)
        }
    }

    initializeFileUploads() {
        this.fileManagementService.pendingUploads.subscribe(pendingUploads => {
            this.pendingUploads = pendingUploads
            // this.pendingUploads = { uploaded: 2, total: 5 };
        })
    }
    updateNotificationPermission(notificationPermission: boolean) {
        this.locationTrackingService.notificationPermission = notificationPermission
    }

    checkUrl(url) {
        if (this.router.url !== url) {
            return true
        }
        return false
    }

    async openOnDemandModal(jobId: string) {
        let modal: any = await this.modalController.create({
            component: onDemandModalComponent,
            cssClass: 'modal-fullscreen',
            componentProps: {
                jobId: jobId
            },
        });
        await modal.present();
    }

    async openJob(orderId: string) {
        let statusUrl = '/status';

        try {
            const currentJob = await this.loadingService.httpWrapperLoader(
                this.dispatchService.getOrder(orderId)
            );
            if (currentJob?.success && currentJob?.data?._id) {
                this.commonService.setCurrentJob(currentJob.data, 'dispatch');
                if (this.checkUrl(statusUrl)) {
                    this.navController.navigateRoot(statusUrl, { animated: true, animationDirection: 'forward' });
                } else {
                    this.authenticationService.orderChanged$.next(true);
                }
            }
        } catch (e) {
            // 
        }
    }

    runHandler(data: any) {
        this.firebaseX.getBadgeNumber().then(data => {
            console.log('get badge', data)
            if (data) {
                this.firebaseX.setBadgeNumber(data).then(res => {
                    console.log('set badge', res)
                }).catch(err => {
                    console.log(err)
                })
            }
        }).catch(err => {
            console.log(err)
        })

        if (!data.wasTapped) {
            if (data.click_action === PushActions.ondemand) {
                this.vibration.vibrate(300);
                return this.openOnDemandModal(data.orderId);
            }
        }

        if (data?.tap && data?.click_action) {
            switch (data.click_action) {
                case PushActions.new_booking:
                case PushActions.reminder:
                    this.vibration.vibrate(300);
                    this.openJob(data.orderId);
                    break

                case PushActions.comms:
                    let commsUrl = '/dashboard/comms'
                    if (this.checkUrl(commsUrl)) {
                        this.vibration.vibrate(300);
                        this.navController.navigateRoot(commsUrl, { animated: true, animationDirection: 'forward' });
                    }
                    break

                case PushActions.order_comms:
                    let orderCommsUrl = `/customerChat/${data.orderId}`
                    if (this.checkUrl(orderCommsUrl)) {
                        this.vibration.vibrate(300);
                        this.navController.navigateRoot(orderCommsUrl, { animated: true, animationDirection: 'forward', state: { fromNotification: true } });
                    }
                    break

                case PushActions.ondemand:
                    this.vibration.vibrate(300);
                    this.openOnDemandModal(data.orderId);
                    break

                case PushActions.schedule:
                    let scheduleTabUrl = '/dashboard/dispatch'
                    if (this.checkUrl(scheduleTabUrl)) {
                        this.navController.navigateRoot(scheduleTabUrl, { animated: true, animationDirection: 'forward', state: { tab: 'schedule' } });
                    }
                    break
            }
        }
    }
}
