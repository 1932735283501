
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AccountProfileService {

    deleteAdditionalImageUrl: string = environment.apiURL + "partner/delete-additional-image";
    updateProfileUrl: string = environment.apiURL + "partner/profile/me";
    profileStatsUrl: string = environment.apiURL + "partner/profileStats";

    constructor(
        private http: HttpClient
    ) { }

    deleteAdditionalImage(data){
        return this.http.post(this.deleteAdditionalImageUrl,data);
    }

    updateProfile(data){
        return this.http.put(this.updateProfileUrl,data);
    }

    async getProfileStats(id?: string) {
        let url = this.profileStatsUrl;
        if (id) {
            url += `?staffId=${id}`;
        }
        return await this.http.get<any>(url).toPromise();
    }
}
