import { Directive, HostListener, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appTextareaAutoresize]'
})
export class TextareaAutoresizeDirective implements AfterViewInit {
    
    private minHeight: number;
    
    constructor(private elementRef: ElementRef) { }

    @HostListener(':input')
    onInput() {
        this.resize();
    }

    ngAfterViewInit() {
        if (this.elementRef.nativeElement.scrollHeight) {
            setTimeout(() => this.resize());
        }
    }

    resize() {
        if (!this.minHeight) {
            const computedStyle = getComputedStyle(this.elementRef.nativeElement);
            this.minHeight = parseFloat(computedStyle.height);
        }
        
        this.elementRef.nativeElement.style.height = '0';
        this.elementRef.nativeElement.style.height = Math.max(this.elementRef.nativeElement.scrollHeight, this.minHeight) + 'px';
    }
}