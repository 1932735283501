import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-vehicle-description',
    templateUrl: './vehicleDescription.component.html',
    styleUrls: ['./vehicleDescription.component.scss']
})
export class VehicleDescriptionComponent {

    _vehicle: any;
    serviceData: any;

    @Input() newVehicle: boolean = false;
    @Input() isCompact: boolean = false;

    get vehicle() {
        return this._vehicle;
    }

    @Input()
    set vehicle(vehicleData: any) {
        this._vehicle = vehicleData;
    }

    @Output() remove = new EventEmitter<any>();

    removeVehicle() {
        this.remove.emit();
    }
}