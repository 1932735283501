<ion-header class="header-v2">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="goBack()">
                <svg-shell src="fi-rr-angle-left.svg" class="primary-i"></svg-shell>
            </ion-button>
        </ion-buttons>
		<ion-title>{{ isOnProfile ? 'Profile' : 'Staff Profile' }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen="true" IonContentOffset>
	<ion-header collapse="condense">
        <ion-toolbar>
            <ion-title class="past-title-v2 text-left">{{ isOnProfile ? 'Profile' : 'Staff Profile' }}</ion-title>
        </ion-toolbar>
    </ion-header>

    <ng-container *ngIf="company?._id && staff?._id">
        <div class="form-con">
            <div class="profile-pic" file-upload (callback)="uploadImage($event, 'profile')" header="Profile Image">
                <div class="initials" *ngIf="!staff.image">{{ staff.firstName?.charAt(0) }}</div>
                <app-image-shell class="user-avatar" animation="spinner" [src]="staff.image | imageResize: '120x120'" *ngIf="!!staff.image"></app-image-shell>
                <div svg-shell src="fi-rr-camera.svg"></div>
            </div>
        
            <div class="user-name">
                <div>{{ staff.firstName }}</div>
                <div>{{ staff.lastName }}</div>
            </div>
        
            <div class="user-info">
                <div>
                    <div svg-shell src="fi-rr-user-time.svg"></div>
                    <div>{{ staff.createdOn | formatDate: 'MMM [\']YY' }}</div>
                </div>
                <div>
                    <div svg-shell src="fi-rr-bolt.svg"></div>
                    <div>{{ staffStats.completed }}x</div>
                </div>
            </div>
        </div>
    
        <ng-container *ngIf="!isOnProfile">
            <div class="accordion">
                <div class="accordion__head" (click)="toggleAccordion(Accordion.Details)">
                    <div class="accordion__head--title">Details</div>
                    <svg-shell class="accordion__head--arrow" src="fi-rr-angle-circle-down.svg" [class.rotate]="openAccordion === Accordion.Details"></svg-shell>
                </div>
    
                <div class="accordion__body" [collapsible]="openAccordion !== Accordion.Details">
                    <ng-container [ngTemplateOutlet]="detailsTemplate"></ng-container>
                </div>
            </div>
    
            <div class="accordion">
                <div class="accordion__head" (click)="toggleAccordion(Accordion.History)">
                    <div class="accordion__head--title">Booking History</div>
                    <button type="button" class="accordion__head--extra flat-btn grey-bg" (click)="$event.stopPropagation(); showAllJob()">
                        Show All
                    </button>
                    <svg-shell class="accordion__head--arrow" src="fi-rr-angle-circle-down.svg" [class.rotate]="openAccordion === Accordion.History"></svg-shell>
                </div>
    
                <div class="accordion__body" [collapsible]="openAccordion !== Accordion.History">
                    <div>
                        <ng-container *ngIf="isLoadingHistory || jobs?.length; else emptyState">
                            <job-card [order]="jobs[0]" [index]="0" [isFetching]="isLoadingHistory" (jobCardClickedEmit)="startJob($event._id)"></job-card>
        
                            <ng-container *ngIf="!isLoadingHistory">
                                <ng-container *ngFor="let order of jobs | slice : 1; let i = index;">
                                    <job-card [order]="order" [index]="i + 1" (jobCardClickedEmit)="startJob($event._id)"></job-card>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        
        <ng-container *ngIf="isOnProfile" [ngTemplateOutlet]="detailsTemplate"></ng-container>
    </ng-container>
</ion-content>

<ion-footer class="footer-v2 ion-no-border" *ngIf="version">
	<ion-toolbar>
		<ion-title style="--color: var(--main-grey-color); font-size: var(--mid-font);">App v{{ version }}</ion-title>
	</ion-toolbar>
</ion-footer>

<ng-template #detailsTemplate>
    <div class="form-con">
        <form [formGroup]="staffForm" (ngSubmit)="updateStaff()">
            <div>
                <div class="input-floating">
                    <input type="text" placeholder=" " formControlName="firstName" clearInput />
                    <label>First Name</label>
                    <control-messages [form]="staffForm" control="firstName"></control-messages>
                </div>
            </div>
        
            <div>
                <div class="input-floating">
                    <input type="text" placeholder=" " formControlName="lastName" clearInput />
                    <label>Last Name</label>
                    <control-messages [form]="staffForm" control="lastName"></control-messages>
                </div>
            </div>

            <div>
                <div class="input-floating iti-input-v2">
                    <input 
                        type="tel" 
                        formControlName="phone" 
                        appIntlTelInput 
                        clearInput
                        [onlyCountries]="[company.activeCountry]"
                        [(validNumber)]="validPhoneNumber"
                    />
                    <label>Phone Number</label>
                    <control-messages [form]="staffForm" control="phone"></control-messages>
                </div>
            </div>

            <div>
                <div class="input-floating">
                    <input type="email" placeholder=" " formControlName="emailId" clearInput />
                    <label>Email</label>
                    <control-messages [form]="staffForm" control="emailId" [message]="validationMessages.email"></control-messages>
                </div>
            </div>

            <div>
                <div class="input-floating typeahead">
                    <input
                        type="text"
                        placeholder=" "
                        googleAutocomplete
                        formControlName="address"
                        [preSelected]="staff.address"
                        [country]="company.activeCountry"
                        (selectAddress)="placeSelected($event)"
                    />
                    <label>Address</label>
                    <control-messages [form]="staffForm" control="address"></control-messages>
                </div>
            </div>

            <ng-container *ngIf="company.companyOperationModel">
                <div>
                    <div class="input-floating">
                        <input type="text" placeholder=" " formControlName="abn" clearInput />
                        <label>Business Number</label>
                    </div>
                </div>
                
                <ng-container *ngIf="company.activeCountry === 'AU' && company.useFielddPay">
                    <div>
                        <div class="input-floating">
                            <input type="text" placeholder=" " formControlName="bsb" clearInput />
                            <label>{{ 'BSB' | bankAccountType }}</label>
                        </div>
                    </div>
                    <div>
                        <div class="input-floating">
                            <input type="text" placeholder=" " formControlName="account" clearInput />
                            <label>Bank Account</label>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <div>
                <div class="input-floating">
                    <textarea
                        placeholder=" " 
                        formControlName="bio" 
                        rows="2"
                        clearInput
                        appTextareaAutoresize
                    ></textarea>
                    <label>Bio</label>
                </div>
            </div>

            <div>
                <div class="staff-bio-pics">
                    <label>Bio Pics</label>
                    <div class="staff-bio-pics-con">
                        <div class="file-input" file-upload 
                            *ngFor="let x of [].constructor(8); let i = index;"
                            [disabled]="!!staff.additionalImages[i]"
                            (callback)="uploadImage($event, 'bio')"
                            (click)="zoomImage(i)">
                            <app-image-shell *ngIf="staff.additionalImages[i]" [src]="staff.additionalImages[i] | imageResize" alt="assets/img/fi-rr-picture.svg" animation="spinner"></app-image-shell>
                            <svg-shell *ngIf="!staff.additionalImages[i]" src="fi-rr-picture.svg"></svg-shell>
                        </div>
                    </div>
                </div>
            </div>
            
            <div>
                <div class="user-button" *ngIf="isOnProfile">
                    <div class="user-button__delete" (click)="openDeleteStaffPopUp()">
                        Delete
                    </div>
                    <div class="user-button__logout" (click)="logout()">
                        Logout
                    </div>
                </div>

                <div>
                    <button type="submit"
                        class="flat-btn bordered grey-bg loading-btn" [class.is-loading]="isLoading">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #emptyState>
    <empty-state 
        icon="fi-rr-map-marker-thin.svg" 
        title="No Jobs Found"
    ></empty-state>
</ng-template>