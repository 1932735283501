import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { cloneDeep, isEqual } from 'lodash-es';

import { BookingQuestion } from '@modules/add-job-module/add-job.model';
import { AssetItemResponse } from '@modules/statusModule/status.model';

import { CommonService } from '@services/common.service';
import { LoadingService } from '@services/loading.service';
import { AlertType, AlertService } from '@services/alert.service';

@Component({
    selector: 'app-api-question',
    templateUrl: './api-question.component.html',
    styleUrls: ['./api-question.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            deps: [[Optional, NgForm]],
            useFactory: (ngForm: NgForm) => ngForm,
        },
    ],
})
export class ApiQuestionComponent implements OnInit {

    isSubmitted: boolean = false;

    get isApiQuestionValid(): boolean {
        return !this.apiQuestion.compulsory || !this.tempData.addMode || ((this.tempData.loaded || this.tempData.apiError) && this.apiQuestion.compulsory);
    }

    API_QUES_CONFIG = {
        vehicle: {
            plate: '',
            state: '',
        }
    };

    API_QUES_ERROR = {
        vehicle: ['year', 'make', 'model'],
    };

    states: Array<string> = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];

    tempData: any = {};
    openExtraDataSelection: boolean = false;

    @Input() isAssetItem: boolean = false;
    @Input() assetItem: AssetItemResponse;

    _apiQuestion: BookingQuestion;
    get apiQuestion() {
        return this._apiQuestion;
    }

    @Input()
    set apiQuestion(question: BookingQuestion) {
        question = cloneDeep(question);

        const _apiQuestion = { ...question, questionData: question.questionData || {} };
        let tempDataObj = {
            loaded: !_apiQuestion.extraCustomerDataId && Object.keys(_apiQuestion.questionData)?.length,
            loading: false,
            apiError: false,
            addMode: !_apiQuestion.extraCustomerDataId,
        };
        if (this.API_QUES_CONFIG[question.subType]) {
            tempDataObj = { ...tempDataObj, ...this.API_QUES_CONFIG[question.subType] };
        }

        for (const key of Object.keys(this.tempData)) {
            if (this._apiQuestion.questionData.hasOwnProperty(key)) {
                tempDataObj[key] = this._apiQuestion.questionData[key];
            }
        }

        if (isEqual(this._apiQuestion, _apiQuestion)) {
            return;
        }

        this._apiQuestion = cloneDeep(_apiQuestion);
        this.tempData = tempDataObj;
    }

    @Input() floatingInput: boolean = false;

    @Input() selectedAddressComponents: google.maps.GeocoderAddressComponent[];
    @Output() apiQuestionChange = new EventEmitter<BookingQuestion>();

    constructor(
        private alertService: AlertService,
        private commonService: CommonService,
        private loadingService: LoadingService,
    ) { }

    ngOnInit() {
        if (this.isAssetItem) {
            const plate = this.assetItem?.extraData?.plate;
            const state = this.assetItem?.extraData?.state;

            let tempDataObj = {
                loaded: !!(state && plate),
                loading: false,
                apiError: false,
                addMode: true,
            };
            if (this.API_QUES_CONFIG[this.assetItem?.subType]) {
                tempDataObj = { ...tempDataObj, ...this.API_QUES_CONFIG[this.assetItem.subType] };
            }
            this.tempData = tempDataObj;

            this.tempData.plate = plate;
            this.tempData.state = state;

            this._apiQuestion = {
                ...(this._apiQuestion || {}),
                ...(this.assetItem || {}),
                questionData: this.assetItem?.extraData || {},
            } as any;
        }
    }

    onStateVisibilityChange(isVisible: boolean) {
        if (!isVisible || this.apiQuestion?.subType !== 'vehicle' || this.tempData?.state) {
            return;
        }

        let state = 'AL';

        if (Object.keys(this.selectedAddressComponents || {}).length) {
            const values = Object.values(this.selectedAddressComponents);
            for (let i = 0; i < values.length; i++) {
                const value = values[i];
                if (value?.types?.includes('administrative_area_level_1') && this.states.includes(value.short_name)) {
                    state = value.short_name;
                    break;
                }
            }
        }

        if (!this.tempData) {
            this.tempData = {};
        }
        this.tempData.state = state;
    }

    removeVehicle(question: any) {
        this.tempData.loaded = false;
        this.tempData.loading = false;
        question.questionData = {};

        this.emitChange();
    }

    fetchData(data: any, subType: string) {

        if (!this.API_QUES_CONFIG[subType]) {
            //show error
            return;
        }

        for (const key of Object.keys(this.API_QUES_CONFIG[subType])) {
            if (!data[key]) {
                //show error
                this.alertService.showToastMessage(`Please enter ${key} to search for your ${this.apiQuestion.subType}`, AlertType.Error);
                return;
            }
        }

        this.tempData.loading = true;
        this.tempData.loaded = false;
        this.tempData.apiError = false;

        let dataToSend = { subType, data };

        return this.loadingService.httpWrapperLoader(
            this.commonService.getLookupData(dataToSend),
            false
        ).then((response) => {
            this.handleApiResponse(response?.data, response?.value || '');
            this.emitChange();
        });
    }

    validateApiQuestion(): boolean {
        this.setValueString();
        this.emitChange();
        this.isSubmitted = true;
        return this.isApiQuestionValid;
    }

    handleApiResponse(data: any = null, value: string = '') {
        this.tempData.loading = false;
        let customData = this.getEnteredCustomData();
        if (!data) {
            //show error
            this.alertService.showToastMessage('Your license plate doesn\'t exist in our records. Please enter the data manually.', AlertType.Error);
            this.tempData.apiError = true;
            this._apiQuestion.questionData = { ...customData };
            return;
        }
        this._apiQuestion.questionData = { ...customData, ...data };
        this._apiQuestion.questionValue = value;
        this.tempData.loaded = true;
    }

    emitChange() {
        this.apiQuestionChange.emit(this.apiQuestion);
    }

    setValueString() {
        if (this.tempData.addMode && this.tempData.apiError) {
            this._apiQuestion.questionValue = this.createValueString(this._apiQuestion.questionData, this.API_QUES_ERROR[this.apiQuestion.subType]);
        }
    }

    createValueString(data: string, keys: string[] = []): string {
        let string = '';
        try {
            string = keys.map(k => (data[k] ? `${k}: ${data[k]}` : '')).join(', ');
        } catch (e) {
            //
        } finally {
            return string;
        }
    }

    getEnteredCustomData(): any {
        let customData = {};
        try {
            for (const k of this.apiQuestion.subOptions) {
                if (this.apiQuestion.questionData[k]) {
                    customData[k] = this.apiQuestion.questionData[k];
                }
            }
        } catch (e) {
            //
        } finally {
            return customData;
        }
    }
}