import { Injectable } from "@angular/core";
import { ModalController, ModalOptions } from "@ionic/angular";

import { ThemeService } from "./theme.service";

import { AnyKeyValue, ModalComponents } from "@data/models/shared.model";
import { modalRtEnterAnimation, modalRtLeaveAnimation } from "../animations/route.transitions";

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private openedModals: Set<string> = new Set<string>();

    constructor(
        private themeService: ThemeService,
        private modalController: ModalController,
    ) { }

    private importComponent(modalComponent: any): Promise<any> {
        switch (modalComponent) {
            case ModalComponents.JobListComponent:
                return import('@components/job-list/job-list.component').then(m => m.JobListComponent);
            case ModalComponents.AddJobComponent:
                return import('@modules/add-job-module/pages/add-job/add-job.component').then(m => m.AddJobComponent);
            case ModalComponents.SuggestedStaffComponent:
                return import('@modules/add-job-module/pages/suggested-staff/suggested-staff.component').then(m => m.SuggestedStaffComponent);
            case ModalComponents.JobWarningsComponent:
                return import('@modules/add-job-module/pages/job-warnings/job-warnings.component').then(m => m.JobWarningsComponent);
            case ModalComponents.AssetDetailsComponent:
                return import('@components/asset-details/asset-details.component').then(m => m.AssetDetailsComponent);
            case ModalComponents.FilterModalComponent:
                return import('@components/filter-modal/filter-modal.component').then(m => m.FilterModalComponent);
            case ModalComponents.PriceListComponent:
                return import('@components/price-list/price-list.component').then(m => m.PriceListComponent);
            default:
                // Use this to avoid circular dependencies
                return Promise.resolve(modalComponent);
        }
    }

    // Warning::: Do not import modalService in any of the above components
    openComponentModal(modalComponent: any, componentProps?: AnyKeyValue, cssClass: string = '', dismissCb?: (arg0: any) => void): Promise<HTMLIonModalElement> {
        if (this.openedModals.has(String(modalComponent))) {
            return;
        }
        this.openedModals.add(String(modalComponent));

        const modalRtClass = 'modal-rt';
        const isModalRt = cssClass?.includes(modalRtClass);

        const promises = Promise.all([
            this.importComponent(modalComponent),
            this.modalController.getTop(),
        ]);

        return promises.then(([component, parentModal]) => {
            const modalOptions: ModalOptions = {
                component: component,
                componentProps: { ...(componentProps || {}), modalService: this },
                backdropDismiss: false,
                keyboardClose: false,
                cssClass: cssClass,
                mode: 'ios',
            };

            if (isModalRt) {
                modalOptions.enterAnimation = modalRtEnterAnimation;
                modalOptions.leaveAnimation = modalRtLeaveAnimation;

                this.themeService.setStatusBarForModalRt();
            } else {
                modalOptions.presentingElement = parentModal || document.querySelector('ion-router-outlet');
            }

            return this.modalController.create(modalOptions).then((modal: HTMLIonModalElement) => {
                modal.onDidDismiss().then((data) => {
                    this.openedModals.delete(String(modalComponent));

                    if (isModalRt) {
                        this.themeService.setStatusBarForModalRt(false);
                    }

                    if (typeof dismissCb === 'function') {
                        dismissCb(data);
                    }
                });

                return modal.present().then(() => modal);
            })
        }).catch((e) => {
            this.openedModals.delete(String(modalComponent));
            throw e;
        });
    }

    closeModal(data?: any): Promise<boolean> {
        return this.modalController.getTop().then((modal: HTMLIonModalElement) => modal?.dismiss(data));
    }

    closeAllModal(): Promise<boolean> {
        return this.modalController.getTop().then((modal: HTMLIonModalElement) => {
            return modal ? modal.dismiss().then(() => this.closeAllModal()) : true;
        });
    }
}