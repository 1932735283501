<div *ngIf="job._id === 'LINE'" class="current-time"></div>
<div *ngIf="job._id !== 'LINE'" class="job-card" [ngStyle]="{ 'animation-duration': animationIndex * 0.2 + 's' }"
    [class.job-done]="isJobCompleted(job.status)" [class.job-cancelled]="job.status === bookingStatus.Cancelled" 
    [class.animate]="animate" (click)="clickJob()">
    <!-- Left Grey Column -->
    <div class="job-card-l">
        <!-- Start Time -->
        <div class="job-card-time">
            {{ (job.status === 0 && !job.recurringBookingId?._id) ? 'ASAP' : startTime }}
        </div>
        <div class="job-card-time-done-con" *ngIf="endTime && (job.status !== 0 || (job.recurringBookingId?._id && job.recurringBookingId.endTime != -1))">
            To
            <!-- Completion Time -->
            <div class="job-card-time-done">
                {{ job.recurringBookingId?._id ? job.recurringBookingId.endTime : endTime }}
           </div> 
       </div>
       <!-- Status -->
       <div class="job-status" *ngIf="job.status > 1">
           {{ statusFormat(job.status) }}
       </div>
       <!-- Total Job Duration -->
        <div class="job-card-total-time" *ngIf="serviceTime">
            <div class="job-card-stopwatch">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><defs><style>.fa-secondary{opacity:.4}</style></defs>
                    <path d="M224 96C109.12 96 16 189.12 16 304s93.12 208 208 208 208-93.12 208-208S338.88 96 224 96zm32 240a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16V208.5a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16z"
                    class="fa-secondary" fill="var(--ion-color-primary)"/><path d="M240 192.5h-32a16 16 0 0 0-16 16V336a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V208.5a16 16 0 0 0-16-16zm188.53-48.57l-28.3-28.3a12 12 0 0 0-17 0l-27.45 27.45a209.14 209.14 0 0 1 42.8 47.8l.55-.55 29.4-29.4a12 12 0 0 0 0-17zM280 0H168a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h24v34.45a210 210 0 0 1 64 0V64h24a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16z"
                    class="fa-primary" fill="var(--ion-color-primary)"/>
                </svg>
            </div>
            <div class="job-card-duration">
                {{ serviceTime }}
            </div>
        </div>
    </div>
    <!-- Middle Job Details Column -->
    <div class="job-card-m-bg">
        <div class="job-card-m">
            <!-- Name of Job -->
            <div class="job-card-title">
                {{ serviceDescription }}
            </div>
            <!-- Address -->
            <div class="job-address-con" *ngIf="job.jobAddress">
                <div class="job-address-pin">
                    <img src="assets/img/gmap-pin.svg">
                </div>
                <div class="job-address">
                    {{ job.jobAddress | address }}
                </div>
            </div>
            <!-- Customer Name -->
            <div class="job-customer-con">
                <div class="job-customer-i">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><defs><style>.fa-secondary{opacity:.4}</style></defs>
                        <path d="M400 144A144 144 0 1 1 256 0a144 144 0 0 1 144 144z"
                        class="fa-secondary" fill="var(--ion-color-primary)"/><path d="M384 320h-55.1a174.1 174.1 0 0 1-145.8 0H128A128 128 0 0 0 0 448v16a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48v-16a128 128 0 0 0-128-128z"
                        class="fa-primary" fill="var(--ion-color-primary)"/>
                    </svg>
                </div>
                <div class="job-customer-name">
                    {{ job.customerFirstName }} {{ job.customerLastName?.length ? job.customerLastName[0] : '' }}
                </div>
            </div>
            <!-- Partner Name -->
            <div class="job-customer-con" *ngIf="showPartnerName && job.status !== 0 && job.partnerName?.length">
                <div class="job-customer-i">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                        <g fill="var(--ion-color-primary)" fill-rule="nonzero">
                            <path d="m29.341 64.127 2.631-10.942-5.056-8.658h15.168l-5.056 8.658 2.63 10.942L34.5 74.212l-5.159-10.085ZM34.5 39.58c11.17 0 20.224-8.86 20.224-19.79S45.67 0 34.5 0C23.33 0 14.276 8.86 14.276 19.79S23.33 39.58 34.5 39.58Z"
                                opacity=".4" />
                            <path d="M49.255 45.485 34.5 74.199 19.745 45.485C8.764 45.995 0 54.74 0 65.558v6.24C0 75.777 3.31 79 7.393 79h54.214C65.69 79 69 75.776 69 71.799v-6.241c0-10.817-8.764-19.563-19.745-20.073Z" />
                        </g>
                    </svg>
                </div>
                <div class="job-customer-name">
                    {{ job.partnerName }}
                </div>
            </div>
            <!-- Price -->
            <div class="job-price-con" *ngIf="!hidePricing">
                <div class="job-price-i">
                    <img src="assets/img/money-bill-g.svg">
                </div>
                <div class="job-customer-name">
                    {{ job.amount | fielddCurrency }}
                </div>
            </div>
            <!-- orderId -->
            <div class="job-price-con" *ngIf="!hidePricing">
                <div class="job-price-i">
                    #
                </div>
                <div class="job-customer-name">
                    {{ job.orderId }}
                </div>
            </div>
        </div>
    </div>
    <!-- Right Job Messages & Status Column -->
    <div class="job-card-r">
        <!-- Messages -->
        <div class="job-message-icon" *ngIf="job.messages?.length" (click)="navigateToComms($event)">
            <i class="new-notification" [ngClass]="{hidden: !hasNotification }"></i>
            <img src="assets/img/comments-blue.svg" *ngIf="!isJobCompleted(job.status)">
            <img src="assets/img/comments-grey.svg" *ngIf="isJobCompleted(job.status)">
        </div>
        <!-- Job Type Image -->
        <div class="job-icon" *ngIf="job.status === 1">
            <img *ngIf="variantImageUrl" [src]="variantImageUrl | imageResize: '120x120'" loading="lazy" />
        </div>
    </div>
</div>