import { Directive, AfterViewInit, OnDestroy, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { observeResize } from '../../utils/helper-functions';

@Directive({
    selector: '[dropdown]',
})
export class DropdownDirective implements AfterViewInit, OnDestroy {

    dropdownEl: HTMLElement;
    mainBody: HTMLElement;

    dropdownObserver: Subscription;
    bodyObserver: Subscription;
    
    private _dropdown: boolean = false;
    public get dropdown(): boolean {
        return this._dropdown;
    }

    @Input()
    public set dropdown(value: boolean) {
        this._dropdown = value;
        this.toggleDropdownClass();
    }

    @Output() dropdownChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private el: ElementRef) { }

    ngAfterViewInit() {
        const el = this.el.nativeElement;

        this.dropdownEl = document.createElement('div');
        this.dropdownEl.setAttribute('dropdown', '');

        const backdrop = document.createElement('div');
        backdrop.classList.add('backdrop');
        backdrop.addEventListener('click', () => this.dropdownChange.emit(false));

        this.dropdownEl.appendChild(backdrop);

        this.mainBody = document.createElement('div');
        this.mainBody.classList.add('dropdown-body');

        Array.from(el.childNodes).forEach((node: any) => {
            this.mainBody.appendChild(node);
        });

        this.dropdownEl.appendChild(this.mainBody);
        document.body.appendChild(this.dropdownEl);

        this.dropdownObserver = observeResize(el).subscribe(this.onChange.bind(this));
        this.bodyObserver = observeResize(this.mainBody).subscribe(this.onChange.bind(this));
    }

    onChange() {
        const parentRect = this.el.nativeElement.getBoundingClientRect();
        let top: number = parentRect.top + parentRect.height;
        let right: number = document.documentElement.clientWidth - (parentRect.left + parentRect.width);
        let bottom: number;
        let left: number = parentRect.left;

        if (top < 22) {
            top = 22;
        }

        if (this.mainBody) {
            const bodyRect = this.mainBody.getBoundingClientRect();

            if (top + bodyRect.height > (document.documentElement.clientHeight - 44)) {
                bottom = 22;
            }
        }

        this.dropdownEl.style.setProperty('--top', top + 'px');
        this.dropdownEl.style.setProperty('--right', right + 'px');

        if (bottom) {
            this.dropdownEl.style.setProperty('--bottom', bottom + 'px');
        } else {
            this.dropdownEl.style.removeProperty('--bottom');
        }

        this.dropdownEl.style.setProperty('--left', left + 'px');

        this.toggleDropdownClass();
    }

    toggleDropdownClass() {
        this.dropdownEl?.classList[this.dropdown ? 'add' : 'remove']('is-open');
    }

    ngOnDestroy() {
        this.dropdownObserver?.unsubscribe();
        this.bodyObserver?.unsubscribe();

        this.dropdownEl.remove();
    }
}