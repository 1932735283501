import { BookingSource } from '@data/models/shared.model';
import * as moment from 'moment'

export interface Zone {
    cityName: string,
    timezone: string,
    zoneName: string,
    cityLocation: FieldGeoLocation,
    polygons: any,
    id: string
}

export interface FieldGeoLocation {
    latitude: number;
    longitude: number
}

export interface SlotObject {
    _id: { hour: number, minutes: number },
    partner: any;
    key: string,
    name: string,
    partnerId: string,
    zone:any,
    mergeCount: number
}

export interface RescheduleRequest {
    orderId: String,
    selectedSlots: SlotObject[],
    selectedDate: moment.Moment,
    timezone: String,
    companyId: String,
    rescheduleReason: String,
}

export interface Variant {
    timeSlotsRequired: any;
    staffRequired: number;
    numberOfBookings: number;
    fullName: string,
    name: string,
    price: number,
    id: string,
    pid: string,
    showTaxes: boolean,
    discountedPrice:number,
    discountAmount: number,
    primaryImageUrl: string,
    serviceId: string,
    freeKms: number,
    isKmsRateEnabled: boolean,
    isTravelChargeEnabled: boolean,
    minimumTravelCost: number,
    ratePerKms: number,
    chargeForTime: boolean,
    chargeForTimeRate: number,
    hasPriceList:boolean,
    selectedPriceNodeId:string,
    requiresQuote: boolean,
    forFixed: boolean;
    forMobile: boolean;
    combineMultipleJobSlots: boolean;
    lineItems:any[];
    hasExtraQuestions: boolean;
    extraQuestionsMainTitle: string;
    showServiceTime: boolean;
    totalServiceTime: number;
    serviceTime: string;
    hasRepeatOptions: boolean;
    repeatJobOptions: any;
    repeatOptionSelected: any;
}

export interface GetFreeSlotsRequest {
    timeSlotsRequired: number;
    quoteReqOrderId: string;
    cityZoneId: string,
    zoneIds: Array<string>,
    dateTime: number,
    startEpoch: number,
    endEpoch: number,
    requestedDate: number,
    requestedYear: number,
    requestedMonth: number,
    timezone: string,
    companyID: string,
    variantID: string,
    totalDurationInMin: number,
    serviceId: string,
    emailID: string,
    inventory: Array<object>;
    numberOfBookings: number;
    fixedLocation: string;
    skip: number;
    limit: number;
    orderId: string;
}

export interface ScheduleDetails {
    partnerId: string,
    hour: number,
    minutes: number,
    scheduledOn: number,
    requestedDate: number,
    requestedMonth: number,
    requestedYear: number,
    dateTime: number,
    partnerName: string,
    zone:any,
    mergeSlotCount: number
}

export interface Order{
    zones: [];
    cityZoneId: any;
    _id: string,
    customerId: string,
    companyID: string,
    scheduledOn: string,
    partnerName: string,
    partnerPhone: number,
    partner: any,
    customerFirstName: string,
    customerLastName: string,
    customerPhone: string,
    customerEmail: string,
    promoCode: string,
    discountedAmount: number,
    jobAddress: any,
    amount: number,
    variants: any,
    geolocation: FieldGeoLocation,
    status: number,
    id: string,
    createdOn: number,
    timeArrived: number,
    timeEnroute: number,
    timeRefreshing: number,
    timeDone: number,
    cancelTime: number,
    bookingImage: string,
    tax: number,
    tip: number,
    timezone: string,
    bookingSource: BookingSource,
    inventory: Array<any>,
    enroute: any,
    rating: string,
    comment: string,
    timeInvoiced: number,
    timeAccepted: number,
    timePhotosTaken: number,
    totalDiscount: number,
    customerObject: any,
    selectedCard: any,
    selectedShop: any;
    routeAddresses: any[];
}

export enum AssetsInputTypes {
    TEXT = 'text',
    PARAGRAPH = 'paragraph',
    PHOTOS = 'photos',
    ADDRESS = 'address',
    LICENSE_PLATE = 'vehicle',
}

export interface AssetItem {
    inputType: AssetsInputTypes;
    label: string;
    position?: number;
    subType?: string;
    subIntegrations?: any;
    subOptions?: string[];
}

export interface AssetItemResponse extends AssetItem {
    value?: string;
    extraData?: any;
    state?: string;
    plate?: string;
}

export interface AssetDBItem {
    _id: string;
    profileImage?: string;
    data: AssetItemResponse[];
}

export interface MappedAsset extends AssetDBItem {
    image?: string;
    title?: string;
    subtitle?: string;
    customerEmail?: string;
}