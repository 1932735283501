import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'imageResize' })
export class ImageResizePipe implements PipeTransform {
    private readonly BASE_URLS = [
        "https://fieldd-images.s3.ap-southeast-2.amazonaws.com/",
        "https://fieldd-images.s3-ap-southeast-2.amazonaws.com/",
        "https://fieldd-images.s3.amazonaws.com/",
    ] as const;

    private readonly REDIRECT_URL = "https://dn0xhy2xsjuze.cloudfront.net/";

    transform(value: any, size: string = null): string {
        if (!value || typeof value !== 'string' || value.startsWith('data:') || value.startsWith('blob:')) {
            return value || '';
        }

        let toReplace = this.REDIRECT_URL;
        if (size && !value.includes('.svg') && !value.includes('.csv') && !value.includes('.pdf')) {
            toReplace += size + '/';
        }

        this.BASE_URLS.forEach((url) => value = value.replace(url, toReplace));
        return value;
    }
}