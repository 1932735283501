<div class="calendar-comp">
    <div class="calendar-comp__title">
        <button 
            type="button"
            class="week-arrow-b" 
            (click)="onMonthChangedBtnClicked(false)" 
            svg-shell src="fi-rr-angle-circle-down.svg"
        ></button>

        <div class="cal-date-top container-title bold">
            {{ activeMonthDate | formatDate: 'MMMM YYYY' }}
        </div>

        <button 
            type="button"
            class="week-arrow-f" 
            (click)="onMonthChangedBtnClicked(true)" 
            svg-shell src="fi-rr-angle-circle-down.svg"
        ></button>
    </div>

    <div (swipe)="swipeEvent($event)" class="calendar-comp__item">
        <div *ngFor="let day of currentMonth | slice: 0:7" class="calendar-comp__item--day">
            {{ day.date | formatDate: 'dd' }}
        </div>

        <div *ngFor="let day of currentMonth" class="calendar-comp__item--day">
            <button 
                type="button" 
                (click)="daySelected(day.date)" [disabled]="!day.enabled"
                [class.today]="day.date === todaysStr && day.date !== date"
                [class.visibility-hidden]="!day.display"
                [class.on-selected]="day.date === date">
                {{ day.date | formatDate: 'DD' }}
            </button>
        </div>
    </div>
</div>