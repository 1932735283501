import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Directive({
    selector: '[collapsible]'
})
export class CollapsibleDirective implements OnChanges {

    @Input() collapsible: boolean;

    togglePromise: Promise<void> = Promise.resolve();
    toggle$ = new Subject<boolean>();

    constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) {
        this.toggle$.pipe(
            switchMap((collapsed) => this.togglePromise.then(() => collapsed))
        ).subscribe((collapsed) => {
            this.togglePromise = this.toggleCollapsible(collapsed);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.collapsible) {
            return;
        }

        if (changes.collapsible.firstChange) {
            if (changes.collapsible.currentValue) {
                this.elementRef.nativeElement.style.display = 'none';
            }
        } else {
            this.toggle$.next(changes.collapsible.currentValue);
        }
    }

    toggleCollapsible(collapsed: boolean) {
        return new Promise<void>((res) => {
            const el = this.elementRef.nativeElement;
            el.style.overflow = 'hidden';
            el.style.transition = 'height 250ms linear';

            if (collapsed) {
                el.style.height = el.clientHeight + 'px';

                setTimeout(() => {
                    this.transitionEnd().then(() => {
                        this.removeProperties(el);
                        el.style.display = 'none';

                        setTimeout(res);
                    });

                    el.style.height = '0px';
                });
            } else {
                el.style.removeProperty('display');
                const height = el.clientHeight;

                el.style.height = '0px';

                setTimeout(() => {
                    this.transitionEnd().then(() => {
                        this.removeProperties(el);
                        setTimeout(res);
                    });

                    el.style.height = height + 'px';
                });
            }
        });
    }

    transitionEnd() {
        return Promise.race([
            new Promise((res) => setTimeout(res, 250)),
            new Promise<void>((res) => this.elementRef.nativeElement.addEventListener('transitionend', () => res(), { once: true })),
        ]);
    }

    removeProperties(el: HTMLElement) {
        el.style.removeProperty('transition');
        el.style.removeProperty('overflow');
        el.style.removeProperty('height');
    }
}