import { Component, Input, OnInit } from "@angular/core";
import { ModalController, PickerController } from "@ionic/angular";

import { cloneDeep } from "lodash-es";
import { FielddCurrencyPipe } from "@pipes/fielddCurrency.pipe";

interface TreeNode {
    id: string;
    isTerminal: Boolean;
    hierarchyLevel: Number;
    title: string;
    nodes: TreeNode[];
    price: Number;
    costPrice: Number;
    text: string;
}

@Component({
    selector: 'price-list',
    templateUrl: './price-list.component.html',
    styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit {

    @Input() priceListDoc: any = {};
    @Input() variant: any = {};

    variantName: string = '';
    selectPriceNodeId: any;

    currentPriceList: TreeNode[] = [];
    headers: string[] = [];

    data: any = null;

    selectedTreeNode: TreeNode | null = null;

    subItems = [];
    dummyNames = [];
    dummyOptions: { text: string; value: number; }[][] = [];

    constructor(
        private currencyPipe: FielddCurrencyPipe,
        private modalController: ModalController,
        public pickerController: PickerController,
    ) { }

    ngOnInit() {
        this.currentPriceList = (this.priceListDoc.priceList || []).filter((x) => !(x.isTerminal && !x.nodes?.length && x.price === 0 && x.title === 'null'));
        this.headers = this.priceListDoc.listHeaders || [];

        this.currentPriceList = this.currentPriceList;

        this.data = {
            nodes: [...this.currentPriceList],
            title: 'root',
        }

        this.dummyOptions[0] = this.getOptions(this.data.nodes);
    }

    getOptions(nodes: TreeNode[]) {
        return cloneDeep(nodes).map((x: TreeNode, i: number) => {
            return {
                text: x.price ? x.title + ' - ' + this.currencyPipe.transform(x.price) : x.title,
                value: i
            }
        });
    }

    selectNode(node: TreeNode, hierarchyLevel: number): void {
        if (!node) return;

        if (node.isTerminal) {
            this.selectedTreeNode = node;

            this.variantName = this.variantName || this.variant?.name || ''; // TDOD: validate
            const variant = {
                price: node.price,
                costPrice: node.costPrice,
                selectedPriceNodeId: String(node.id),
                name: this.variantName + ' ' + this.getPriceHierarchyName(this.currentPriceList, node.id),
                pricingTag: this.variant.pricingTag,
            };
            this.selectPriceNodeId = variant;
        } else {
            this.subItems[hierarchyLevel] = node;
            let counter = hierarchyLevel + 1;
            this.dummyOptions[counter] = this.getOptions(node.nodes);
            this.dummyNames[counter] = undefined;
            while (this.subItems[counter] || this.dummyNames[counter]) {
                this.dummyNames[counter] = undefined;
                this.subItems[counter++] = null;
            }

            this.selectPriceNodeId = null;
        }
    }

    getPriceHierarchyName(list: TreeNode[], id: string) {
        let name = '';
        let index = String(id).split('.');
        if (list[index[0]].isTerminal) {
            return list[index[0]].title;
        } else if (index.length > 1) {
            name = list[index[0]].title + ' ' + this.getPriceHierarchyName(list[index[0]].nodes, String(id).substring(String(id).indexOf('.') + 1, String(id).length));
        }
        return name;
    }

    selectData() {
        return this.closeModal(this.selectPriceNodeId);
    }

    closeModal(data?: any) {
        return this.modalController.dismiss(data);
    }
}