import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { CommonService } from '../../../services/common.service';
import { LoadingService } from '../../../services/loading.service';
import { AlertService } from '../../../services/alert.service';
import { AuthenticationService } from '../../../services/auth.service';
import { CustomerService } from '../../../modules/customerModule/customer.service';
import { StaffService } from '../../../modules/staffModule/staff.service';

@Component({
    selector: 'app-job-list',
    templateUrl: 'job-list.component.html',
})
export class JobListComponent implements OnInit {
    
    @Input() selectedId : string;
    @Input() userType : string;
    @Input() jobs : Array<any> = [];
    
    public company: any = {};

    public jobApiCalled: boolean = false;
    public hasMore: boolean = true;
    private paginationInfo: any = {
        skip: 0,
        limit: 20,
    };
    title: string;

    constructor(
        private customerService: CustomerService,
        private staffService: StaffService,
        private commonService: CommonService,
        private authenticationService: AuthenticationService,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private navController: NavController,
        private modalController:ModalController
    ) { }

    ngOnInit() {
        this.company = this.authenticationService.getCurrentCompany();

        this.title = (this.userType === 'customer' ? 'Customer' : 'Staff') + ' Job History';

        if (this.jobs?.length) {
            this.paginationInfo.skip += this.jobs.length;
        }
        this.getJobs();
    }

    async getJobs(event?: any) {
        const dataToSend = {
            ...this.paginationInfo,
            sortOn: 'scheduledOn'
        };
        if (!event) {
            await this.loadingService.showLoader(true);
        } 

        let res: Promise<any>;
        switch (this.userType) {
            case 'customer':
                dataToSend.customerId = this.selectedId;
                res = this.customerService.getCustomerJobs(dataToSend);
                break;
            case 'staff':
                dataToSend.partnerId = this.selectedId;
                res = this.staffService.getStaffJobs(dataToSend);
                break;
        }

        res.then(async (res) => {
            await this.loadingService.hideLoader();
            if (res?.jobs) {
                this.hasMore = res.jobs.length === this.paginationInfo.limit;
                this.paginationInfo.skip += res.jobs.length;

                this.jobs = [...this.jobs, ...res.jobs];
            } else {
                this.alertService.showErrorDialog(`Temporary delay getting ${this.userType} jobs, please try again`);
            }
        }).catch(async () => {
            await this.loadingService.hideLoader();
            this.alertService.showErrorDialog(`Temporary delay getting ${this.userType} jobs, please try again`);
        }).finally(async () => {
            if (event) {
                event.target.complete();
            }
            this.jobApiCalled = true;
        });
    }

    async startJob(job: any) {
        await this.loadingService.showLoader(true);
        this.commonService.getOrderDetails(job._id).subscribe(async (response) => {
            await this.loadingService.hideLoader();
            if (response?.success && response?.data?._id) {
                this.closeModal();
                this.commonService.setCurrentJob(response.data, `${this.userType}/${this.selectedId}`);
                this.navController.navigateRoot("/status", { animationDirection: 'forward' });
            }
        });
    }

    refreshJobs(event: any) {
        this.resetList();
        this.getJobs(event);
    }

    resetList() {
        this.jobs = [];
        this.hasMore = true;
        this.jobApiCalled = false;
        this.paginationInfo = { skip: 0, limit: 20, };
    }

    closeModal(data?: any) {
        this.modalController.dismiss(data);
    }
}