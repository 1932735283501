import { inject, Pipe, PipeTransform } from '@angular/core';
import { CommonHelperService } from '@services/helper.service';

@Pipe({ name: 'formatPhone' })
export class FormatPhonePipe implements PipeTransform {
    private commonHelper = inject(CommonHelperService);

    transform(phoneNumber: string, activeCountry: string = null): string {
        return this.commonHelper.formatPhoneNumber(phoneNumber, activeCountry);
    }
}