import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@ionic/angular';

import { StatusBar } from '@ionic-native/status-bar/ngx';
import { distinctUntilChanged, filter, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    
    private platformReady: Promise<void>;
    private isIos: boolean = false;
    private hasThemeBackground: boolean = false;

    private mainColourHex: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private statusBar: StatusBar,
        private platform: Platform,
        private router: Router,
    ) {
        this.platformReady = this.platform.ready().then(() => {
            this.isIos = this.platform.is('ios');

            if (this.isIos) {
                this.addRouterListener();
            }
        });
    }

    addRouterListener() {
        this.hasThemeBackground = this.isPageWithThemeBackground();
        
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.isPageWithThemeBackground()),
            distinctUntilChanged(),
        ).subscribe((dashboard) => {
            this.hasThemeBackground = dashboard;
            this.onDashboardChange();
        });
    }

    isPageWithThemeBackground() {
        return window.location.href?.includes('/dashboard/') || window.location.href?.includes('/calendar');
    }

    setTheme(settings: any) {
        const colorHex = this.checkReturnHex(settings?.mainColourHex || '#0F69FF');
        if (this.mainColourHex === colorHex) return;
        this.mainColourHex = colorHex;

        const rgb = this.hexToRgb(colorHex);
        this.document.documentElement.style.setProperty('--ion-color-primary', colorHex);
        const rgbStr = `${rgb.r}, ${rgb.g}, ${rgb.b}`;
        this.document.documentElement.style.setProperty('--ion-color-primary-rgb', rgbStr);

        this.document.documentElement.style.setProperty('--fi-rr-marker', this.markerSvg());
        this.document.documentElement.style.setProperty('--fi-rr-marker-h', this.markerSvg(rgbStr));

        this.styleStatusBar(colorHex);
    }

    markerSvg(fill: string = '191, 192, 208') {
        return `url("data:image/svg+xml,%3Csvg viewBox='0 0 15 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='rgb(${fill})' fill-rule='nonzero'%3E%3Cpath d='M7.5 4.501a2.992 2.992 0 0 0-2.984 3c0 1.657 1.336 3 2.984 3a2.992 2.992 0 0 0 2.984-3c0-.796-.315-1.559-.874-2.121A2.975 2.975 0 0 0 7.5 4.5Zm0 4.5a1.496 1.496 0 0 1-1.492-1.5c0-.829.668-1.5 1.492-1.5s1.492.671 1.492 1.5c0 .828-.668 1.5-1.492 1.5Z' /%3E%3Cpath d='M7.5 18a3.925 3.925 0 0 1-3.216-1.65C1.442 12.408 0 9.444 0 7.54 0 3.377 3.358 0 7.5 0 11.642 0 15 3.376 15 7.54c0 1.904-1.442 4.868-4.284 8.81A3.925 3.925 0 0 1 7.5 18Zm0-16.363c-3.242.004-5.87 2.645-5.873 5.905 0 1.507 1.412 4.295 3.975 7.849a2.342 2.342 0 0 0 1.898.973c.751 0 1.457-.362 1.898-.973 2.563-3.554 3.975-6.342 3.975-7.849-.004-3.26-2.63-5.901-5.873-5.905Z' /%3E%3C/g%3E%3C/svg%3E")`;
    }

    async styleStatusBar(colorHex: string) {
        await this.platformReady;
        if (this.statusBar) {
            if (this.isIos) {
                this.onDashboardChange();
            } else {
                this.statusBar.backgroundColorByHexString(colorHex);
                this.statusBar.styleLightContent();
            }
        }
    }

    onDashboardChange() {
        if (this.hasThemeBackground) {
            this.statusBar.styleLightContent();
        } else {
            this.statusBar.styleDefault();
        }
    }

    async setStatusBarForModalRt(onOpen: boolean = true) {
        await this.platformReady;
        if (this.statusBar && this.isIos) {
            if (onOpen) {
                this.statusBar.styleDefault();
            } else {
                this.onDashboardChange();
            }
        }
    }
    
    setCssProperty(property: any, value: any) {
        this.document.documentElement.style.setProperty(property, value);
    }

    checkReturnHex(color: any) {
        return this.isHex(color) ? color : Array.isArray(color) ? this.rgbToHex({ r: color[0], g: color[1], b: color[2] }) : '#0F69FF';
    }

    doConvert(color: any) {
        let input = this.isHex(color) ? color : Array.isArray(color) ? this.rgbToHex({ r: color[0], g: color[1], b: color[2] }) : '#0F69FF';
        return this.rgbToHex(this.colorConversion(input));
    }

    colorConversion(color: any) {
        const ratio = 0.4;
        let rgb = this.hexToRgb(color);
        let convertTo = {
            r: Math.round(rgb.r * ratio),
            g: Math.round(rgb.g * ratio),
            b: Math.round(rgb.b * ratio)
        }
        return convertTo;
    }

    isHex(hex: any) {
        const colorValidationRegex = RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
        return colorValidationRegex.test(hex);
    }

    converseThreeToSixHexDigits(digits: any) {
        let chars = [digits.charAt(1), digits.charAt(2), digits.charAt(3)];
        return '#'.concat(chars[0], chars[0], chars[1], chars[1], chars[2], chars[2]);
    }

    hexToRgb(hex: any) {
        let input = hex;
        if (input.length === 4) {
            input = this.converseThreeToSixHexDigits(input);
        }
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(input);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    rgbToHex(rgb: any) {
        return `#${this.componentToHex(rgb.r)}${this.componentToHex(rgb.g)}${this.componentToHex(rgb.b)}`;
    }

    componentToHex(color: any) {
        let hex = color.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
}