import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, lastValueFrom, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { checkEmailStaffRequest, loginRequest } from '../data/models/auth.model';
import { environment } from '../../environments/environment';
import { ThemeService } from './theme.service';
import { NavController, Platform } from '@ionic/angular';
import { FielddLocaleService } from './locale.service';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import * as moment from 'moment';
import { AppVersion } from '@ionic-native/app-version/ngx';
// import { LocalDbService } from './local-db.service';
import { isEqual, sortBy } from 'lodash-es';
import { PaymentGateways, SignUpRequest } from '@data/models/shared.model';
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    version: string = '';
    private ignoreVersionCheckHeader = {
        headers: new HttpHeaders().set('ignore-version-check', 'true')
    };

    forgotPasswordUrl: string = environment.apiURL + "partner/forgotpassword";
    loginUrl: string = environment.apiURL + "partner/login";
    resetPasswordUrl: string = environment.apiURL + "partner/password/change";
    signUpUrl: string = environment.apiURL + "company/register"
    getBusinessCategoriesUrl: string = environment.apiURL + "user/business-categories/";
    registerFirebaseTokenUrl: string = "";
    partnerProfileUrl: string = environment.apiURL + 'partner/profile/me';
    serviceDetailsUrl: string = environment.apiURL + 'user/service';
    getServiceAppSettingsUrl: string = environment.apiURL + 'partners/app-settings';
    refreshCompanyAndPartnerDataUrl: string = environment.apiURL + "partners/";

    signupVerificationUrl: string = environment.apiURL + "company/signUpVerification";
    contractorSignupVerificationUrl: string = environment.apiURL + "contractor/contractorSignUpVerification";
    deviceRegistrationUrl: string = environment.apiURL + "partner/device";
    dashboardDataUrl: string = environment.apiURL + "partner/dashboardData";
    logoutUrl: string = environment.apiURL + "logout";
    addQCErrorLogUrl: string = environment.apiURL + "partner/addQCErrorLogUrl";
    public userLoggedIn$ = new BehaviorSubject<boolean>(false);
    
    public appSettingsChanged$ = new BehaviorSubject<boolean>(false);
    public orderChanged$ = new BehaviorSubject<boolean>(false);

    messages: Subject<Array<any>> = new Subject<Array<any>>();

    private companyChanged$ = new BehaviorSubject<boolean>(false);
    public company$: Observable<any> = this.companyChanged$.pipe(
        filter((flag) => !!flag),
        map(() => this.isUserLoggedIn() ? this.getCurrentCompany() : null),
        filter((company) => !!company?._id),
        distinctUntilChanged(isEqual),
        shareReplay(1),
    );

    private companyServicesChanged$ = new BehaviorSubject<boolean>(false);
    public companyServices$: Observable<any> = this.companyServicesChanged$.pipe(
        filter((flag) => !!flag),
        map(() => this.isUserLoggedIn() ? this.companyServices : null),
        filter((companyServices) => !!companyServices?._id),
        distinctUntilChanged(isEqual),
        shareReplay(1),
    );

    private staffChanged$ = new BehaviorSubject<boolean>(false);
    public staff$: Observable<any> = this.staffChanged$.pipe(
        filter((flag) => !!flag),
        map(() => this.isUserLoggedIn() ? (this.getCurrentUserData() || null) : null),
        distinctUntilChanged(isEqual),
        shareReplay(1),
    );

    constructor(
        private http: HttpClient,
        private firebaseX: FirebaseX,
        private platform: Platform,
        private localStorageService: LocalStorageService,
        private themeService: ThemeService,
        private appVersion: AppVersion,
        private navController: NavController,
        private fielddLocaleService: FielddLocaleService,
        // private localDbService: LocalDbService
    ) { 
        this.platform.ready().then(async () => {
            if (this.platform.is("cordova")) {
                this.version = await this.appVersion.getVersionNumber();
            }
        });
    }

    get isWhiteLabelApp(): boolean {
        return !!environment.companyID?.length;
    }

    setCurrentUserData(staff: any) {
        let currentUser = this.localStorageService.retrieve('currentUser');
        if (currentUser) {
            currentUser.user = staff;
            this.localStorageService.store('currentUser', currentUser);
            this.staffChanged$.next(true);
        }
    }

    getAppVersion() {
        return this.version;
    }

    getAuthToken() {
        let authToken = null;
        let currentUser = this.localStorageService.retrieve("currentUser");
        if (currentUser) {
            authToken = currentUser.auth.authToken;
        }
        return authToken;
    }

    getCurrentCompany() {
        let company = null;
        let currentUser = this.localStorageService.retrieve("currentUser");
        if (currentUser) {
            company = currentUser.company;
        }
        return company;
    }

    getAppSettings() {
        return this.localStorageService.retrieve('appSettings');
    }

    getCurrentJobDetails() {
        const jobDetails = this.localStorageService.retrieve("activejob")
        if(jobDetails?._id) {
            return jobDetails
        }
        return null
    }

    getCurrentUserData() {
        let staff = null;
        let currentUser = this.localStorageService.retrieve("currentUser");
        if (currentUser) {
            staff = currentUser.user;
        }
        return staff;
    }

    getCurrentCurrency() {
        let company = this.getCurrentCompany();
        if(company) {
            return this.fielddLocaleService.getCurrentCurrencySymbol(company.activeCountry);
        }
    }

    get companyServices(): any {
        return this.localStorageService.retrieve('companyServices');
    }

    set companyServices(service: any) {
        this.localStorageService.store('companyServices', service);
        this.companyServicesChanged$.next(true);
    }

    getServiceDetails(): Promise<any> {
        const dataToSend = { companyId: this.getCurrentCompany()._id };
        return lastValueFrom(this.http.post<any>(this.serviceDetailsUrl, dataToSend)).then((services) => {
            if (services?.length) {
                this.companyServices = services[0];
            }
            return this.companyServices;
        })
        .catch(() => this.companyServices);
    }

    shouldShowFirstTimeLoginMessage() {
        let userData = this.getCurrentUserData();
        return this.localStorageService.retrieve('isFirstTimeLogin') == userData.emailID;
    }

    checkFirstTimeLogin(checkEmailStaffRequest: checkEmailStaffRequest): Promise<any> {
        return lastValueFrom(
            this.http.post<any>(this.loginUrl, checkEmailStaffRequest).pipe(
                catchError(() => of(null)),
            )
        );
    }

    login(loginRequest: loginRequest): Promise<any> {
        return lastValueFrom(
            this.http.post<any>(this.loginUrl, loginRequest).pipe(
                tap((loginResponse) => {
                    this.localStorageService.store('isLoggedIn', true);
                    this.localStorageService.store('currentUser', loginResponse);
                    if (loginResponse.appSettings) {
                        this.updateStyles(loginResponse.appSettings);
                    }
                    // await this.createIndexDb()
                    this.userLoggedIn$.next(true);
                    this.companyChanged$.next(true);
                    this.staffChanged$.next(true);
                }),
            )
        );
    }

    clearFirstTimeLogin() {
        this.localStorageService.clear('isFirstTimeLogin');
    }

    setFirstTimeLogin(emailId) {
        this.localStorageService.store("isFirstTimeLogin", emailId);
    }

    async getServiceAppSettings() {
        try {
            let companyId = environment.companyID?.length ? environment.companyID : this.getCurrentUserData().companyID;
            let res = await lastValueFrom(this.http.get<any>(`${this.getServiceAppSettingsUrl}/${companyId}`, this.ignoreVersionCheckHeader));
            if (res?.success) {
                this.updateStyles(res.data);
            }
        } catch (e) {
            //
        }
    }

    refreshPartnerProfile() {
        return lastValueFrom(
            this.http.get<any>(this.partnerProfileUrl).pipe(
                tap((response) => {
                    if (response?.success) {
                        this.setCurrentUserData(response.partner);
                    }
                }),
                catchError(this.handleError),
            )
        );
    }

    refreshCompanyAndPartner(isOnInit: boolean = false) {
        this.getServiceAppSettings();

        return lastValueFrom(
            this.http.get<any>(this.refreshCompanyAndPartnerDataUrl + this.getCurrentUserData()._id + '/status', this.ignoreVersionCheckHeader).pipe(
                tap(response => {
                    if (response) {
                        if (response.partner) {
                            this.setCurrentUserData(response.partner);
                        }
                        if (response.company) {
                            this.updateCompanyData(response.company);
                        }

                        if (isOnInit) {
                            this.userLoggedIn$.next(true);
                        }
                    }
                }),
                catchError(this.handleError)
            )
        );
    }

    updateCompanyData(company) {
        let userInfo = this.localStorageService.retrieve('currentUser');
        userInfo.company = company;
        this.localStorageService.store('currentUser', userInfo);
        this.companyChanged$.next(true);
    }

    updateStyles(settings: any) {
        this.localStorageService.store('appSettings', settings);
        this.setThemeStyle(settings);
        this.appSettingsChanged$.next(true);
    }

    setThemeStyle(settings: any) {
        this.themeService.setTheme(settings);
    }

    logout() {
        this.http.get<any>(this.logoutUrl).subscribe(res => {
            if(res && res.success) {
                return this.resetStyleAndLogout()
            }
            return this.resetStyleAndLogout()
        }, err => {
            return this.resetStyleAndLogout()
        })
    }

    resetStyleAndLogout() {
        let settings = this.getAppSettings();
        // this.localDbService.deleteAllStores(this.getLocalDbStores())
        this.localStorageService.clear();
        this.navController.navigateRoot("/auth", { animationDirection: 'forward' });
        if (this.isWhiteLabelApp) {
            this.updateStyles(settings);
        } else {
            this.themeService.setTheme(null);
        }
    }

    getLocalDbStores() {
        return this.localStorageService.retrieve('localDbStores')
    }

    getBusinessCategories(searchInput: string): Observable<any> {
        return this.http.get<any>(this.getBusinessCategoriesUrl + searchInput).pipe(
            map((response) => sortBy(response?.categories || [], ['displayName'])),
            catchError(() => of([])),
            map((categories) => categories?.length ? categories : [{ displayName: searchInput, _id: null }]),
        );
    }

    signup(data: any) {
        return lastValueFrom(this.http.post<any>(this.signUpUrl, data));
    }

    verifySignupData(data: SignUpRequest) {
        return lastValueFrom(this.http.post<any>(this.signupVerificationUrl, data));
    }

    verifyContractorSignupData(data: any) {
        return lastValueFrom(this.http.post<any>(this.contractorSignupVerificationUrl, data));
    }

    forgotPasswordRequest(emailID: string): Promise<any> {
        return lastValueFrom(
            this.http.post<any>(this.forgotPasswordUrl, { emailID })
        );
    }

    resetPasswordRequest(data: any): Promise<any> {
        return lastValueFrom(
            this.http.post<any>(this.resetPasswordUrl, data)
        );
    }

    isUserLoggedIn(): boolean {
        return this.localStorageService.retrieve('isLoggedIn') as boolean;
    }

    private handleError(err: any) {
        return throwError(err);
    }

    storeFireBaseToken(tokenDetails) {
        this.localStorageService.store("deviceToken", tokenDetails);
    }

    registerFireBaseTokenForPartner() {
        let deviceToken = this.localStorageService.retrieve("deviceToken");
        if (this.isUserLoggedIn() && deviceToken?.device?.deviceId) {
            this.http.post(this.deviceRegistrationUrl, deviceToken).subscribe((success) => {

            })
        }
    }

    getOrderMessages() {
        return this.localStorageService.retrieve("newMessages");
    }

    addUserOrderMessages(orderId) {
        let messages = this.getOrderMessages() || [];
        if (messages.indexOf(orderId) === -1) {
            messages.push(orderId);
        }
        this.messages.next(messages);
        this.localStorageService.store("newMessages", messages);
    }

    removeUserOrderMessages(orderId) {
        let messages = this.getOrderMessages() || [];
        if (messages !== 0 && messages.indexOf(orderId) !== -1) {
            messages.splice(messages.indexOf(orderId), 1);
            this.localStorageService.store('newMessages', messages);
        }
    }

    getFirebaseToken() {
        this.firebaseX.getToken().then(token => {
            
            console.log("firebaseToken", token);
            
            let deviceType = this.platform.is("ios") ? "iOS" : "Android";
            let deviceObj = {
                "device": {
                    deviceId: token,
                    deviceType: deviceType
                }
            }
            this.storeFireBaseToken(deviceObj);
            this.registerFireBaseTokenForPartner();
        }).catch(err => {
            console.log('firebase error', err.message)
        });
    }

    addQCErrorLog(orderId, photoType, errorType) {
        let payload = {
            orderId: orderId,
            photoType: photoType,
            errorType: errorType
        }
        return this.http.post<any>(this.addQCErrorLogUrl, payload);
    }

    async getDashboardData() {
        let data = {
            todayStart: moment().startOf('d').utc().valueOf(),
            todayEnd: moment().endOf('d').utc().valueOf(),
        };
        
        return await lastValueFrom(this.http.post<any>(this.dashboardDataUrl, data));
    }

    // async createIndexDb() {
    //     // create local db
    //     const res = await this.localDbService.createDb('fieldd-db')
    //     if(res) {
    //         const db = this.localDbService.getDb()
    //         if(db?.name) {
    //             this.setLocalDbStores()
    //         }
    //     }
    // }

    // setLocalDbStores() {
    //     this.localStorageService.store('localDbStores', JSON.parse(JSON.stringify(environment.localDbStores)))
    // }

    getPaymentGateway(company: any): PaymentGateways | string {
        if (company?.thirdPartyPayments) {
            if (company?.thirdPartySquare && company?.squareApplicationId != '') {
                return PaymentGateways.SQUARE;
            } else if (company?.thirdPartyStripe && company?.stripePublicKey != '') {
                return PaymentGateways.STRIPE;
            } else if (company?.thirdPartyPayFabric && company?.payFabricPublicKey != '') {
                return PaymentGateways.PAY_FABRIC;
            } else if (company?.thirdPartyPin && company?.pinPublicKey != '') {
                return PaymentGateways.PIN;
            }
        }
        // QualPay: Uncomment these lines
        else if (company?.useFielddPay) {
            if (company?.recipientData?.fielddPayAccount?.toLowerCase() === PaymentGateways.QUALPAY) {
                return PaymentGateways.QUAL_FIELDD;
            } else if (company?.recipientData?.fielddPayAccount === PaymentGateways.FINIX_FIELDD) {
                return PaymentGateways.FINIX_FIELDD;
            } else if (company?.recipientData?.fielddPayAccount === PaymentGateways.ADYEN_FIELDD) {
                return PaymentGateways.ADYEN_FIELDD;
            }
            return PaymentGateways.PIN_FIELDD;
        }
        return 'none';
    }

    getGatewayConfigured(company: any): boolean {
        return !!(
            (
                company?.acceptCard && (company?.useFielddPay || company?.thirdPartyPayments)
            ) &&
            (
                (Object.values(PaymentGateways) as any).includes(this.getPaymentGateway(company))
            )
        );
    }
}
