
import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../services/auth.service';

@Pipe({ name: 'distanceUnit' })
export class DistanceUnitPipe implements PipeTransform {
    distanceUnit = 'km'
    constructor(
        private authenticationService: AuthenticationService
    ) {

    }
    transform(value: any): string {
        let company = this.authenticationService.getCurrentCompany();
        if (company.countryCode == "US") {
            this.distanceUnit = 'mile'
        }
        else {
            this.distanceUnit = "km";
        }
        return this.distanceUnit;
    }
}
