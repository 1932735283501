import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class StaffService {

    private listStaffUrl: string = environment.apiURL + "partner/staff-list";
    private staffDetailsUrl: string = environment.apiURL + "admin/getPartnerDetails";
    private getStaffJobsUrl: string = environment.apiURL + "admin/partner/orders";
    private getPendingJobsUrl: string = environment.apiURL + "admin/partner/orders/pending";
    private deleteStaffUrl: string = environment.apiURL + "admin/partner";
    private updateStaffUrl: string = environment.apiURL + "partner/staff";

    constructor(
        private http: HttpClient
    ) { }

    listStaff(data: any) {
        return this.http.post<any>(this.listStaffUrl, data);
    }

    getStaffDetails(data: any): Promise<any> {
        return this.http.post<any>(this.staffDetailsUrl, data).toPromise();
    }

    updateStaffDetails(id: string, data: any): Promise<any> {
        return this.http.put<any>(`${this.updateStaffUrl}/${id}`, data).toPromise();
    }
    
    getStaffJobs(data: any): Promise<any> {
        return this.http.post<any>(`${this.getStaffJobsUrl}`, data).toPromise();
    }

    getPendingJobs(staffId: string): Promise<any> {
        return this.http.get<any>(`${this.getPendingJobsUrl}/${staffId}`).toPromise();
    }

    deleteStaff(staffId: string): Promise<any> {
        return this.http.delete<any>(`${this.deleteStaffUrl}/${staffId}`).toPromise();
    }
}