<ion-header class="header-v2">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="closeModal()">
                <svg-shell src="fi-rr-angle-left.svg" class="primary-i"></svg-shell>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen="true">
    <ion-refresher class="black-refresher" style="z-index: 1;" slot="fixed" (ionRefresh)="refreshJobs($event)">
        <ion-refresher-content refreshingSpinner="crescent"></ion-refresher-content>
    </ion-refresher>

    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title class="past-title-v2 text-left">{{ title }}</ion-title>
        </ion-toolbar>
    </ion-header>

    <!-- No job history - empty state -->
    <div class="empty-state vertical-center" *ngIf="!jobs?.length && jobApiCalled">
        <div class="empty-state-i">
            <svg viewBox="0 0 167 129" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                    <path d="M160 24.997a7 7 0 1 1 0 14h-40a7 7 0 1 1 0 14h22a7 7 0 1 1 0 14h-10.174c-4.875 0-8.826 3.134-8.826 7 0 2.577 2 4.91 6 7a7 7 0 1 1 0 14H46a7 7 0 1 1 0-14H7a7 7 0 1 1 0-14h40a7 7 0 1 0 0-14H22a7 7 0 1 1 0-14h40a7 7 0 1 1 0-14h98zm0 28a7 7 0 1 1 0 14 7 7 0 0 1 0-14z"
                        fill="var(--ion-color-primary)" fill-opacity=".1" />
                    <path d="M25.771 52.997h-.948c-6.063 0-10.979-4.908-10.979-10.963 0-6.055 4.916-10.963 10.98-10.963h.116c.976-8.491 7.925-15.074 16.351-15.074 8.882 0 16.121 7.313 16.456 16.466 5.37.346 10.096 5.573 10.096 11.024 0 4.475-3.314 9.51-7.24 9.51H33.301"
                        fill="#fff" stroke="var(--ion-color-primary)" stroke-linecap="round" stroke-opacity=".4"
                        stroke-width="2.5" />
                    <path d="M83 9.247c10.701 0 20.388 4.337 27.4 11.35 7.012 7.012 11.35 16.7 11.35 27.4 0 13.692-11.789 34.858-35.061 63.552a4.75 4.75 0 0 1-7.378 0C56.039 82.855 44.25 61.689 44.25 47.997c0-10.701 4.337-20.388 11.35-27.4 7.012-7.013 16.7-11.35 27.4-11.35z"
                        fill="#fff" stroke="var(--ion-color-primary)" stroke-opacity=".6" stroke-width="2.5" />
                    <path d="M65.701 107c-2.484.445-4.779.982-6.835 1.598m-4.155 1.488c-4.22 1.817-6.71 4.057-6.71 6.479 0 6.075 15.67 11 35 11s35-4.925 35-11c0-4.03-6.897-7.554-17.183-9.47"
                        stroke="var(--ion-color-primary)" stroke-linecap="round" stroke-opacity=".4" stroke-width="2.5" />
                    <path d="M83 29.247a18.69 18.69 0 0 1 13.258 5.492c3.393 3.393 5.492 8.08 5.492 13.258s-2.099 9.865-5.492 13.258A18.691 18.691 0 0 1 83 66.746a18.66 18.66 0 0 1-10.184-3.004 18.858 18.858 0 0 1-4.515-4.104 18.67 18.67 0 0 1-4.05-11.642 18.69 18.69 0 0 1 5.49-13.258A18.691 18.691 0 0 1 83 29.246z"
                        fill="var(--ion-color-primary)" fill-opacity=".1" stroke="var(--ion-color-primary)" stroke-opacity=".6"
                        stroke-width="2.5" />
                    <path d="m120.78 12.21-1.776-.71 1.776-.71a9 9 0 0 0 5.013-5.014l.71-1.776.712 1.776a9 9 0 0 0 5.013 5.013l1.776.71-1.776.711a9 9 0 0 0-5.013 5.014L126.504 19l-.71-1.776a9 9 0 0 0-5.014-5.014z"
                        stroke="var(--ion-color-primary)" stroke-linejoin="round" stroke-opacity=".4" stroke-width="2.5" />
                    <path d="M105.69 94.997H105a8 8 0 1 1 .085-16c.711-6.196 5.774-11 11.915-11 6.472 0 11.747 5.337 11.99 12.016 3.914.253 7.357 4.068 7.357 8.045 0 3.265-2.415 6.94-5.275 6.94h-19.894"
                        fill="#fff" stroke="var(--ion-color-primary)" stroke-linecap="round" stroke-opacity=".4"
                        stroke-width="2.5" />
                </g>
            </svg>
        </div>
        <div class="empty-state-t">
            No jobs found
        </div>
        <div class="empty-state-desc">
            All jobs for the {{ userType }} will be displayed here.
        </div>
    </div>
    <div style="padding-left: 15px; padding-bottom: 5px;">
        <schedule-job-item *ngFor="let job of jobs; let i = index;" [jobIndex]="i" [job]="job" [showPartnerName]="true" (jobClicked)="startJob($event)"></schedule-job-item>
    </div>

    <ion-infinite-scroll *ngIf="hasMore" #infiniteScroll (ionInfinite)="getJobs($event)" class="dispatch-infinite-scroll">
        <ion-infinite-scroll-content loadingSpinner="crescent"></ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ion-content>