import { Component, Input } from '@angular/core';


@Component({
    selector: 'upload-status',
    templateUrl: 'upload-status.component.html',
    styleUrls: ['upload-status.component.scss']
})
export class UploadStatusComponent {
    @Input() pendingUploads: any;
    constructor() { }

}