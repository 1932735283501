import { Pipe, PipeTransform } from '@angular/core';

import { DateService } from '@services/date-service'

@Pipe({ name: 'formatDate' })
export class FormatDate implements PipeTransform {
    constructor(private dateService:DateService){

    }
    transform(value: any, format: string, timezone?: string): string {
        if (value) {
            if (timezone) {
                return this.dateService.autoFormat(value,format,timezone);
            }
            value = this.dateService.autoFormat(value,format);
        }
        return value;
    }
}

