import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Storage } from '@ionic/storage';

import * as moment from 'moment-timezone'
import * as intlTelInputUtils from 'intl-tel-input/build/js/utils';
declare var intlTelInputUtils;

import { AddressObject, ParsedGoogleAddress } from '@data/models/shared.model';

import { FormatAddressPipe } from '@pipes/address.pipe';
import { FielddCurrencyPipe } from '../pipes/fielddCurrency.pipe';
import { DateService } from './date-service';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommonHelperService {
    baseURI: string = environment.apiURL;
    apiKey: string = environment.apiKey;

    constructor(
        private nativeStorage: Storage,
        private dateService: DateService,
        private domSanitizer: DomSanitizer,
        private formatAddressPipe: FormatAddressPipe,
        private fielddCurrencyPipe: FielddCurrencyPipe,
    ) { }

    convertTimeSlots(slot) {
        const momentTime = moment().set({ hour: slot._id.hour, minute: slot._id.minutes });
        return this.dateService.convertTimeSlots(momentTime)
    }

    convertTimeSlotFormattedText(slot) {
        const timeObj = this.convertTimeSlots(slot);
        return this.dateService.convertTimeSlotFormattedText(timeObj);
    }

    getFormattedSlot(hour, min) {
        return this.dateService.autoFormat(moment().set({ hour: hour, minute: min }), 'hh:mm:a')
    }

    tabSlider(tabAnchorClass, selectionClass) {
        let parentClass = "." + selectionClass + "s";
        selectionClass = "#" + selectionClass;
        let selectedTabBorder = document.querySelector(selectionClass + '') as any;

        let active = document.querySelector(parentClass + ' .active') as any;

        let activeWidth = active.offsetWidth;

        selectedTabBorder.style['width'] = activeWidth + 'px';
        selectedTabBorder.style['left'] = active.offsetLeft + 'px';
        document.querySelectorAll('a.' + tabAnchorClass).forEach(x => {
            x.addEventListener("click", (data) => {
                let element = data.target as any;
                setTimeout(() => {
                    let active = document.querySelector(parentClass + ' .active') as any;
                    if (active) {
                        selectedTabBorder.style['width'] = element.offsetWidth + 'px';
                        selectedTabBorder.style['left'] = element.offsetLeft + 'px';

                    }
                }, 100);

            })
        })
    }


    getFullName(variant, mainService) {
        let v;
        if (variant.pid != 0) {
            v = this.getParent(variant, mainService);
        }
        else {
            v = variant;
        }
        while (v && v.pid != 0) {
            v = (variant = this.getParent(v, mainService)) ? variant : v;
        }
        return v.name + " • " + variant.name;
    }

    getServiceHistory(variant, mainService) {
        try {
            let history = variant.name
            let v;
            if (variant.pid != 0) {
                v = this.getParent(variant, mainService);
                if (!v)
                    return history
                history = history + " • " + v.name
            }
            else {
                return history
            }
            let i = 0;
            while (v && v.pid != 0 && i < 10) {
                i++;
                v = this.getParent(v, mainService)
                if (v && v.name)
                    history = history + " • " + v.name
            }

            return history
        }
        catch (err) {
            return variant.name
        }
    }

    getParent(variant, mainService) {
        for (let key in mainService.variants) {
            if (mainService.variants[key].id === variant.pid) {
                return mainService.variants[key];
            }
        }
    };

    formatTimes(timeSlots, timeBetweenBookings, scheduledOn, timezone) {
        let formattedTimes;
        let previousTimeSlot;
        let currentTimeSlot;

        let isConsecutiveTime = false;

        for (let i = 0; i < timeSlots.length; i++) {
            previousTimeSlot = currentTimeSlot;
            currentTimeSlot = timeSlots[i];

            const currentDateTime = moment(scheduledOn).tz(timezone || moment.tz.guess()).set({ hour: currentTimeSlot.time, minute: currentTimeSlot.min });
            const currentFormattedTime = this.dateService.autoFormat((currentDateTime), 'h:mm a');

            if (!formattedTimes && !previousTimeSlot) {
                formattedTimes = currentFormattedTime;
                continue;
            }

            const hourDifference = currentTimeSlot.time - previousTimeSlot.time;
            const minDifference = currentTimeSlot.min - previousTimeSlot.min;

            if (timeBetweenBookings.time === hourDifference && timeBetweenBookings.min === minDifference) {
                const isLastSlot = !!(i === (timeSlots.length - 1));

                if (isLastSlot) {
                    const currentFormattedEndTime = this.dateService.autoFormat(moment(currentDateTime).add(timeBetweenBookings.time, 'h').add(timeBetweenBookings.min, 'm'), 'h:mm a');

                    formattedTimes = formattedTimes + ' - ' + currentFormattedEndTime;
                } else {
                    isConsecutiveTime = true;
                    continue;
                }
            } else if (isConsecutiveTime) {
                const previousDateTime = moment(scheduledOn).tz(timezone).set({ hour: previousTimeSlot.time, minute: previousTimeSlot.min });
                const previousFormattedEndTime = this.dateService.autoFormat(moment(previousDateTime).add(timeBetweenBookings.time, 'h').add(timeBetweenBookings.min, 'm'), 'h:mm a');

                formattedTimes = formattedTimes + ' - ' + previousFormattedEndTime + ', ' + currentFormattedTime;
                isConsecutiveTime = false;
            } else {
                formattedTimes = formattedTimes + ', ' + currentFormattedTime;
            }
        }

        return formattedTimes;
    };

    stack() {
        function Stack() {
            this._size = 0;
            this._storage = {};
        }

        Stack.prototype.push = function (data) {
            let size = ++this._size;
            this._storage[size] = data;
        };

        Stack.prototype.pop = function () {
            let size = this._size,
                deletedData;

            if (size) {
                deletedData = this._storage[size];

                delete this._storage[size];
                this._size--;

                return deletedData;
            }
        };

        return new Stack();
    }

    formatCurrency(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;


            // $locale.NUMBER_FORMATS.CURRENCY_SYM + 
            return this.fielddCurrencyPipe.getCurrencySymbol() + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - parseInt(i)).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    };

    formatPhoneNumber(phoneNumber: string, countryCode: string = null): string {
        return intlTelInputUtils.formatNumber(phoneNumber, countryCode, intlTelInputUtils.numberFormat.NATIONAL);
	}

    checkInLunchHours(companyHours: any, startTime: any, endTime: any) {
        if (companyHours?.haveLunchBreak && companyHours.lunchStartTime && companyHours.lunchDuration) {
            const lunchDuration = (companyHours.lunchDuration.time * 60) + companyHours.lunchDuration.min;
            if (lunchDuration && startTime.clone().hours(companyHours.lunchStartTime.time).minutes(companyHours.lunchStartTime.min).isBetween(startTime, endTime, 'minutes', '[)')) {
                return lunchDuration;
            }
        }
        return false;
    }

    formatTotalTime(job, company) {
        const timezone = job.timezone ? job.timezone : moment.tz.guess()
        let date = moment(job.scheduledOn)
        const startTime = moment(date).set({
            'hour': date.hour(),
            'minute': date.minutes()
        })
        let totalHours, endTime;
        let timeString = this.dateService.autoFormat(startTime, 'h:mma MMM D YYYY', timezone);
        let displayString = timeString;
        const dateString = this.dateService.autoFormat(startTime, 'MMM D YYYY', timezone);

        if (job.mergeSlotCount) {
            totalHours = (company.timeBetweenBookings.time + (company.timeBetweenBookings.min / 60)) * (job.mergeSlotCount)
        };

        if (job.multiDays?.enabled && job.multiDays?.endScheduledOn) {
            endTime = moment(job.multiDays.endScheduledOn)
            timeString += " - " + this.dateService.autoFormat(endTime, 'h:mma MMM D YYYY', timezone)
            displayString = timeString;
        } else if (totalHours) {
            endTime = moment(startTime).add(totalHours, 'hours')
            const inLunchHours = this.checkInLunchHours(company.companyHours, startTime, endTime);
            if (inLunchHours) {
                endTime.add(inLunchHours, 'm');
            }
            timeString = this.dateService.autoFormat(startTime, 'h:mma', timezone) + " - " + this.dateService.autoFormat(endTime, 'h:mma', timezone) + " (" + totalHours + "hr)";
            displayString = timeString + " " + dateString;
        }

        return {
            displayString,
            timeString,
            dateString,
            totalHours
        }
    }

    formatSlotTimeLocal(job, type, format = 'h:mma') {
        let timezone = job.timezone ? job.timezone : moment.tz.guess()

        let date;
        switch (type) {
            case 'createdAt':
                date = moment(job.createdAt)
                break
            case 'createdOn':
                date = moment(job.createdOn)
                break
            case 'scheduledOn':
                date = moment(job.scheduledOn)
                break
            case 'accepted':
                date = moment(job.timeAccepted)
                break
            case 'enRoute':
                date = moment(job.timeEnroute)
                break
            case 'arrived':
                date = moment(job.timeArrived)
                break
            case 'timeRefreshing':
                date = moment(job.timeRefreshing)
                break
            case 'timePhotosTaken':
                date = moment(job.timePhotosTaken)
                break
            case 'done':
                date = moment(job.timeDone)
                break
        }
        let startTime = moment(date).set({
            'hour': date.hour(),
            'minute': date.minutes()
        })

        return this.dateService.autoFormat(startTime, format, timezone)
    }

    getDate(job) {
        let timezone = job.timezone ? job.timezone : moment.tz.guess()
        let date = moment(job.scheduledOn)

        let jobDate = moment(date).set({
            'hours': date.hour(),
            'minutes': date.minutes()
        })

        return this.dateService.autoFormat(jobDate, ('MMM DD, YYYY'), timezone)
    }

    calculateCompanyDayHours(companyStartTime, companyEndTime, companyLunchStart, companyLunchDuration) {

        let startTime = moment().set({
            'hours': companyStartTime.time,
            'minute': companyStartTime.min,
            'seconds': 0,
            'milliseconds': 0
        })

        let endTime = moment().set({
            'hours': companyEndTime.time,
            'minute': companyEndTime.min,
            'seconds': 0,
            'milliseconds': 0
        })

        let lunchStartTime = moment().set({
            'hours': companyLunchStart.time,
            'minute': companyLunchStart.min,
            'seconds': 0,
            'milliseconds': 0
        })

        let lunchEndTime = lunchStartTime.clone().add(companyLunchDuration.time, 'h').add(companyLunchDuration.min, 'm')

        let lunchDiff = lunchEndTime.diff(lunchStartTime, 'm')
        if (lunchDiff === 0) {
            return +(endTime.diff(startTime, 'm') / 60).toFixed(2)
        }

        let hoursBeforeLunch = lunchStartTime.diff(startTime, 'm')
        let hoursAfterLunch = endTime.diff(lunchEndTime, 'm')
        return +(Math.max(hoursBeforeLunch, hoursAfterLunch) / 60).toFixed(2)
    }

    calculateMaxSlots(slotSize, companyStartTime, companyEndTime, companyLunchStart, companyLunchDuration) {
        let companyMins = this.calculateCompanyDayHours(companyStartTime, companyEndTime, companyLunchStart, companyLunchDuration) * 60
        return Math.floor(companyMins / slotSize);
    }

    calculateTravelCharge(job) {
        let totalTravelCharge = 0;
        try {
            if (job.overrideTravelCharges && !isNaN(job.overrideTravelCharges.amount)) {
                return totalTravelCharge = job.overrideTravelCharges.amount;
            }
            if (job.routeAddresses?.length && job.pickupDropOffRoute) {
                let distanceCharge = job.pickupDropOffRoute.totalDistanceCharge || 0;
                let durationCharge = job.pickupDropOffRoute.totalDurationCharge || 0;
                let minimumTravelCost = job.pickupDropOffRoute.minimumTravelCost || 0;

                totalTravelCharge = distanceCharge + durationCharge;
                if (totalTravelCharge < minimumTravelCost) {
                    totalTravelCharge = minimumTravelCost;
                }
            }
        } catch (e) {
            //
        } finally {
            return totalTravelCharge;
        }
    }

    calculateZoneTravelCharge(job) {
        let zoneTravelCharge = 0;
        try {
            if (job.zoneTravelCharges && job.zoneTravelCharges.appliedCharges) {
                zoneTravelCharge += job.zoneTravelCharges.appliedCharges;
            }
        } catch (e) {
            //
        } finally {
            return zoneTravelCharge;
        }
    }

    generateId() {
        let timestamp = (new Date().getTime() / 1000 | 0).toString(16)
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
            return (Math.random() * 16 | 0).toString(16)
        }).toLowerCase()
    }

    async getFile(src: string): Promise<{ data: string; extension: string; }> {
        try {
            // const yesterday = Date.now() - (24 * 60 * 60 * 1000);
            // const file = !data?.file || data?.lastUsed < yesterday ? (await this.fetchFile(src)) : data.file;

            const data = await this.nativeStorage.get(src);
            const file = !data?.file ? (await this.fetchFile(src)) : data.file;

            return {
                data: file,
                extension: file.split(';')[0].split('/')[1],
            };
        } catch {
            return null;
        }
    }

    async fetchFile(src: string, saveLocally: boolean = true): Promise<unknown> {
        const response = await fetch(src);
        if (!(response.status >= 200 && response.status <= 299)) {
            throw new Error('Not found');
        }
        const blob = await response.blob();

        return await new Promise((res) => {
            const reader = new FileReader();
            reader.onload = () => {
                const file = reader.result;
                if (saveLocally) {
                    this.nativeStorage.set(src, { lastUsed: Date.now(), file: file });
                }
                res(file);
            };
            reader.readAsDataURL(blob);
        });
    }

    base64ToBlob(base64File: string) {
        const type = base64File.split(';')[0].split(':')[1];

        const byteString = window.atob(base64File.split('base64,')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([int8Array], { type: type });
    }

    base64ToUri(base64: string, fallbackUrl?: string): SafeResourceUrl {
        let src: string;

        if (base64) {
            try {
                src = URL.createObjectURL(this.base64ToBlob(base64));
            } catch {
                src = base64;
            }
        }

        return this.domSanitizer.bypassSecurityTrustResourceUrl(src || fallbackUrl);
    }

    formatAddress(address: any): string {
        return this.formatAddressPipe.transform(address);
    }

    parseGoogleAddress(place: google.maps.places.PlaceResult | google.maps.GeocoderResult): ParsedGoogleAddress {
        const addressObject: AddressObject = {
            country: '',
            countryCode: '',
            state: '',
            postcode: '',
            city: '',
            street: '',
        };

        for (let i = 0; i < place.address_components.length; i++) {
            const o = place.address_components[i];

            if (o.types.indexOf('street_number') >= 0) {
                addressObject.street += o.long_name;
            }
            if (o.types.indexOf('route') >= 0) {
                addressObject.street += ' ' + o.long_name;
            }
            if (o.types.indexOf('locality') >= 0 || o.types.indexOf('postal_town') >= 0) {
                addressObject.city = o.long_name;
            }
            if (o.types.indexOf('administrative_area_level_2') >= 0 && !addressObject.city) {
                addressObject.city = o.long_name;
            }
            if (o.types.indexOf('administrative_area_level_1') >= 0) {
                addressObject.state = o.long_name;
            }
            if ((o.types.indexOf('administrative_area_level_2') >= 0 && !addressObject.state) ||
                (o.types.indexOf('administrative_area_level_3') >= 0 && !addressObject.state)) {
                addressObject.state = o.long_name;
            }
            if (o.types.indexOf('locality') >= 0 && !addressObject.state) {
                addressObject.state = o.long_name;
            }
            if (o.types.indexOf('country') >= 0) {
                addressObject.country = o.long_name;
                addressObject.countryCode = o.short_name;
            }
            if (o.types.indexOf('postal_code') >= 0) {
                addressObject.postcode = o.long_name;
            }
        }

        const addressFromObj = this.formatAddress({
            address_line1: addressObject.street || '',
            address_city: addressObject.city || '',
            address_state: addressObject.state || '',
            address_country: addressObject.country || '',
            address_postcode: addressObject.postcode || '',
        });

        if (!addressObject.street) {
            const street = place.formatted_address.split(',').shift().trim();
            if (street.toLowerCase() !== addressObject.city.toLowerCase() &&
                street.toLowerCase() !== addressObject.state.toLowerCase()) {
                addressObject.street = street;
            }
        }

        const parsedGoogleAddress: ParsedGoogleAddress = {
            address: addressFromObj || this.formatAddress(place.formatted_address),
            address_components: place.address_components,
            addressObject: addressObject,
        };

        if (place?.geometry?.location) {
            const { lat, lng } = place.geometry.location.toJSON();

            parsedGoogleAddress.location = {
                latitude: lat,
                longitude: lng,
            };
        }

        if ('utc_offset_minutes' in place) {
            parsedGoogleAddress.utcOffset = place.utc_offset_minutes;
        }

        return parsedGoogleAddress;
    }
}