import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../services/auth.service';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'fielddCurrency' })
export class FielddCurrencyPipe implements PipeTransform {
    currency: string;
    constructor(
        private authenticationService: AuthenticationService,
        private currencyPipe: CurrencyPipe
    ) {
        this.currency = this.authenticationService.getCurrentCurrency();
    }
    transform(value: any, display: 'code' | 'symbol' | 'symbol-narrow' | 'only-short-symbol' | string | boolean = 'symbol-narrow'): string {
        if (display === 'only-short-symbol') {
            return this.getShortSymbol();
        }
        return this.currencyPipe.transform(value, this.currency, display);
    }
    getCurrencySymbol(): string {
        return this.currencyPipe.transform(0, this.currency).replace("0.00", '');
    }

    getShortSymbol(): string {
        return this.currencyPipe.transform(0, this.currency, 'symbol-narrow').replace("0.00", '');
    }

    getCurrencyCode(): string {
        return this.currencyPipe.transform(0, this.currency, 'symbol').replace(this.getShortSymbol() + "0.00", '');
    }
}

@Pipe({ name: 'formatAmount' })
export class AmountFormattingPipe implements PipeTransform {
    currency: string;
    constructor(
        private authenticationService: AuthenticationService,
        private currencyPipe: CurrencyPipe
    ) {
        this.currency = this.authenticationService.getCurrentCurrency();
    }

    transform(value) {
        try {
            value = value ? Number(value) : 0;
            let decimalCount = 2;
            let thousands = ",";
            let decimal = "."

            const negativeSign = value < 0 ? "-" : "";

            let i = parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;


            // $locale.NUMBER_FORMATS.CURRENCY_SYM + 
            return this.getCurrencySymbol() + negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(value - parseInt(i)).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }
    getCurrencySymbol(): string {
        return this.currencyPipe.transform(0, this.currency).replace("0.00", '');
    }
}
