
/*shared Module is used to store common Modules which are required for other module
*every modules will import shared module instead of these three modules individually
*/
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Chooser } from '@ionic-native/chooser/ngx';

import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { ControlMessagesComponent } from '../validation/control.messages';
import { EqualValidator } from '../validation/customValidators/validateEqual.validator';
import { GoogleAutoComplete } from './directives/google-autocomplete.directive';
import { PipesModule } from '../pipes/pipe.module';
import { Camera } from '@ionic-native/camera/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx'
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { SignaturePadModule } from 'angular2-signaturepad';
import { Vibration } from '@ionic-native/vibration/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'
import { NgxMaskModule } from 'ngx-mask'
import { FielddCurrencyPipe, AmountFormattingPipe } from '../pipes/fielddCurrency.pipe';
import { CardIO } from '@ionic-native/card-io/ngx'
import { VibratableDirective } from './directives/vibratable.directive';
import { CustomCalenderComponent } from './components/customCalendar/customCalendar.component';
import { SlideButtonComponent } from './components/slideButton/slideButton.component';
import { JobInformationComponent } from '../modules/statusModule/pages/jobInformation.component';
import { JobIFrameComponent } from './components/jobIFrame/jobIFrame.component';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { ImageShellComponent } from './components/image-shell/image-shell.component';
import { VideoShellComponent } from '@components/video-shell/video-shell.component';
import { SvgShellComponent } from './components/svg-shell/svg-shell.component';
import { CustomTimePicker } from './components/customTimePicker/customTimePicker.component';
import { UploadStatusComponent } from './components/upload-status/upload-status.component';
import { ScheduleItemComponent } from '../modules/dispatchModule/pages/scheduleJobItem.component';
import { JobListComponent } from './components/job-list/job-list.component';
import { StaffDetailsComponent } from '../modules/staffModule/pages/staff-details.component/staff-details.component';
import { SeparatorDirective } from './directives/separator.directive';
import { ImageResizePipe } from '../pipes/imageResize.pipe';
import { FormatAddressPipe } from '@pipes/address.pipe';
import { CollapsibleDirective } from './directives/collapsible.directive';
import { IonContentOffsetDirective } from './directives/ion-content-offset.directive';
import { IntlTelInputDirective } from './directives/intl-tel-input.directive';
import { GoogleAutocompleteDirective } from '@directives/google-autocomplete-v2.directive';
import { TextareaAutoresizeDirective } from '@directives/textarea-autoresize.directive';
import { DigitOnlyDirective } from '@directives/digit-only.directive';
import { PaymentGatewayComponent } from './components/payment-gateway/payment-gateway.component';
import { DropdownDirective } from '@directives/dropdown.directive';
import { VehicleDescriptionComponent } from './components/vehicleDescription/vehicleDescription.component';
import { VisibilityDirective } from '@directives/visibility.directive';
import { FileUploadDirective } from '@directives/file-upload.directive';
import { AssetDetailsComponent } from './components/asset-details/asset-details.component';
import { ApiQuestionComponent } from './components/api-question/api-question.component';
import { MonthlyCalendarComponent } from '@components/monthly-calendar/monthly-calendar.component';
import { FilterModalComponent } from '@components/filter-modal/filter-modal.component';
import { PriceListComponent } from '@components/price-list/price-list.component';
import { EmptyStateComponent } from '@components/empty-state/empty-state.component';
import { JobCardComponent } from '@components/job-card/job-card.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        SignaturePadModule,
        IonicModule,
        NgbTypeaheadModule,
        NgxMaskModule.forRoot(),
        IonicStorageModule.forRoot(),
    ],
    providers: [
        FielddCurrencyPipe,
        AmountFormattingPipe,
        ImageResizePipe,
        FormatAddressPipe,
        Camera,
        SocialSharing,
        CurrencyPipe,
        CardIO,
        File,
        FileTransfer,
        InAppBrowser,
        Vibration,
        Chooser,
    ],
    exports: [
        PipesModule,
        FormsModule,
        CommonModule,
        NgxMaskModule,
        ReactiveFormsModule,
        SignaturePadModule,
        NgbTypeaheadModule,

        PaymentGatewayComponent,
        CollapsibleDirective,
        IntlTelInputDirective,
        TextareaAutoresizeDirective,
        IonContentOffsetDirective,
        GoogleAutocompleteDirective,
        DigitOnlyDirective,
        DropdownDirective,
        VisibilityDirective,
        FileUploadDirective,
        ControlMessagesComponent,
        GoogleAutoComplete,
        SeparatorDirective,
        VibratableDirective,
        CustomCalenderComponent,
        MonthlyCalendarComponent,
        CustomTimePicker,
        SlideButtonComponent,
        UploadStatusComponent,
        JobIFrameComponent,
        JobInformationComponent,
        EqualValidator,
        ScheduleItemComponent,
        ImageShellComponent,
        VideoShellComponent,
        SvgShellComponent,
        JobListComponent,
        StaffDetailsComponent,
        VehicleDescriptionComponent,
        ApiQuestionComponent,
        AssetDetailsComponent,
        FilterModalComponent,
        PriceListComponent,
        EmptyStateComponent,
        JobCardComponent,
    ],
    declarations: [
        PaymentGatewayComponent,
        CollapsibleDirective,
        IntlTelInputDirective,
        TextareaAutoresizeDirective,
        IonContentOffsetDirective,
        GoogleAutocompleteDirective,
        DigitOnlyDirective,
        DropdownDirective,
        VisibilityDirective,
        FileUploadDirective,
        ControlMessagesComponent,
        GoogleAutoComplete,
        SeparatorDirective,
        VibratableDirective,
        CustomCalenderComponent,
        MonthlyCalendarComponent,
        CustomTimePicker,
        SlideButtonComponent,
        UploadStatusComponent,
        JobIFrameComponent,
        JobInformationComponent,
        EqualValidator,
        ImageShellComponent,
        VideoShellComponent,
        SvgShellComponent,
        ScheduleItemComponent,
        JobListComponent,
        StaffDetailsComponent,
        VehicleDescriptionComponent,
        ApiQuestionComponent,
        AssetDetailsComponent,
        FilterModalComponent,
        PriceListComponent,
        EmptyStateComponent,
        JobCardComponent,
    ]
})
export class SharedModule { }