import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { FormatSlotPipe } from './formatSlot.pipe';
import { FilterStatusNotIn } from './jobFilterByStatusNotIn.pipe';
import { FormatDate } from './formatDate.pipe';
import { DistanceUnitPipe } from './distanceUnit.pipe';
import { BankAccountTypePipe } from './bankAccounType.pipe';
import { FielddCurrencyPipe, AmountFormattingPipe } from './fielddCurrency.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';
import { AddressPipe, FormatAddressPipe } from './address.pipe';
import { FormatMessageTime } from './messageTime.pipe';
import { FilterZeroValue } from './filterZeroValue.pipe';
import { FormatPhonePipe } from './formatPhone.pipe';
import { ImageResizePipe } from './imageResize.pipe';
import { CardSchemePipe } from './cardSheme.pipe';
import { TextareaRowsPipe } from './textarea-rows.pipe';
import { DataURItoBlobPipe } from './data-uri-to-blob.pipe';

@NgModule({
    imports: [
        CommonModule,
        IonicModule
    ],
    declarations: [
        FormatSlotPipe,
        CardSchemePipe,
        FilterStatusNotIn,
        DistanceUnitPipe,
        BankAccountTypePipe,
        FielddCurrencyPipe,
        AmountFormattingPipe,
        FormatDate,
        SafeUrlPipe,
        AddressPipe,
        FormatAddressPipe,
        FormatPhonePipe,
        ImageResizePipe,
        FormatMessageTime,
        FilterZeroValue,
        TextareaRowsPipe,
        DataURItoBlobPipe,
    ],
    exports: [
        FormatSlotPipe,
        CardSchemePipe,
        FilterStatusNotIn,
        DistanceUnitPipe,
        FielddCurrencyPipe,
        AmountFormattingPipe,
        BankAccountTypePipe,
        FormatDate,
        SafeUrlPipe,
        AddressPipe,
        FormatAddressPipe,
        FormatPhonePipe,
        ImageResizePipe,
        FormatMessageTime,
        FilterZeroValue,
        TextareaRowsPipe,
        DataURItoBlobPipe,
    ]
})
export class PipesModule { }
