import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import moment from 'moment';

// import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError';

import { environment } from '@environments/environment';
import { checkFormValidity, noWhitespaceValidator } from '@utils/helper-functions';

import { CardResponse, GatewayCard, PaymentGateways } from '@data/models/shared.model';

import { AlertService, AlertType } from '@services/alert.service';
import { PaymentSetupService } from './payment-gateway.service';
import { AuthenticationService } from '@services/auth.service';
import { LoadingService } from '@services/loading.service';

@Component({
	selector: 'payment-gateway',
	templateUrl: './payment-gateway.component.html',
	styleUrls: ['./payment-gateway.component.scss'],
	viewProviders: [
		{
			provide: ControlContainer,
			deps: [[Optional, NgForm]],
			useFactory: (ngForm: NgForm) => ngForm,
		},
	],
})
export class PaymentGatewayComponent implements OnInit, OnDestroy {

	@Input() company: any;
	@Input() amountToBeCharged: number = 0;
	@Input() customerId: string;

	private _saveCard: boolean = false;
	@Input() set saveCard(value: boolean) {
		// this._saveCard = value && this.fieldAuthenticationService.isUserLoggedIn();
		this._saveCard = value;
	}

	get saveCard(): boolean {
		return this._saveCard;
	}

	@Output() isFormValid = new BehaviorSubject<boolean>(false);
	@Output() gatewayError: EventEmitter<void> = new EventEmitter<void>();
	private cardResponse: (arg0: CardResponse) => void;

	//Pin Payments
	@ViewChild('cvv') cvvInput: ElementRef;
	@ViewChild('expiryDate') expiryDateInput: ElementRef;

	validations = {
		cardHolderName: [{ type: 'required', message: 'Required' },],
		cardNumber: [{ type: 'required', message: 'Required' },],
		expiry_date: [{ type: 'required', message: 'Required' },],
		cvv: [{ type: 'required', message: 'Required' },],
	};
	paymentMethodForm: FormGroup = new FormGroup({
		cardHolderName: new FormControl('', Validators.compose([])),
		cardNumber: new FormControl('', Validators.compose([])),
		expiry_date: new FormControl('', Validators.compose([])),
		cvv: new FormControl('', Validators.compose([])),
	});
	paymentMethodDetails = {
		cardType: 'blankCard',
		cardHolderName: '',
		cardNumber: '',
		expiry_date: '',
		cvv: '',
	};
	cvvPattern: string;
	cardPattern: string;
	manualCardEntry: boolean = false;

	timestamp: number;
	gatewayLoaded: boolean = false;

	adyenCheckout: any;
	adyenComponent: any;

	//Qualpay
	@ViewChild('formSubmitBtn') formSubmitBtn: ElementRef;
	//Square
	private sqPayForm: any;
	private sqPayCard: any;

	//Stripe
	private stripe: any;
	private stripeCard: any;

	//Evo
	private payFabricSDK: any;
	private cardTokenGenerated: boolean = false;
	private isGeneratingToken: boolean = false;

	//Finix
	private finixForm: any;

	get paymentGateway(): string {
		return this.authService.getPaymentGateway(this.company);
	}

	private _isPaymentRequired: boolean = false;
	public get isPaymentRequired(): boolean {
		return this._isPaymentRequired;
	}
	@Input('required')
	public set isPaymentRequired(value: boolean) {
		if (this._isPaymentRequired === value) return;

		this._isPaymentRequired = value;

		for (const key in this.paymentMethodForm.controls) {
			const control = this.paymentMethodForm.get(key);
			if (!value) {
				control.clearValidators();
			} else {
				control.setValidators([Validators.required, noWhitespaceValidator()]);
			}
			control.updateValueAndValidity();
		}
	}

	private subscriptions: Array<Subscription> = [];

	constructor(
		private loadingService: LoadingService,
		private alertService: AlertService,
		private authService: AuthenticationService,
		private paymentSetupService: PaymentSetupService,
	) { }

	ngOnInit(): void {
		this.timestamp = Date.now();
		this.initiateGateways();
	}

	//Component Functions [Start]
	initiateGateways() {
		this.manualCardEntry = false;
		switch (this.paymentGateway) {
			case PaymentGateways.SQUARE:
				this.initSquare()
				break;
			case PaymentGateways.STRIPE:
				this.initStripe();
				break;
			case PaymentGateways.PAY_FABRIC:
				this.initPayFabric();
				this.isFormValid.next(true);
				break;
			case PaymentGateways.ADYEN_FIELDD:
				this.initAdyen();
				break;
			case PaymentGateways.FINIX_FIELDD:
				this.initFinix();
				this.isFormValid.next(true);
				break
			case PaymentGateways.PIN:
			case PaymentGateways.PIN_FIELDD:
			case 'none':
				this.manualCardEntry = true;

				this.subscriptions.push(
					this.paymentMethodForm.statusChanges.pipe(
						debounceTime(100),
						distinctUntilChanged(),
					).subscribe((status) => {
						this.isFormValid.next(['VALID', 'DISABLED'].includes(status));
					})
				);

				break;
		}
	}

	generateToken() {
		return new Promise<CardResponse>((resolve) => {
			this.cardResponse = resolve;

			switch (this.paymentGateway) {
				case PaymentGateways.SQUARE:
					this.generateSquareToken();
					break;
				case PaymentGateways.STRIPE:
					this.generateStripeToken();
					break;
				case PaymentGateways.PAY_FABRIC:
					this.isGeneratingToken = true;
					this.generatePayFabricToken();
					break;
				case PaymentGateways.FINIX_FIELDD:
					this.generateFinixToken();
					break;
				case PaymentGateways.ADYEN_FIELDD:
					this.generateAdyenToken();
					break;
				// case PaymentGateways.QUAL_FIELDD:
				// 	this.generateQualCardToken();
				// 	break;
				case PaymentGateways.PIN:
				case PaymentGateways.PIN_FIELDD:
				case 'none':
					this.generatePinPaymentCard();
					break;
			}
		});
	}

	validateForm() {
		if (this.manualCardEntry) {
			return checkFormValidity(this.paymentMethodForm);
		}
		return this.isFormValid.getValue();
	}

	verifyBuyer(chargeData?: any) {
		switch (this.paymentGateway) {
			case PaymentGateways.FINIX_FIELDD:
				return this.verifyFinixBuyer();
			case PaymentGateways.SQUARE:
				// if (this.company.squareLocationId) {
				// 	return this.verifySquareBuyer(chargeData)
				// }
				break;
			case PaymentGateways.STRIPE:
			default:
				break;
		}
		return 'NOT_REQUIRED'
	}

	private emitCardFail() {
		this.cardResponse({ success: false });
		this.cardResponse = null;
	}

	private emitCardData(cardData: GatewayCard) {
		this.cardResponse({ success: true, cardData });
		this.cardResponse = null;
	}

	private emitGatewayError() {
		this.gatewayError.emit();
	}

	//Component Functions [Start]

	//Pin Payments (Manual Card Entry) [Start]
	checkCardType(event: any) {
		if (event.target.value && ((this.paymentMethodDetails.cardType == 'amex' && event.target.value.length == 17) || (this.paymentMethodDetails.cardType != 'amex' && event.target.value.length == 19))) {
			this.expiryDateInput.nativeElement.focus();
		}
		if (this.paymentMethodDetails.cardNumber) {
			this.cardPattern = "0000 0000 0000 0000";
			this.cvvPattern = "000";
			if (this.paymentMethodDetails.cardNumber.toString().match('^3[47]')) {
				this.paymentMethodDetails.cardType = 'amex';
				this.cardPattern = "0000 000000 00000";
				this.cvvPattern = "0000";
			} else if (this.paymentMethodDetails.cardNumber.toString().match('^4')) {
				this.paymentMethodDetails.cardType = 'visa';
			} else if (this.paymentMethodDetails.cardNumber.toString().match('^5')) {
				this.paymentMethodDetails.cardType = 'master';
			} else {
				this.paymentMethodDetails.cardType = 'blankCard';
			}
		} else {
			this.paymentMethodDetails.cardType = 'blankCard';
		}
	}

	validateExpiryDate(event: any) {
		const expiry_date = event.target.value;
		if (expiry_date) {
			switch (expiry_date.length) {
				case 1:
					if (expiry_date[0] != '1' && expiry_date[0] != '0') {
						event.target.value = '0' + expiry_date[0];
						this.paymentMethodDetails.expiry_date = event.target.value;
					}
					break;
				case 2:
					if (parseInt(expiry_date) > 12) {
						event.target.value = expiry_date[0];
						this.paymentMethodDetails.expiry_date = event.target.value;
					}
					break;
				case 5:
					if (parseInt(expiry_date.split('/')[1]) < parseInt(moment().format('YY'))) {
						event.target.value = expiry_date.substring(0, 2);
						this.paymentMethodDetails.expiry_date = event.target.value;
					} else {
						this.cvvInput.nativeElement.focus();
					}
					break;
			}
		}
	}

	generatePinPaymentCard() {
		if (checkFormValidity(this.paymentMethodForm)) {
			return this.emitCardData({
				cardToken: 'NO_PIN_TOKEN',
				cardNumber: this.paymentMethodForm.value.cardNumber,
				cvc: this.paymentMethodForm.value.cvv,
				expiry_month: this.paymentMethodForm.value.expiry_date.substring(0, 2),
				expiry_year: String(2000 + parseInt(this.paymentMethodForm.value.expiry_date.substring(2))),
			});
		}
		this.emitCardFail();
		this.alertService.showToastMessage('Please check the details you entered and try again', AlertType.Error);
	}
	//Pin Payments (Manual Card Entry) [End]

	//Qualpay [Start]
	initQualPay() {
		this.paymentSetupService.initQualPay('qualPay-form', false, this.onQualCardTokenize.bind(this));
		this.gatewayLoaded = true;
	}

	generateQualCardToken() {
		this.formSubmitBtn?.nativeElement?.click();
	}

	onQualCardTokenize(cardData: any, error: any) {

		if (error?.detail?.length) {
			this.emitCardFail();
			return;
		}

		this.emitCardData({
			cardNumber: cardData.card_number,
			cvc: 'XXX',
			expiry_month: cardData.exp_date.substring(0, 2),
			expiry_year: String(2000 + parseInt(cardData.exp_date.substring(2, 4))),
			cardToken: cardData.card_id,
		});
	}
	//Qualpay [End]

	//PayFabric (EVO) [Start]
	initPayFabric() {
		this.destroyPayFabric();
		this.paymentSetupService.initPayFabric('evo-card', (cardData, error) => this.onPayFabricToken(cardData, error), this.saveCard ? this.customerId : null).then(payFabric => {
			this.gatewayLoaded = true;
			if (!payFabric) {
				//handle payfabric not loaded
			}
			this.payFabricSDK = payFabric;
		}).catch(() => {
			this.emitGatewayError();
		});
	}

	destroyPayFabric() {
		if (this.payFabricSDK && typeof this.payFabricSDK.destroy === 'function') {
			this.payFabricSDK.destroy();
			this.payFabricSDK = null;
		}
	}

	generatePayFabricToken() {
		this.paymentSetupService.submitPayFabric('evo-card');
	}

	onPayFabricToken(cardData: { transactionKey: string, cardToken: string }, error: any) {
		if (error) {
			// if (error.validationError && this.loadingDetails.isDisabled) {
			if (error.validationError && this.isGeneratingToken) {
				this.alertService.showToastMessage(error.error, AlertType.Error);
			} else if (error?.ResponseMsg) {
				this.alertService.showToastMessage(error.ResponseMsg, AlertType.Error);
				this.initPayFabric();
			}
			if (this.isGeneratingToken) {
				this.isGeneratingToken = false;
				return this.emitCardFail();
			}
		}
		if (this.cardTokenGenerated) {
			return;
		}
		if (cardData.transactionKey) {
			this.cardTokenGenerated = true;
			// if (cardData.cardToken) {
			// 	this.emitCardData({
			// 		cardToken: cardData.cardToken,
			// 	});
			// } else {
			// }
			this.loadingService.httpWrapperLoader(
				this.paymentSetupService.getPayFabricCardDetails(cardData.transactionKey),
				false
			).then(response => {
				if (response.success && response.cardDetails?.cardId) {
					this.emitCardData({
						cardNumber: response.cardDetails.cardNumber,
						cvc: 'XXX',
						expiry_month: String(response.cardDetails.expiry_month),
						expiry_year: response.cardDetails.expiry_year,
						cardToken: response.cardDetails.cardId,
						scheme: response.cardDetails.scheme,
					});
				} else {
					this.alertService.showToastMessage('Temporary delay while processing the transaction. Please try again.', AlertType.Error);
					this.emitCardFail();
					return this.initPayFabric();
				}
			}).catch((e) => {
				this.alertService.showToastMessage('Temporary delay while processing the transaction. Please try again.', AlertType.Error);
				this.emitCardFail();
				return this.initPayFabric();
			})
		}
	}
	//PayFabric (EVO) [End]

	//Square [Start]
	initSquare() {
		// return this.paymentSetupService.initSquare(this.company.squareApplicationId, this.company.squareLocationId, 'sq-card').then(({ payments, card }) => {
		return this.paymentSetupService.initSquare(this.company.squareApplicationId, null, 'sq-card').then(({ payments, card }) => {
			this.gatewayLoaded = true;
			this.sqPayCard = card;
			this.sqPayForm = payments;

			this.isFormValid.next(true);
			card.addEventListener('errorClassAdded', () => this.isFormValid.next(false));
			card.addEventListener('errorClassRemoved', () => this.isFormValid.next(true));
		}).catch(() => {
			this.emitGatewayError();
		});
	}

	async generateSquareToken() {
		if (document.querySelector('#sq-card iframe')) {
			try {
				const tokenResult = await this.sqPayCard.tokenize();
				this.onSquareTokenGenerated(tokenResult);
			} catch (e) {
				this.emitCardFail();
			}
		}
	}

	async verifySquareBuyer(chargeData: { token: string, amount: number }) {
		let verificationToken = null;
		try {
			const verificationDetails = {
				billingContact: {},
				intent: 'CHARGE',
				amount: String(chargeData.amount),
				currencyCode: this.authService.getCurrentCurrency()
			};
			const verificationResults = await this.loadingService.httpWrapperLoader(
				this.sqPayForm.verifyBuyer(
					chargeData.token,
					verificationDetails
				) as Promise<any>
			);
			verificationToken = verificationResults.token;
		} catch (e) {
			//suppressing error
			//TODO: think diff error scenarios
		} finally {
			return verificationToken;
		}
	}

	async onSquareTokenGenerated(tokenResult: any) {
		if (!tokenResult || tokenResult.status !== 'OK') {
			let squareErrorMessage = '';
			if (tokenResult?.errors) {
				tokenResult.errors.forEach((error: { message: string; }) => {
					squareErrorMessage += '  ' + error.message + '\r\n'
				});
			} else {
				squareErrorMessage = "Temporary delay while processing your card. Please try again later."
			}
			this.alertService.showToastMessage(squareErrorMessage, AlertType.Error);

			this.emitCardFail();
			return;
		}

		// let verificationToken: string;
		// if (this.company.squareLocationId) {
		// 	verificationToken = await this.verifySquareBuyer({ token: tokenResult.token, amount: this.amountToBeCharged });
		// 	if (!verificationToken) {
		// 		this.alertService.showToastMessage("We were unable to verify to your card. Please try again.", AlertType.Error);

		// 		this.emitCardFail();
		// 		return;
		// 	}
		// }

		let cardData = tokenResult.details.card;

		this.emitCardData({
			cardNumber: 'XXXX XXXX XXXX ' + cardData.last4,
			cvc: 'XXX',
			expiry_month: cardData.expMonth.toString(),
			expiry_year: cardData.expYear,
			cardToken: tokenResult.token,
			// verificationToken
		});
	}

	//Square [End]

	//Stripe [Start]
	initStripe() {
		const { stripe, stripeCard } = this.paymentSetupService.initStripe(this.company.stripePublicKey, '#stripe-card') || {};
		this.gatewayLoaded = true;
		this.stripe = stripe;
		this.stripeCard = stripeCard;

		stripeCard.on('change', (event: any) => {
			this.isFormValid.next(event?.complete && !event?.error?.message);
		});
	}

	generateStripeToken() {
		if (this.saveCard) {
			this.paymentSetupService.getStripeSetupIntentMethod().then(clientSecret => {
				if (clientSecret) {
					this.stripe.confirmCardSetup(clientSecret, {
						payment_method: {
							card: this.stripeCard
						}
					}).then((result: any) => {
						this.onStripeTokenGenerated(result);
					}).catch((err: any) => {
						this.emitCardFail();
						this.alertService.showToastMessage(err.message, AlertType.Error);
					})
				} else {
					this.emitCardFail();
				}
			})
		} else {
			this.stripe.createPaymentMethod({ type: 'card', card: this.stripeCard }).then((result: any) => {
				this.onStripeTokenGenerated(result);
			}).catch((err: any) => {
				this.emitCardFail();
				this.alertService.showToastMessage(err.message, AlertType.Error);
			})
		}
	}

	onStripeTokenGenerated(result: any) {
		if (result.error) {
			this.emitCardFail();
			this.alertService.showToastMessage('Please check the card details and try again', AlertType.Error);
		} else {
			if (this.saveCard && result?.setupIntent?.payment_method) {
				this.emitCardData({
					cardToken: result.setupIntent.payment_method
				})
			}
			else if (!this.saveCard && result.paymentMethod?.id) {
				this.emitCardData({
					cardNumber: 'XXXX XXXX XXXX ' + result.paymentMethod.card.last4,
					cvc: 'XXX',
					expiry_month: result.paymentMethod.card.exp_month.toString(),
					expiry_year: result.paymentMethod.card.exp_year,
					scheme: result.paymentMethod.card.brand,
					cardToken: result.paymentMethod.id
				});
			} else {
				this.emitCardFail();
				this.alertService.showToastMessage('Temporary delay processing payments, please try again', AlertType.Error);
			}
		}
	}
	//Stripe [End]

	//Adyen [Start]
	initAdyen() {
		this.paymentSetupService.initAdyen('adyen-card', this.adyenEventHandler.bind(this)).then(resp => {
			this.gatewayLoaded = true;
			if (!resp) {
				//handle adyen not loaded
			}
			this.adyenCheckout = resp['checkout']
			this.adyenComponent = resp['adyenComponent'];
		});
	}

	generateAdyenToken() {
		if (!this.adyenComponent) {
			return this.emitCardFail();
		}
		this.adyenComponent.submit();
	}

	adyenEventHandler(event: string, state: any) {
		switch (event) {
			case 'submit':
				this.onAdyenToken(state);
				break;
			case 'change':
				this.isFormValid.next(state?.isValid);
				break;
			case 'error':
				this.emitCardFail();
				this.alertService.showToastMessage(state.message, AlertType.Error);
				break;
		}
	}

	onAdyenToken(state: any) {
		if (state?.isValid && state.data?.paymentMethod) {
			const paymentMethod = state.data.paymentMethod;
			this.emitCardData({
				cardNumber: paymentMethod.encryptedCardNumber,
				cvc: paymentMethod.encryptedSecurityCode,
				expiry_month: paymentMethod.encryptedExpiryMonth,
				expiry_year: paymentMethod.encryptedExpiryYear,
				scheme: paymentMethod.brand || paymentMethod.type,
			});
		} else {
			this.emitCardFail();
		}
	}
	//Adyen [End]

	//Finix [Start]
	initFinix() {
		
		this.paymentSetupService.initFinix(`finix-card`).then(resp => {
		// this.paymentSetupService.initFinix(`finix-card-${this.timestamp}`).then(resp => {
			this.gatewayLoaded = true;
			if (!resp) {
				//handle finix not loaded
			}
			this.finixForm = resp;
		});
	}

	generateFinixToken() {
		if (!this.company.recipientData?.applicationId || !this.company.recipientData?.merchantId) {
			this.alertService.showToastMessage(`Fieldd Pay is not available yet. Please check the fieldd pay application.`, AlertType.Error);
            return this.emitCardFail();
        }
		let isValid = true;
		Object.keys(this.finixForm.state).forEach(key => {
			if (this.finixForm.state[key].errorMessages?.length) {
				this.finixForm.state[key].isDirty = true;
				this.finixForm.state[key].isFocused = true;
				isValid = false;
			}
		});
		if (!isValid) {
			this.alertService.showToastMessage(`Please check the card details and try again`, AlertType.Error);
			return this.emitCardFail();
		}
		this.finixForm.submit(environment.finixEnv, this.company.recipientData.applicationId, async (error: any, response: any) => {
			if (error) {
				this.alertService.showToastMessage('Please check the card details and try again', AlertType.Error);
				this.emitCardFail();
				return;
			} else {
				const binData = this.finixForm.binInformation;
				const sessionId = await this.paymentSetupService.generateFinixSessionToken(this.company.recipientData.merchantId);
				this.emitCardData({
					cardNumber: binData.bin,
					cvc: 'XXX',
					scheme: binData.cardBrand,
					cardToken: response.data.id,
					verificationToken: sessionId
				});
			}
		});
	}

	async verifyFinixBuyer() {
		let verificationToken = null;
        try {
            if (!this.company?.recipientData?.merchantId) {
                throw new Error('Merchant Id not found');
            }
            verificationToken = await this.paymentSetupService.generateFinixSessionToken(this.company.recipientData.merchantId);
        } catch (e) {
            //suppressing error
        } finally {
            return verificationToken;
        }
	}
	//Finix [End]

	ngOnDestroy(): void {
		this.destroyPayFabric();
		this.subscriptions.forEach((s) => s.unsubscribe());
	}
}