import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { lastValueFrom, of } from "rxjs";
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';

// import AdyenCheckout from '@adyen/adyen-web';
// import AdyenCheckoutError from "@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError";

import { observeResize } from "@utils/helper-functions";
import { environment } from '@environments/environment';

import { LoadingService } from "@services/loading.service";
import { AuthenticationService } from "@services/auth.service";
import { AlertType, AlertService } from "@services/alert.service";

declare var Stripe: any;
declare var Square: any;
declare var Finix: any;
declare let payfabricpayments: any;
declare var qpEmbeddedForm: any;

@Injectable({
    providedIn: 'root'
})
export class PaymentSetupService implements OnDestroy {
    private getQualPayKeyUrl = environment.apiURL + 'v2/user/getQualPayKey';
    private getStripeSetupIntentUrl = environment.apiURL + 'v2/user/getStripeSetupIntent';
    private getPayFabricSessionUrl = environment.apiURL + 'v2/payFabric/getToken';
    private getPayFabricCardUrl = environment.apiURL + 'v2/payFabric/getCard';
    private getAdyenTokenUrl = environment.apiURL + 'fielddPay/token';

    companyChanged$: any;
    company: any;
    localStorageService: any;

    constructor(
        private http: HttpClient,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private authService: AuthenticationService,
    ) {
        this.companyChanged$ = this.authService.company$.subscribe((company) => {
            this.company = company;
        });
    }

    //API Calls
    getPayFabricSession(customerId: string): Promise<any> {
        let body = {};
        if (customerId) {
            body['customerId'] = customerId
        }
        return lastValueFrom(this.http.post<any>(`${this.getPayFabricSessionUrl}/${this.company._id}`, body));
    }

    getQualPayKey(): Promise<any> {
        return lastValueFrom(this.http.get<any>(this.getQualPayKeyUrl));
    }

    getStripeSetupIntentMethod(): Promise<string | boolean> {
        return lastValueFrom(
            this.http.post<{ success: boolean, clientSecret: string }>(this.getStripeSetupIntentUrl, { companyid: this.company._id }).pipe(
                map((response) => (response?.success && response.clientSecret) ? response.clientSecret : false),
                catchError(() => of(false)),
            )
        );
    }

    getAdyenToken(companyId: string): Promise<any> {
        return lastValueFrom(this.http.get<any>(`${this.getAdyenTokenUrl}/${companyId}`));
    }

    //Qualpay
    initQualPay(formId: string, tokenize: boolean, callbackFn: (data, error) => any, preSubmitCb?: () => any) {
        return this.loadingService.httpWrapperLoader(
            this.getQualPayKey()
        ).then((data) => {
            if (data?.transientKeyData) {
                let styleString = this.getStyleString();
                let checkWidth = (el) => {
                    if (el.offsetWidth > 320) {
                        el.style.height = '180px';
                        styleString = this.getStyleString();
                    } else {
                        el.style.height = '245px';
                        styleString = this.getStyleString(true);
                    }
                }
                let el = document.querySelector(`#${formId} #qp-embedded-container`) as HTMLElement;
                if (el) {
                    checkWidth(el);
                    observeResize(el).pipe(
                        map((contentRect: any) => contentRect.width),
                        distinctUntilChanged(),
                    ).subscribe(() => {
                        checkWidth(el);
                    });
                }
                let configurationParams = {
                    formId: formId,
                    mode: data.transientKeyData.mode,
                    transientKey: data.transientKeyData.transient_key,
                    tokenize: !!tokenize,
                    style: styleString,
                    formFields: {
                        cvv2: {
                            required: true
                        }
                    },
                    achConfig: {
                        enabled: false
                    },
                    onSuccess: (data) => {
                        callbackFn(data, null);
                    },
                    onError: (error) => {
                        let errorMsg;
                        if (error?.code == 99) {
                            this.initQualPay(formId, tokenize, callbackFn, preSubmitCb);
                        }
                        if (error?.detail?.length) {
                            errorMsg = error.detail.reduce((acc: string, msg: any) => acc += msg + '\r\n', '');
                            this.alertService.showToastMessage(errorMsg, AlertType.Error);
                        }
                        callbackFn(null, error);
                    },
                    preSubmit: () => {
                        if (preSubmitCb) {
                            preSubmitCb();
                        }
                    },
                }
                qpEmbeddedForm.loadFrame(data.transientKeyData.merchant_id, configurationParams);
            }
        });
    }

    getStyleString(smallFrame = false) {
        const string =
            `
        body { background-color: #ffffff }
        body .row-xsmall {
            margin-bottom: 15px !important;
        }
        body .content {
            padding: 0;
            line-height: 24px;
            font-family: Montserrat, sans-serif;
            color: #22303e;
            -webkit-font-smoothing: antialiased;
            font-smoothing: antialiased;
            font-size: 14px;
            background-color: #ffffff;
        }
        body .card-type-icon{
            margin-top: 3px;
            padding-left: 15px;
        }
        body .card-type-icon span{
            border: 1px solid #E8EBF0;
            -webkit-border-radius: 8px !important;
            border-radius: 8px !important;
            background-color: #ffffff;
            height: 44px;
        }
        body .field-container input{
            margin-top: 3px;
            padding: 7px 12px 7px 12px;
            width: 100%;
            border: 1px solid #E8EBF0 !important;
            -webkit-border-radius: 8px !important;
            border-radius: 8px !important;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 44px;
            background: #ffffff !important;
            font-size: 16px !important;
            color: #22303e !important;
        }
       
        body .qp-loading{
            display: none;
        }
        body .field-container input:hover{
            -webkit-box-shadow: 0 1px 8px 0px rgb(62 57 107 / 15%) !important;
            box-shadow: 0 1px 8px 0px rgb(62 57 107 / 15%) !important;
            -webkit-transition: 0.2s ease all;
            -o-transition: 0.2s ease all;
            transition: 0.2s ease all;
        }

        body .field-container input.ng-invalid:not(.ng-pristine){
            border-color: #ff716a !important;
        }
        body .small{
            font-size: 10px;
        }
        #embed-payment-form.ng-hide{
            display: initial !important;
        }
        body .grid-half:first-child{
            padding-right: ${smallFrame ? '0' : '15px'};
        }`;
        return String(string.replace(/\s\s+/g, ''))
    }

    //Square
    async initSquare(squareApplicationId: string, squareLocationId: string, elementId?: string) {
        const cardStyle = {
            '.input-container': {
                borderColor: '#ebebed',
                borderRadius: '16px'
            },
            input: {
                color: '#22303e',
                fontSize: '16px',
            },
            'input::placeholder': {
                color: '#8a8a8e',
            },
        }

        async function initializeCard(payments) {
            const card = await payments.card({
                style: cardStyle
            });
            await card.attach(`#${elementId}`);
            return card;
        }

        const payments = Square.payments(squareApplicationId, squareLocationId);
        let card;
        try {
            if (elementId) {
                card = await initializeCard(payments);
            }
        } catch (e) {
            // 
        } finally {
            return { payments, card };
        }
    }

    //Stripe
    initStripe(stripePublicKey: string, elementId = '#stripe-card') {
        //get stripe public key and set up intent object
        const stripe = Stripe(stripePublicKey);
        const elements = stripe.elements();

        const style = {
            base: {
                color: '#22303e',
                lineHeight: '24px',
                fontFamily: `'Quicksand', sans-serif`,
                fontSmoothing: 'auto',
                fontSize: '16px',
                boxShadow: 'none',
                borderRadius: '16px',
                '::placeholder': {
                    color: '#8a8a8e'
                }
            },
            invalid: {
                color: '#ff716a',
                iconColor: '#ff716a'
            }
        };
        const stripeCard = elements.create('card', { style: style });
        stripeCard.mount(elementId);
        stripeCard.addEventListener('change', (event: { error: { message: string; }; }) => {
            if (event.error) {
                this.alertService.showToastMessage(event.error.message, AlertType.Error);
            }
        });
        return { stripe, stripeCard };
    }

    //PayFabric
    getPayFabricCardDetails(transactionId: string): Promise<any> {
        const dataToSend = {
            companyId: this.company._id,
            transactionId
        }
        return lastValueFrom(this.http.post<any>(`${this.getPayFabricCardUrl}`, dataToSend))
    }

    initPayFabric(elementId = 'evo-card', callbackFn: (data, error) => any, customerId: string) {
        let element = document.getElementById(elementId);
        element.innerHTML = '';
        element.innerHTML = `<ion-spinner style="display: block;margin: auto;" name="crescent"></ion-spinner>`;

        return new Promise(resolve => {
            this.loadingService.httpWrapperLoader(
                this.getPayFabricSession(customerId),
                false
            ).then((data) => {
                element.innerHTML = '';
                if (data.success) {
                    try {
                        const payFabric = new payfabricpayments({
                            ...data.sessionTokenRes,
                            target: elementId,
                            displayMethod: 'IN_PLACE',
                            acceptedPaymentMethods: ['CreditCard'],
                            requireShippingAddress: false,
                            successCallback: (data) => {
                                const { TrxKey: transactionKey, OrganizationId: organizationId, WalletID: cardToken } = data;
                                callbackFn({ transactionKey, cardToken }, null);
                            },
                            failureCallback: (error) => {
                                callbackFn(null, error);
                            },
                            disableCancel: true
                        })

                        window.addEventListener('message', (event) => {
                            try {
                                if (!event.origin?.includes('payfabric.com')) return;
                                if (event.data?.Event) {
                                    switch (event.data.Event) {
                                        case "OnFormValidationFail":
                                            callbackFn(null, { error: 'Please enter the details', validationError: true });
                                            break;
                                    }
                                }
                            } catch (e) {
                                //
                            }
                        })
                        resolve(payFabric);
                    } catch (e) {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            })
        })
    }

    submitPayFabric(elementId = '#evo-card') {
        let iframeEl = document.querySelector(`#${elementId} iframe`) as any;
        if (iframeEl) {
            iframeEl.contentWindow.postMessage('submitForm', '*');
        }
    }

    async initAdyen(elementId: string, callbackFn: (type: string, state: any) => any) {
        return this.loadingService.httpWrapperLoader(
            this.getAdyenToken(this.company._id)
        ).then(async (response) => {
            if (response.success) {
                try {
                    const configuration = {
                        paymentMethodsResponse: response.paymentMethodResponse,
                        clientKey: response.clientKey,
                        locale: "en-US",
                        environment: "test",
                        showPayButton: false,
                        onSubmit: (state: any) => {
                            callbackFn('submit', state);
                        },
                        onAdditionalDetails: (state: any) => {
                            callbackFn('additionalDetails', state);
                        },
                        onChange: (state: any) => {
                            callbackFn('change', state);
                        },
                        onError: (error: any) => {
                            callbackFn('error', error);
                        }
                    };
                    // const checkout = await AdyenCheckout(configuration);
                    // const adyenComponent = checkout.create('card', { openFirstPaymentMethod: false }).mount(`#${elementId}`);
                    // return ({ checkout, adyenComponent });
                } catch (e) {
                    return false;
                }
            } else {
                return false;
            }
        }).catch((error) => {
            return false;
        });
    }
    
    //Finix
    async initFinix(elementId: string) {

        const options = {
            showAddress: true,
            showLabels: false,
            labels: {},
            showPlaceholders: true,
            placeholders: {
                name: "Cardholder Name",
                number: "Card Number",
            },
            hideFields: [
                "address_line1",
                "address_line2",
                "address_city",
                "address_state",
                "address_region",
                "address_country",
            ],
            requiredFields: [
                "address_postal_code",
            ],
            hideErrorMessages: true,
            errorMessages: {},
            styles: {
                default: {
                    color: "#8898aa",
                    border: "1px solid #EBEBED",
                    height: "44px",
                    borderRadius: "12px",
                    padding: "12px",
                    fontFamily: "Quicksand, sans-serif",
                    fontSize: "16px",
                },
                success: {
                    color: "#22303e",
                },
                error: {
                    color: "#ff716a",
                    border: "1px solid #ff716a",
                },
            },
        };

        return Finix.CardTokenForm(elementId, options);
    }

    generateFinixSessionToken(merchantId: string): Promise<string>{
        return new Promise((resolve, reject) => {
            Finix.Auth(environment.finixEnv, merchantId, (sessionKey: string) => {
                resolve(sessionKey);
            })
        });
    }
    //Finix [End]

    ngOnDestroy(): void {
        this.companyChanged$?.unsubscribe();
    }
}