import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingService } from '../../services/loading.service';
import { MarkerArea } from 'markerjs2';
import { File } from '@ionic-native/file/ngx';
import { FileManagementService } from '../../services/file-management.service';
import { LocalStorageService } from 'ngx-webstorage';


@Component({
    selector: 'app-zoom',
    templateUrl: 'zoomModal.component.html'
})
export class ZoomModalComponent implements OnInit {

    pathName: string;
    fileName: string;
    dirPath: string;

    hideImage: boolean = false;

    @Input() index: number;

    @Input() editMode: boolean = false;
    @Input() imageUrl: string;

    @Input() disableDelete: boolean = false;

    @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();
    @Output() imageDeleteClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() returnUrlHandler: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private modalController: ModalController,
        private loadingService: LoadingService,
        private file: File,
        private fileManagementService: FileManagementService,
        private localStorageService: LocalStorageService,
    ) { }

    ngOnInit() {
        let url = this.imageUrl;
        if (this.editMode && url) {
            this.loadingService.showLoader(true).then(() => {
                //edit the original image
                if (url.includes('-edit')) {
                    let imgStates = this.localStorageService.retrieve('imgState') || [];
                    let currentState = imgStates[this.index];
                    if (currentState) {
                        url = currentState.originalUrl;
                    }
                }
                this.pathName = url.substring(0, url.lastIndexOf('/') + 1);
                this.fileName = url.substring(url.lastIndexOf('/') + 1, url.length);
                this.dirPath = this.pathName.includes('file:///') ? this.pathName : `file://${this.pathName}`;

                this.file.readAsDataURL(this.dirPath, this.fileName).then((blob) => {
                    this.imageUrl = blob;
                    this.hideImage = false;
                });
            });
        }
    }

    async imageLoaded(ev: Event) {
        if (ev && this.editMode) {
            const img = ev.target as HTMLImageElement;

            let editLine = '-edit(' + this.index + ').jpg'
            let newFileName = this.fileName;

            const imgStates = this.localStorageService.retrieve('imgState') || [];
            const currentState = imgStates[this.index];
            let originalUrl = this.pathName + '/' + this.fileName;

            const markerArea = new MarkerArea(img);
            markerArea.targetRoot = img.parentElement;
            markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;

            markerArea.uiStyleSettings.zoomButtonVisible = true;
            markerArea.uiStyleSettings.zoomOutButtonVisible = true;

            markerArea.renderAtNaturalSize = true;

            markerArea.renderImageType = 'image/jpeg';
            markerArea.renderImageQuality = 1;
            markerArea.addEventListener('render', async event => {

                if (!this.fileName.includes('-edit')) {
                    newFileName = this.fileName.split('.')[0] + editLine;
                } else {
                    if (currentState) {
                        originalUrl = currentState.originalUrl;
                    }
                }

                event.dataUrl = event.dataUrl.split(';base64,')[1] || event.dataUrl;

                const imgSrc = this.fileManagementService.dataURItoBlob(event.dataUrl);
                const imgStateObj = {
                    index: this.index,
                    state: event.state,
                    url: this.fileName,
                    originalUrl: originalUrl
                };

                imgStates[this.index] = imgStateObj;
                this.localStorageService.store('imgState', imgStates);

                const { nativeURL } = await this.file.writeFile(this.file.dataDirectory, newFileName, imgSrc, { replace: true });
                this.returnUrlHandler.emit(nativeURL);
            });

            markerArea.show();

            // restore the state of the previous edited image
            if (currentState && currentState.url === this.fileName && currentState.index === this.index) {
                markerArea.restoreState(currentState.state)
            }

            //img.style.display = 'none'
            this.loadingService.hideLoader()

            markerArea.addEventListener("close", (event) => {
                this.closeModal()
            });
        }
    }

    imageErrorHandler() {
        this.hideImage = true;
    }

    async closeModal() {
        await this.modalController.dismiss();
        this.modalClosed.emit();
    }

    async deleteImage() {
        await this.loadingService.showLoader(true)
        this.imageDeleteClicked.emit();
        await this.closeModal()
        await this.loadingService.hideLoader()
    }
}