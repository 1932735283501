<ion-header class="crm-header">
    <ion-toolbar>
        <ion-title>
            <div class="modal-title">
                <svg-shell class="modal-title__icon" [src]="'side-menu-' + (company?.customerAssets?.icon || 'ship') + '.svg'"></svg-shell>
                <div>{{ company?.customerAssets?.profileTitle || 'Profile' }}</div>
                <svg-shell class="modal-title__close" (click)="closeModal()" src="fi-rr-cross.svg"></svg-shell>
            </div>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="crm-modal-body" fullscreen="true" IonContentOffset>
    <div class="asset-profile" *ngIf="!isAdding && asset">
        <ng-container [ngTemplateOutlet]="profileImageTemplate"></ng-container>
        <div class="asset-profile-info">
            <div class="asset-profile-info-name">{{ asset.title }}</div>
            <div class="asset-profile-info-desc">{{ asset.subtitle }}</div>
        </div>

        <div class="accordion">
            <div class="accordion__head" (click)="toggleAccordion(Accordion.Details)">
                <span>{{ company?.customerAssets?.mainTitle || 'Details' }}</span>
                <svg-shell src="fi-rr-angle-circle-down.svg" [class.rotate]="openAccordion === Accordion.Details"></svg-shell>
            </div>

            <div class="accordion__body" [collapsible]="openAccordion !== Accordion.Details">
                <ng-container [ngTemplateOutlet]="detailsTemplate"></ng-container>

                <div class="action-button">
					<div class="action-button__delete flat-btn sm-height grey-bg bordered loading-btn black-loader" 
                        [class.is-loading]="isDeleting"
                        (click)="deleteAsset()">
						Delete
					</div>
					<div class="action-button__update flat-btn sm-height grey-bg bordered loading-btn black-loader" 
                        [class.is-loading]="isSaving"
                        (click)="saveDetails(detailsForm)">
						Update
					</div>
				</div>
            </div>
        </div>

        <div class="accordion">
            <div class="accordion__head" (click)="toggleAccordion(Accordion.History)">
                <span>Booking History</span>
                <!-- <div class="counter number" *ngIf="orders?.length > 0" [countUp]="orders.length"></div> -->
                <div class="counter number" *ngIf="orders?.length > 0">{{ orders.length }}</div>
                <svg-shell src="fi-rr-angle-circle-down.svg" [class.rotate]="openAccordion === Accordion.History"></svg-shell>
            </div>

            <div class="accordion__body" [collapsible]="openAccordion !== Accordion.History">
                <div>
                    <!-- <ng-container *ngIf="isLoadingHistory || orders?.length; else emptyState">
                        <job-card [order]="orders[0]" [index]="0" [isFetching]="isLoadingHistory" (jobCardClickedEmit)="openOrderDetails($event)"></job-card>
    
                        <ng-container *ngIf="!isLoadingHistory">
                            <ng-container *ngFor="let order of orders | slice : 1; let i = index;">
                                <job-card [order]="order" [index]="i + 1" (jobCardClickedEmit)="openOrderDetails($event)"></job-card>
                            </ng-container>
                        </ng-container>
                    </ng-container> -->
                </div>
            </div>
        </div>
    </div>

    <div class="asset-profile crm-modal-body__con" *ngIf="isAdding">
        <ng-container [ngTemplateOutlet]="profileImageTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="detailsTemplate"></ng-container>
    </div>
</ion-content>

<ion-footer class="ion-no-border footer-v2">
    <ion-toolbar>
        <ion-buttons>
            <button class="flat-btn blue-bg loading-btn" 
                [class.is-loading]="isSaving"
                (click)="detailsForm.ngSubmit.emit()">
                Save and Select
            </button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>

<ng-template #profileImageTemplate>
    <!-- (callback)="profileImage = $event?.[0]?.raw || profileImage" -->
    <div class="asset-profile-img file-input"
        file-upload (callback)="onProfileImageChange($event)"
        [class.secondary-block-margin]="!isAdding" header="Profile Image">
        <app-image-shell [lazyLoad]="true" [src]="profileImage | imageResize" animation="spinner" *ngIf="profileImage"></app-image-shell>
        <svg-shell src="fi-rr-picture.svg" *ngIf="!profileImage"></svg-shell>
    </div>
</ng-template>

<ng-template #detailsTemplate>
    <form #detailsForm="ngForm" name="detailsForm" class="question" 
        (ngSubmit)="saveDetails(detailsForm)"
        (keydown.enter)="$event.preventDefault()"
        novalidate>
        <div class="question-item" 
            *ngFor="let item of assetDetails; trackBy: trackByFn; let i = index;">
            <div class="question-item__title"
                *ngIf="[AssetsInputTypes.PHOTOS, AssetsInputTypes.LICENSE_PLATE].includes(item.inputType)">
                {{ item.label }}
            </div>
            
            <div class="input-floating" *ngIf="[AssetsInputTypes.TEXT, AssetsInputTypes.PARAGRAPH].includes(item.inputType)">
                <textarea #detailInput="ngModel" 
                    [name]="item.label"
                    placeholder=" "
                    [(ngModel)]="item.value" 
                    (blur)="trimText(item.value)"
                    [required]="AssetsInputTypes.TEXT === item.inputType"
                    clearInput 
                    appTextareaAutoresize
                    autocapitalize="off" 
                    [rows]="AssetsInputTypes.TEXT === item.inputType ? 1 : 3"
                    [style.resize]="AssetsInputTypes.PARAGRAPH === item.inputType ? 'vertical' : 'none'"
                ></textarea>
                <label>{{ item.label }}</label>

                <control-messages [control]="detailInput" [message]="{ required: 'Required' }"></control-messages>
            </div>

            <div class="input-floating with-icon-l" *ngIf="AssetsInputTypes.ADDRESS === item.inputType">
                <svg-shell src="fi-rr-search.svg" class="icon-l"></svg-shell>
                <input
                    type="text"
                    googleAutocomplete
                    placeholder=" "
                    [(ngModel)]="item.value"
                    [ngModelOptions]="{ standalone: true }"
                    [country]="company?.activeCountry"
                    (selectAddress)="item.extraData = $event; item.value = $event.address"
                />

                <label>{{ item.label }}</label>
            </div>

            <div class="question-item__photos" *ngIf="AssetsInputTypes.PHOTOS === item.inputType">
                <div class="file-input" *ngFor="let x of [].constructor(5); let j = index;" 
                    file-upload (callback)="item.extraData[j] = $event?.[0]?.raw || item.extraData[j]" header="Image">
                    <app-image-shell *ngIf="item.extraData[j]" [src]="item.extraData[j]" animation="spinner"></app-image-shell>
                    <svg-shell *ngIf="!item.extraData[j]" src="fi-rr-picture.svg"></svg-shell>
                </div>
            </div>

            <div *ngIf="AssetsInputTypes.LICENSE_PLATE === item.inputType">
                <app-api-question [isAssetItem]="true" [assetItem]="item" [floatingInput]="true" (apiQuestionChange)="setApiAnswer($event, item)"></app-api-question>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #emptyState>
    <!-- <empty-state 
        icon="./assets/svg/fi-rr-map-marker-thin.svg" 
        title="No Jobs Found"
        [subtitle]="'customerAssets.bookingButton' | bookingConstant | async"
        (click)="bookNow()"
    ></empty-state> -->
</ng-template>