
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, lastValueFrom } from 'rxjs';
import { environment } from "@environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DispatchService {

    private readonly getScheduleRequestUrl: string = environment.apiURL + 'partner/refreshrequestnew'; //gets list of jobs
    private readonly getBookingDetailUrl: string = environment.apiURL + 'partner/getBooking'; // gets specific job
    private readonly staffCapacityDataUrl: string = environment.apiURL + 'staff/capacity';
    private readonly getOrderMessagesUrl: string = environment.apiURL + 'chat/messages/';

    constructor(private http: HttpClient) { }

    getScheduleRequest(data: { body: any; skip: number; limit: number; }): Promise<any> {
        return lastValueFrom(
            this.http.post<any>(`${this.getScheduleRequestUrl}/${data.skip}/${data.limit}`, data.body)
        );
    }

    getOrder(orderId: string) {
        const query = {
            params: {
                separateCustomerNotes: true,
                separateCustomerAssets: true,
            },
        };
        return lastValueFrom(this.http.get<any>(`${this.getBookingDetailUrl}/${orderId}`, query));
    }

    getOrderMessages(orderId: string): Promise<any> {
        return lastValueFrom(this.http.get<any>(this.getOrderMessagesUrl + orderId));
    }

    getStaffCapacityData(dataToSend): Promise<any> {
        return lastValueFrom(this.http.post<any>(this.staffCapacityDataUrl, dataToSend));
    }
}
