
import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../services/auth.service';

@Pipe({ name: 'bankAccountType' })
export class BankAccountTypePipe implements PipeTransform {
    countryAccountTypes =
        [{
            "country": "DE",
            "accountType": "Bankleitzahl (BLZ)"
        }, {
            "country": "CH",
            "accountType": "BC Number"
        }, {
            "country": "AU",
            "accountType": "BSB"
        }, {
            "country": "CA",
            "accountType": "Transit Code"
        }, {
            "country": "SE",
            "accountType": "Clearing Number"
        }, {
            "country": "UA",
            "accountType": "MFO"
        }, {
            "country": "IN",
            "accountType": "IFSC"
        }, {
            "country": "US",
            "accountType": "Routing Number"
        }, {
            "country": "GB",
            "accountType": "Sort Code"
        }, {
            "country": "UK",
            "accountType": "Sort Code"
        }];
    constructor(
        private authenticationService: AuthenticationService
    ) {

    }
    transform(value: any): string {
        let defaultAccountType = "BSB";
        let company = this.authenticationService.getCurrentCompany();
        let accountType = this.countryAccountTypes.find(x => { return x.country == company.activeCountry.toUpperCase() });
        if (accountType) {
            defaultAccountType = accountType.accountType;
        }
        return defaultAccountType;

    }
}
