<ion-header class="crm-header">
    <ion-toolbar>
        <ion-title>
            <div class="modal-title">
                <div>Select</div>
                <svg-shell class="modal-title__close" (click)="closeModal()" src="fi-rr-cross.svg"></svg-shell>
            </div>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="crm-modal-body" fullscreen="true" IonContentOffset>
    <div class="price-list__p">
        <div class="price-list__p-i">
            <svg-shell src="fi-rr-picture.svg" *ngIf="!variant.primaryImageUrl"></svg-shell>
            <app-image-shell [src]="variant.primaryImageUrl | imageResize: '180x180'" animation="spinner" *ngIf="variant.primaryImageUrl"></app-image-shell>
        </div>
        <div class="price-list__p-t">
            {{ variant.name }}
        </div>
        <div class="price-list__p-st">
            View Options
        </div>
    </div>

    <div *ngIf="data" class="price-list-con">
        <ng-template 
            [ngTemplateOutlet]="nodeTemplateRef" 
            [ngTemplateOutletContext]="{ node: { isTerminal: false, hierarchyLevel: 0, nodes: data.nodes } }"
        ></ng-template>
    </div>
</ion-content>

<ion-footer class="ion-no-border footer-v2">
    <ion-toolbar>
        <ion-buttons>
            <button class="flat-btn blue-bg" 
                (click)="selectData()"
                [disabled]="!selectPriceNodeId">
                Select
            </button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>

<ng-template #nodeTemplateRef let-node="node">
    <div *ngIf="!node.isTerminal">
        <div class="price-list input-floating">
            <div class="price-list-h">{{ headers[node.hierarchyLevel] }}</div>
            <select 
                name="price-list-{{node.hierarchyLevel}}" 
                [(ngModel)]="dummyNames[node.hierarchyLevel]"
                (change)="selectNode(node.nodes[dummyNames[node.hierarchyLevel]], node.hierarchyLevel)"
                [class.default-selected]="dummyNames[node.hierarchyLevel] === undefined">
                <option [value]="undefined" disabled>
                    {{ subItems[node.hierarchyLevel] ? subItems[node.hierarchyLevel].title : 'Select ' + headers[node.hierarchyLevel] }}
                </option>
                <option *ngFor="let n of dummyOptions[node.hierarchyLevel]" [ngValue]="n.value">{{ n.text }}</option>
            </select>
        </div>

        <ng-template
            *ngIf="subItems[node.hierarchyLevel] && subItems[node.hierarchyLevel].length != 0 && subItems[node.hierarchyLevel] != '' && !subItems[node.hierarchyLevel].isTerminal"
            [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{ node: subItems[node.hierarchyLevel] }">
        </ng-template>
    </div>
</ng-template>