import { Pipe, PipeTransform, inject } from '@angular/core';
import { CommonHelperService } from '../services/helper.service';

@Pipe({ name: 'formatSlot' })
export class FormatSlotPipe implements PipeTransform {

    private commonHelperService = inject(CommonHelperService);

    transform(value: any): string {
        return this.commonHelperService.convertTimeSlotFormattedText(value);
    }
}