import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InAppBrowser} from '@ionic-native/in-app-browser/ngx'

@Component({
    selector: 'app-modal',
    templateUrl: 'firsttimeLoginModal.component.html'
})
export class FirsttimeModelComponent {

    constructor(
        private modalController: ModalController,
        private inAppBrowser: InAppBrowser
    ) { }

    closeModal() {
        this.modalController.dismiss();
    }

    visitCrm() {
        this.inAppBrowser.create('https://admin.fieldd.co/#!/login', '_system').show();
    }
}