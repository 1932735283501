import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, lastValueFrom, catchError, of } from 'rxjs';
import * as io from 'socket.io-client';

import { environment } from '@environments/environment';
import { ChatEvent } from '@data/models/enums';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    private socket: SocketIOClient.Socket;
    private chatEmitUrl: string = environment.apiURL + 'chat/';

    public hasNewDirectMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
    ) { }

    establishConnection(id: string) {
        const userInformation = {
            userType: 'partner',
            _id: id
        };
        this.socket = io.connect(environment.socketIOURL + 'chat', {
            transports: ['websocket'],
            query: userInformation
        });
    }

    emitEvent(chatEvent: ChatEvent, data: any): Promise<{ success: boolean }> {
        return lastValueFrom(
            this.http.post<{ success: boolean }>(this.chatEmitUrl + chatEvent, data).pipe(
                catchError(() => of({ success: false })),
            )
        );
    }

    sendOrderMessage(message: string, messageType: string, orderId: string) {
        let dataToSend: any = {
            orderId: orderId,
            message: message,
            messageType: messageType,
            sender: 'partner'
        };

        this.emitEvent(ChatEvent.NEW_ORDER_MESSAGE, dataToSend);
        dataToSend.createdAt = new Date();
        return dataToSend;
    }

    sendDirectMessage(messageType: string, message: any, chatId: string, messageId: string) {
        let dataToSend: any = {
            _id: messageId,
            chatId: chatId,
            messageType: messageType,
            message: message,
            sender: 'partner'
        };
        this.emitEvent(ChatEvent.NEW_DIRECT_MESSAGE, dataToSend);
        dataToSend.createdOn = new Date();
        return dataToSend;
    }

    sendSeenMessage(data: any) {
        this.emitEvent(ChatEvent.SEEN_DIRECT_MESSAGE, data);
    }

    registerDefaultListeners() {
        this.onNewDirectMessage().subscribe(() => {
            this.setHasNewDirectMessage(true);
        });
    }

    onNewDirectMessage(): Observable<any> {
        return this.createObservable(ChatEvent.NEW_DIRECT_MESSAGE);
    }

    onSeenDirectMessage(): Observable<any> {
        return this.createObservable(ChatEvent.SEEN_DIRECT_MESSAGE);
    }

    onNewMessage(): Observable<any> {
        return this.createObservable(ChatEvent.NEW_ORDER_MESSAGE);
    }

    onJobChange(): Observable<any> {
        return this.createObservable(ChatEvent.JOB_UPDATE);
    }

    newBooking(): Observable<any> {
        return this.createObservable(ChatEvent.LIVE_FEED);
    }

    onRefreshData(): Observable<any> {
        return this.createObservable(ChatEvent.REFRESH_DATA);
    }

    createObservable(chatEvent: ChatEvent): Observable<any> {
        return new Observable((observer) => {
            const listener = (data: any) => observer.next(data);

            this.socket.on(chatEvent, listener);
            return () => this.socket.off(chatEvent, listener);
        });
    }

    setHasNewDirectMessage(value: boolean) {
        this.hasNewDirectMessage.next(value);
    }
}