<ion-header class="crm-header">
    <ion-toolbar>
        <ion-title>
            <div class="modal-title">
                <svg-shell class="modal-title__icon" *ngIf="modalIcon" [src]="modalIcon"></svg-shell>
                <div>{{ modalTitle }}</div>
                <svg-shell class="modal-title__close" (click)="closeModal()" src="fi-rr-cross.svg"></svg-shell>
            </div>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="crm-modal-body" fullscreen="true" IonContentOffset>
    <ng-container *ngFor="let group of groupedData">
        <div class="filter-group">
            <div class="filter-group__title">{{ group.title }}</div>
            <div class="filter-group__content">
                <ng-container *ngFor="let item of group.items">
                    <div class="filter-group__item" (click)="toggleItem(item.id, item.data)">
                        <app-image-shell class="filter-group__item-img" [src]="item.image"></app-image-shell>
                        <div class="filter-group__item-title">
                            <div>{{ item.title }}</div>
                            <div class="filter-group__item-subtitle" *ngIf="item.subtitle">{{ item.subtitle }}</div>
                        </div>
                        <ion-checkbox class="filter-group__item-checkbox" [ngModel]="selectedData.has(item.id)" [disabled]="true"></ion-checkbox>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ion-content>

<ion-footer class="ion-no-border footer-v2">
    <ion-toolbar>
        <ion-buttons>
            <button class="flat-btn blue-bg" 
                (click)="selectData()"
                [disabled]="selectedData.size === 0">
                Select
            </button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>