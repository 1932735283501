import { Component, Input, HostBinding, Output, EventEmitter, ElementRef, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { filter, takeWhile } from 'rxjs';

import { ImageResizePipe } from '@pipes/imageResize.pipe';
import { observeIntersection } from '@utils/helper-functions';
import { CommonHelperService } from '@services/helper.service';

@Component({
	selector: 'app-image-shell',
	templateUrl: './image-shell.component.html',
	styleUrls: ['./image-shell.component.scss']
})
export class ImageShellComponent {
	private _src: SafeResourceUrl;
	private _alt = '';

	private __src: string;
	private isInView: boolean = false;

	private readonly FALLBACK_IMAGE = '../../../assets/img/blank_image.svg';

	@HostBinding('class.img-loaded') imageLoaded = false;
	@HostBinding('class.alt-img') altImage = false;

	@Input() lazyLoad: boolean = false;

	@Input()
	set alt(val: string) {
		this._alt = val || this.FALLBACK_IMAGE;
	}

	@Input()
	set src(value) {
		this.__src = value as string;
	}

	get src(): SafeResourceUrl {
		return this._src;
	}

	@Output() load: EventEmitter<null> = new EventEmitter<null>();

	constructor(
		private elementRef: ElementRef,
		private commonHelper: CommonHelperService,
		private domSanitizerService: DomSanitizer,
		private imageResizePipe: ImageResizePipe,
	) { }

	ngAfterViewInit() {
		if (this.src && !this.lazyLoad) {
			this.fetchFile();
		}

		if (!this.lazyLoad) return;
		observeIntersection(this.elementRef.nativeElement).pipe(
			takeWhile((inView) => !inView, true),
			filter((flag) => !!flag),
		).subscribe((inView) => {
			if (inView) {
				this.isInView = true;
				if (this.lazyLoad) {
					this.fetchFile();
				}
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.src) {
			this.altImage = false;
			if (!changes.src.currentValue) {
				this._src = this.domSanitizerService.bypassSecurityTrustResourceUrl(this._alt);
				this.altImage = true;
			}
		}

		if (changes.src?.currentValue && this.isInView || (changes.lazyLoad?.currentValue === false || (!changes.lazyLoad && !this.lazyLoad))) {
			this.fetchFile();
		}
	}

	fetchFile() {
		if (!(this.__src && (this.isInView || !this.lazyLoad))) {
			return;
		}

		this.imageLoaded = false;
		const src = this.imageResizePipe.transform(this.__src);
		this.commonHelper.getFile(src).then((data) => {
			this._src = this.commonHelper.base64ToUri(data?.data, src);
		});
	}

	_imageLoaded() {
		this.imageLoaded = true;
		this.load.emit();
	}

	imageErrorHandler(event: any) {
		event.target.src = this._alt ? this._alt : this.FALLBACK_IMAGE;
		this.altImage = true;
	}
}