<div class="ondemand-con">
    <div style="margin-left: 10px; margin-right: 10px;">
        <div class="ondemand-header-con">
            <div class="ondemand-i">
                <app-image-shell [src]="appSettings.appImage"></app-image-shell>
            </div>
            <div class="ondemand-title">
                On-Demand Request
            </div>
        </div>
        <div class="ondemand-map-con" [class.load-complete]="mapImageLoaded" *ngIf="job?._id">
            <div class="ondemand-map">
                <img [src]="job.bookingImage || 'assets/img/map-placeholder.png'" (load)="mapImageLoaded = true">
            </div>
            <div class="ondemand-map-bottom">
                <div class="ondemand-service-eta-left">
                    <div class="ondemand-service-i">
                        <img [src]="(serviceImageUrl | imageResize: '120x120') || 'assets/img/blank_image.svg'">
                    </div>
                    <div class="ondemand-eta">
                        {{ duration }}
                        <br>
                        Away
                    </div>
                </div>
                <div class="ondemand-address-con-right">
                    <div class="ondemand-from-address">
                        <div class="ondemand-pin">
                            <img src="assets/img/ondemand-from.svg">
                        </div>
                        <div class="ondemand-address">
                            {{ destinationAddress | address }}
                        </div>
                    </div>
                    <div class="ondemand-to-address">
                        <div class="ondemand-pin">
                            <img src="assets/img/ondemand-to.svg">
                        </div>
                        <div class="ondemand-address">
                            {{ startAddress | address }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="standard-con ondemand-info-con" *ngIf="job?._id">
            <div class="view-job-con" id="ondemand-info-con-id" (click)="onViewJobOpen()">
                <div class="pos-con-title">
                    View Job Info
                </div>
                <div class="pos-con-button-r drop-down">
                    <div class="view-job-info-accordian" [class.rotate]="showInfo">
                        <svg width="16" height="12" viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="m17.256 1.487-.512-.552a1.182 1.182 0 0 0-1.733 0L9 7.42 2.99.935a1.182 1.182 0 0 0-1.734 0l-.512.552a1.182 1.182 0 0 0 0 1.606l7.39 7.972a1.182 1.182 0 0 0 1.733 0l7.389-7.972c.42-.453.42-1.153 0-1.606Z"
                                fill="var(--ion-color-primary)" fill-rule="evenodd" opacity=".8" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="job-info-con" [class.show]="showInfo">
            <div class="job-info-outer-container">
                <div class="ondemand-icon">
                    <img src="assets/img/gmap-pin.svg">
                </div>
                <div class="job-info-inside-con">
                    <div class="job-info-heading">Address</div>
                    <div class="job-info-content">{{ job.jobAddress | address }}</div>
                </div>
            </div>
            <div class="h-line"></div>

            <div class="job-info-outer-container" *ngIf="job.status === 0 && job.recurringBookingId">
                <div class="ondemand-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8Zm92.49 313-20 25a16 16 0 0 1-22.49 2.5l-67-49.72a40 40 0 0 1-15-31.23V112a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v144l58 42.5a16 16 0 0 1 2.49 22.5Z"
                            fill="var(--ion-color-primary)" style="opacity:.4" />
                        <path d="m348.49 321-20 25a16 16 0 0 1-22.49 2.5l-67-49.72a40 40 0 0 1-15-31.23V112a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v144l58 42.5a16 16 0 0 1 2.49 22.5Z"
                            fill="var(--ion-color-primary)" class="fa-primary" />
                    </svg>
                </div>
                <div class="job-info-inside-con">
                    <div class="job-info-heading">Time</div>
                    <div class="job-info-content job-info-time">
                        <div *ngIf="recurringJobDetails?._id">
                            {{ startTime }}
                        </div>
                        <div style="display: flex;" *ngIf="recurringJobDetails?._id && recurringJobDetails.endTime != -1">
                            <div class="job-info-endtime"> To </div>
                            <div class="job-info-endtime"> {{ endTime }} </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="job.status === 0 && job.recurringBookingId" class="h-line" ></div>

            <div class="job-info-outer-container">
                <div class="ondemand-icon ondemand-service-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="m479 177.64-.41.23-182.5 100.45 3.45 209.17A24 24 0 0 1 276.06 512h-39.55a24 24 0 0 1-24-24v-.52L216 278 33.47 177.91A24 24 0 0 1 24 145.32l.25-.45 19.5-33.74a24.07 24.07 0 0 1 32.89-8.75l.38.23L256 208l179-105.39a24.07 24.07 0 0 1 33.05 8.12l.23.4 19.5 33.74a24 24 0 0 1-8.78 32.77z"
                            fill="var(--ion-color-primary)" style="opacity:.4" />
                        <path d="m488 366.68-.25.45-19.5 33.74a24.07 24.07 0 0 1-32.89 8.75l-.38-.23L256 304 77 409.39a24.07 24.07 0 0 1-33-8.12l-.23-.4-19.5-33.74a24 24 0 0 1 8.83-32.77l.41-.23 182.4-100.45-3.45-209.17A24 24 0 0 1 235.94 0h39.55a24 24 0 0 1 24 24v.52L296 234l182.53 100.09a24 24 0 0 1 9.47 32.59z"
                            fill="var(--ion-color-primary)" class="fa-primary" />
                    </svg>
                </div>
                <div class="job-info-inside-con">
                    <div class="job-info-heading">Service</div>
                    <div class="job-info-content" id="ondemand-service-scroll">
                        <job-information *ngIf="job?._id" [isInOnDemand]="true" [job]="job" [amount]="job.amount" [discountedAmount]="job.discountedAmount" [promoCode]="job.promoCode" [discountMessage]="discountMessage"></job-information>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-center-con safe-mr-bottom ondemand-btn-con">
            <div class="full-button" (click)="acceptRequest()">
                Accept
            </div>
            <div class="full-button delete-button" (click)="closeModal()">
                Ignore
            </div>
        </div>
    </div>
</div>