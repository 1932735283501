<div  (click)="order ? jobCardClickedEmit.emit(order) : ''" class="order"
	[ngStyle]="{ 'animation-duration': (animationIndex) * 0.5 + 's' }">
	<div *ngIf="isFetching" class="skeleton-order">
		<div class="item-picture skeleton">
			<ion-spinner name="crescent"></ion-spinner>
		</div>
		<div class="skeleton-box" style="height: 60px;">
		</div>
	</div>
	<div *ngIf="!isFetching && order" class="order-con">
		<div class="item-picture" *ngIf="showBookingImage">
			<div *ngIf="order?.recurringBookingId" class="recurring-job" svg-shell src="./assets/svg/fi-rr-repeat.svg"></div>
			<app-image-shell alt="./assets/img/default_map.png" animation="spinner" class="item-picture"
				[src]="order?.bookingImage | imageResize" [lazyLoad]="true">
			</app-image-shell>
		</div>
		<div class="order-details">
			<div>
				<div>
					<div class="past-time">
						{{ (order?.scheduledOn | formatDate: 'h:mma ddd MMM D, YYYY') }}
					</div>
					<div class="past-address">
						{{ (order?.jobAddress || order?.address) | formatAddress: true }}
					</div>
				</div>
			</div>
			<div class="open-col" *ngIf="!showCancel">
				<div class="grey-color">
					{{order?.amount | fielddCurrency }}
				</div>
				<div class="status-past">
					{{ bookingStatus }}
				</div>
			</div>
			<div *ngIf="order && !showCancel">
				<ion-icon class="grey-right-icon" name="chevron-forward-outline"></ion-icon>
			</div>
			<div class="open-col" *ngIf="showCancel">
				<ion-icon name="close-outline"></ion-icon>
			</div>
		</div>
	</div>
</div>