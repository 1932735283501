import { Injectable } from '@angular/core';
import { BackgroundGeolocation, BackgroundGeolocationAuthorizationStatus, BackgroundGeolocationConfig, BackgroundGeolocationEvents, BackgroundGeolocationResponse } from '@ionic-native/background-geolocation/ngx';
import { AuthenticationService } from './auth.service';
import { environment } from "../../environments/environment";
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash-es';
import { AlertService } from './alert.service';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { CommonHelperService } from './helper.service';
import { ParsedGoogleAddress } from '@data/models/shared.model';

/**
 *Common Loading service to display loader
 */
@Injectable({
    providedIn: 'root'
})
export class LocationTrackerService {

    todayRequests = [];
    getNewRequest = true;
    processingRequests = {};
    requestSMSSent = {};
    public trackerSubscription: any;
    public lateJobsTrackingSubscription: any;

    locationUpdateUrl: string = environment.apiURL + "partner/profile/location/update";
    partnerLocationUpdateUrl: string = environment.apiURL + "v2/partner/location/";
    sendLateEmailUrl: string = environment.apiURL + "partner/sendLateEmail/";
    sendLateSmsUrl: string = environment.apiURL + "partner/sendLateSms/"
    partnerPermissionsUpdateUrl: string = environment.apiURL + "partner/updatePermissions"
    foregroundLocationPermission: boolean = false;
    public backgroundLocationPermission: boolean = false;
    notificationPermission: Boolean = false;
    constructor(
        private bGeoLocation: BackgroundGeolocation,
        private authenticationService: AuthenticationService,
        private localStorageService: LocalStorageService,
        private http: HttpClient,
        private alertService: AlertService, 
        private platform : Platform,
        private androidPermissions: AndroidPermissions,
        private commonHelperService: CommonHelperService,
    ) {
        this.platform.ready().then(() => {
            if (this.platform.is("cordova")) {
                this.initializeGeolocationForForeground();
            }
        })
    }

    initializeGeolocationForForeground(){
        let staffId = this.authenticationService.getCurrentUserData()?._id;
        let authToken = this.authenticationService.getAuthToken();
        const config: BackgroundGeolocationConfig = {
            desiredAccuracy: 10,
            stationaryRadius: 1,
            distanceFilter: 10,
            debug: false, 
            startForeground: false,
            stopOnTerminate: false,
            notificationTitle: 'Location Tracking',
            notificationText: 'Sharing location with customer while you\'re en route.',
            interval: 10000,
            fastestInterval: 5000,
            activitiesInterval: 10000
        };

        this.bGeoLocation.configure(config).then(() => {
            this.bGeoLocation.on(BackgroundGeolocationEvents.location).subscribe((location: BackgroundGeolocationResponse) => {
                this.bGeoLocation.startTask().then((taskKey) => {
                    console.log("from background : " + JSON.stringify(location));
                    this.bGeoLocation.endTask(taskKey);
                });
            }, error => {
                console.log('geolocation error:', error);
            });
        });
    }

    startBackgroundTracking(){
        let staffId = this.authenticationService.getCurrentUserData()?._id;
        let authToken = this.authenticationService.getAuthToken();
        const config: BackgroundGeolocationConfig = {
            desiredAccuracy: 10,
            stationaryRadius: 1,
            distanceFilter: 10,
            debug: false, 
            startForeground: true,
            stopOnTerminate: false,
            notificationTitle: 'Location Tracking',
            notificationText: 'Sharing location with customer while you\'re en route.',
            interval: 10000,
            fastestInterval: 5000,
            activitiesInterval: 10000,
            url: this.partnerLocationUpdateUrl,
            httpHeaders: {
                'api_key': environment.apiKey,
                'auth_token':authToken || ""
            },
            postTemplate: {
                partnerId : staffId || "",
                partnerLocation:{
                    latitude: '@latitude',
                    longitude: '@longitude',
                    speed: '@speed',
                    bearing: '@bearing',
                    isFromMockProvider: '@isFromMockProvider',
                    altitude: '@altitude',
                },
                source:"background"
            }
        };
        this.stopPartnerLocationTracking();
        
        this.bGeoLocation.configure(config)
            
        this.bGeoLocation.start();
    }

    stopBackgroundTracking(){
        this.bGeoLocation.configure({startForeground:false})
        this.bGeoLocation.stop();
        this.startPartnerLocationTracking();
    }

    sendLateEmail(job) {
        this.http.post(this.sendLateEmailUrl + job._id, job).subscribe((res => {

        }))
    }

    sendLateSms(job, status) {
        this.http.post(this.sendLateSmsUrl + job._id, job).subscribe((res => {

        }))
    }

    async startPartnerLocationTracking() {
        if (!this.platform.is("cordova")) return;
        this.stopPartnerLocationTracking();

        await this.checkLocationAccess(true);
        this.updateStaffPermissions();
        const job = this.authenticationService.getCurrentJobDetails()
        let time = 60000
        if(job?.status === 2) {
            time = 5000
        }
        if(this.foregroundLocationPermission){
            this.updateLocation();
        }
        this.trackerSubscription = setInterval(() => {
            if(this.foregroundLocationPermission){
                this.updateLocation();
            }
        }, time)
    }

    stopPartnerLocationTracking() {
        clearInterval(this.trackerSubscription);
    }

    updateLocation() {
        if(this.authenticationService.isUserLoggedIn()){
            this.getLatestLocation()
        }
    }

    getLatestLocation(){
        return new Promise((res, rej) => {
            this.bGeoLocation.getCurrentLocation({ maximumAge:60000, enableHighAccuracy: false, timeout: 5000 }).then((position: BackgroundGeolocationResponse) => {
                let locationData = {
                    partnerLocation: {
                        latitude: position.latitude,
                        longitude: position.longitude
                    }
                }
                this.localStorageService.store("partnerLocation", locationData);
                
                let staffId = this.authenticationService.getCurrentUserData()._id;
                let dataToSend = [{
                    partnerId:staffId || "",
                    partnerLocation: {
                        latitude: position.latitude,
                        longitude: position.longitude,
                        speed: position.speed,
                        bearing: position.bearing,
                        isFromMockProvider: position.isFromMockProvider,
                        altitude: position.altitude
                    },
                    //just for debugging purposes
                    //can be removed later
                    source:"foreground"
                }]
                this.updatePartnerLocation(dataToSend).subscribe(res => { });
                res(locationData);
            },error=>{
                console.log("Error getting location",error);
                res(null);
            });
        })
    }

    updatePartnerLocation(postData) {
        return this.http.post<any>(this.partnerLocationUpdateUrl, postData)
    }

    saveEnrouteInfo(data) {
        this.localStorageService.store("enroute", data);
    }

    clearEnrouteInfo() {
        this.localStorageService.clear("enroute");
    }

    getEnrouteInfo() {
        return this.localStorageService.retrieve("enroute");
    }
    getCurrentLocation() {
       return this.localStorageService.retrieve("partnerLocation");
    }

    getLatLong(address): Promise<any> {
        return new Promise((resolve) => {
            let geocoder = new google.maps.Geocoder();
            if (geocoder) {
                geocoder.geocode({
                    'address': address
                }, (results, status) => {
                    if (status == google.maps.GeocoderStatus.OK) {
                        resolve(results[0]);
                    } else {
                        resolve(false)
                    }
                });
            }
        })
    }

    async getGeolocationFromAddress(address: string, activeCountry?: string, postalCode?: string): Promise<ParsedGoogleAddress> {
        try {
            const geocoder = new google.maps.Geocoder();
            const geocoderRequest: google.maps.GeocoderRequest = {
                address: address,
                componentRestrictions: {},
            };

            if (activeCountry) {
                geocoderRequest.componentRestrictions.country = activeCountry;
            }

            if (postalCode) {
                geocoderRequest.componentRestrictions.postalCode = postalCode;
            }

            const { results } = await geocoder.geocode(geocoderRequest);

            if (results?.length) {
                return this.commonHelperService.parseGoogleAddress(results[0]);
            }
            return null;
        } catch {
            return null;
        }
    }

    getDistanceFromCoordinates(origin: google.maps.LatLng | google.maps.LatLngLiteral, destination: google.maps.LatLng | google.maps.LatLngLiteral, unit: 'km' | 'mile' = 'km', travelMode: google.maps.TravelMode = google.maps.TravelMode.DRIVING): Promise<google.maps.DistanceMatrixResponseElement> {
        return new Promise((resolve, reject) => {
            try {
                const directionService = new google.maps.DistanceMatrixService();
                directionService.getDistanceMatrix({
                    origins: [new google.maps.LatLng(origin)],
                    destinations: [new google.maps.LatLng(destination)],
                    travelMode,
                    unitSystem: unit === 'mile' ? google.maps.UnitSystem.IMPERIAL : google.maps.UnitSystem.METRIC,
                }).then((response) => {
                    const result = response.rows?.[0]?.elements?.[0];
                    if (result) {
                        return resolve(result);
                    }
                    return reject();
                }).catch((error) => reject(error));
            } catch (e) {
                return reject(e);
            }
        });
    }

    getMiles(distanceInfo: google.maps.Distance) {
        distanceInfo.value = Math.round((distanceInfo.value * 0.000621371192) * 100) / 100;
        return distanceInfo;
    }

    getKilometers(distanceInfo: google.maps.Distance) {
        distanceInfo.value = Math.round((distanceInfo.value / 1000) * 100) / 100;
        return distanceInfo;
    }

    calcDistance(fromGeo, toGeo) {
        let R = 6371e3, // Earth Radius in meters
            φ1 = this.toRadians(fromGeo.latitude),
            φ2 = this.toRadians(toGeo.latitude),
            Δφ = this.toRadians(toGeo.latitude - fromGeo.latitude),
            Δλ = this.toRadians(toGeo.longitude - fromGeo.longitude),
            a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ / 2) * Math.sin(Δλ / 2),
            c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    }

    toRadians(degree) {
        return Math.PI * degree / 180;
    };

    //Authorized_foreground doesn't work for android
    //For android the permission overlay can directly be opened in the app
    //The following code is to incorporate these two scenarios.

    checkLocationAccess(askForPermission:Boolean = true){
        return new Promise(async (resolve,reject)=>{
            if (!this.platform.is("cordova")) return resolve(true);
            this.checkForegroundLocationPermission().then(async (foregroundResponse) => {
                if(foregroundResponse){
                    this.foregroundLocationPermission = true;
                    let bgPermission = await this.checkBackgroundLocationPermission();
                    if(bgPermission){
                        this.backgroundLocationPermission = true;
                        this.updateStaffPermissions()
                        resolve(true);
                    }
                    else{
                        if(askForPermission){
                            let bgPermissionGranted = await this.askForLocationPermission(true, true) as any;
                            resolve(bgPermissionGranted.skip || bgPermissionGranted.permissionGiven);
                        }
                        resolve(false);
                    }
                }
                else{
                    if(askForPermission){
                        let permissionResponse = await this.askForLocationPermission(false, true) as any;
                        if(permissionResponse.skip){
                            resolve(true);
                        }
                        else if(permissionResponse.permissionGiven){
                            let bgPermission = await this.checkBackgroundLocationPermission();
                            if(bgPermission){
                                this.backgroundLocationPermission = true;
                                this.updateStaffPermissions();
                                resolve(true);
                            }
                            else{
                                let bgPermissionGranted = await this.askForLocationPermission(true, false) as any;
                                resolve(bgPermissionGranted.skip || bgPermissionGranted.permissionGiven);
                            }
                        }
                        else{
                            resolve(false);
                        }
                    }
                    else{
                        resolve(false);
                    }
                }
            })
        });

    }

    updateStaffPermissions(){
        return this.http.post<any>(this.partnerPermissionsUpdateUrl, {
            notificationPermission : this.notificationPermission, 
            locationPermission: (this.foregroundLocationPermission && this.backgroundLocationPermission)
        }).toPromise();
    }

    /**
     * It check for foreground location permission only
     * @returns {promise} true if background permission can be accessed
     */
    checkForegroundLocationPermission(){
        return new Promise(async (resolve, reject) => {
            this.bGeoLocation.checkStatus().then(async (success) => {
                if(success.authorization === BackgroundGeolocationAuthorizationStatus.NOT_AUTHORIZED){
                    resolve(false);
                }
                else{
                    resolve(true);
                }
            }, (err) => resolve(false));   
        })
    }

    /**
     * It only asks for foreground location permission. Background location permission should be handled after this.
     * @returns {promise} true, if background location has been permitted in case of android, in ios opens settings and return false..
     */
    askForLocationPermission(forBackground = false, showAlert = true){
        return new Promise(async (resolve, reject) => {

            let resp = {
                skip: false,
                permissionGiven: false,
                error: false
            }
            let permissionString = "android.permission.ACCESS_FINE_LOCATION";
            let errorMessage = "No location permissions have been given to this app - your customers will not receive accurate updates of your location when you are en-route."

            if(forBackground){
                permissionString = "android.permission.ACCESS_BACKGROUND_LOCATION";
                errorMessage = "The correct tracking permissions have not been given to this app - your customers will not receive accurate updates of your location when you are en-route.";
            }
            let response = true;
            if(showAlert){
                response = await this.alertService.showActionBox('Use your location', 'To provide your customers with best experience when you are en-route to their location, please set your location permissions to always or they will not be able to track your en-route location accurately.', 'Add Permission', 'Skip', 'error') as any;
            }
            if(response){
                if(this.platform.is("android")){
                    this.androidPermissions.requestPermission(permissionString).then((res) => {
                        if(res.hasPermission){
                            resp.permissionGiven = true;
                            if(forBackground){
                                this.backgroundLocationPermission = true;
                                this.updateStaffPermissions();
                            }
                            else{
                                this.foregroundLocationPermission = true;
                            }
                            resolve(resp);
                        }
                        else{
                            if(forBackground){
                                this.backgroundLocationPermission = false;
                                this.updateStaffPermissions();
                            }
                            else{
                                this.foregroundLocationPermission = false;
                                this.updateStaffPermissions();
                            }
                            resolve(resp);
                        }
                    }, (err) => {
                        resp.error = true;
                        resolve(resp);
                    })
                }
                else{
                    this.bGeoLocation.showAppSettings();
                    resolve(resp);
                }
            }
            else{
                await this.alertService.showToastMessage(errorMessage);
                resp.skip = true;
                this.localStorageService.store("partnerLocation", null);
                if(forBackground){
                    this.backgroundLocationPermission = false;
                }
                else{
                    this.foregroundLocationPermission = false;
                }
                this.updateStaffPermissions();
                resolve(resp);
            }
        })
    }

    /**
     * It check for background permission only
     * @returns {promise} true if background permission can be accessed
     */
    checkBackgroundLocationPermission(){
        return new Promise(async (resolve, reject) => {
            if(this.platform.is("android")){
                this.androidPermissions.checkPermission("android.permission.ACCESS_BACKGROUND_LOCATION").then((res) => {
                    if(res.hasPermission){
                        resolve(true);
                    }
                    else{
                        resolve(false);
                    }
                }, (err) => {
                    resolve(false);
                })
            }
            else{
                this.bGeoLocation.checkStatus().then(async (success) => {
                    if(success.authorization === BackgroundGeolocationAuthorizationStatus.NOT_AUTHORIZED || success.authorization === BackgroundGeolocationAuthorizationStatus.AUTHORIZED_FOREGROUND){
                        resolve(false);
                    }
                    else{
                        resolve(true);
                    }
                }, (err) => resolve(false));   
            }
        })
    }
}