<ion-content *ngIf="isFullModal" [forceOverscroll]="false">
	<ng-container *ngTemplateOutlet="modalBody"></ng-container>
</ion-content>

<div class="popup-small-con" *ngIf="!isFullModal">
	<ng-container *ngTemplateOutlet="modalBody"></ng-container>
</div>

<ng-template #modalBody>
	<div class="popup-small-bg">
		<div class="popup-header">
			<img class="popup-icon" [src]="imageUrl">
		</div>
		<div class="popup-title">
			{{title}}
		</div>
		<div class="popup-description" [innerHtml]="description">
	
		</div>
		<div class="popup-buttons-bottom">
			<div class="popup-cancel-button" (click)="closeModal(false)" *ngIf="!hideCancelButton">
				{{cancelButtonText}}
			</div>
			<div class="popup-ok-button" (click)="closeModal(true)">
				{{okButtonText}}
			</div>
			<div class="popup-ok-button" *ngIf="backButtonText" (click)="closeModal(false, true)">
				{{backButtonText}}
			</div>
		</div>
	</div>
</ng-template>