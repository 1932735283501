<ion-content>
    <div class="popup-small-bg confirmation-popover {{ messageType.toLowerCase() }}-type">
        <div class="popup-header">
            <div class="confirmation-loader" [ngClass]="loadCompleteClass" id="confirmationLoader">
                <div class="circle-loader">
                    <div class="checkmark"></div>
                    <div class="errormark"></div>
                </div>
            </div>
        </div>
        <div class="popup-title">
            {{ messageType }}
        </div>
        <div class="popup-description">
            <p style="font-size: var(--large-font); font-weight: 500; white-space: pre-line;" [innerHtml]="message"></p>
        </div>
        <div class="popup-buttons-bottom">
            <div class="popup-ok-button" (click)="closeModal(false)">
                {{ buttonText }}
            </div>
            <div class="popup-ok-button" *ngIf="confirmActionText" (click)="closeModal(true)">
                {{ confirmActionText }}
            </div>
        </div>
    </div>
</ion-content>