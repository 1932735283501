import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';

@Component({
    selector: 'app-slide-button',
    templateUrl: 'slideButton.component.html',
    styleUrls: ['./slideButton.component.scss']
})
export class SlideButtonComponent {

    @Input() text: string = '';
    @Input() set holding (val: boolean) {
        if (val) {
            setTimeout(() => this.reduceByOne(), 300);
        } 
    };
    @Output() slideComplete: EventEmitter<any> = new EventEmitter<any>();

    public slideValue: number = 0;

    constructor(private vibration: Vibration) { }

    onChange() {
        if(this.slideValue === 100) {
            this.vibration.vibrate(100);
            this.slideComplete.emit();
        } else {
            setTimeout(() => this.reduceByOne(), 300);
        }
    }

    reduceByOne() {
        if(this.slideValue !== 0) {
            this.slideValue--;
            setTimeout(() => this.reduceByOne(), 2);
        }
    }
}