import { Directive, ElementRef, Output } from '@angular/core';
import { observeIntersection } from '@utils/helper-functions';
import { Observable } from 'rxjs';

@Directive({
    selector: '[visibility]'
})
export class VisibilityDirective {

    @Output() visibility: Observable<boolean>;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) {
        this.visibility = observeIntersection(this.elementRef.nativeElement);
    }
}