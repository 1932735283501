import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cardScheme' })
export class CardSchemePipe implements PipeTransform {

    constructor() { }

    transform(value: any, display: string): any {
        const { scheme, img } = this.getCardSchemeAndImg(value);
        if (display === 'scheme') {
            return scheme;
        } else if (display === 'img') {
            return img;
        } else {
            return { scheme, img };
        }
    }

    getCardSchemeAndImg(scheme: string) {
        switch (scheme) {
            case 'visa':
            case 'v':
                return {
                    scheme: 'Visa',
                    img: './assets/img/visa-b.svg',
                };
            case 'mastercard':
            case 'master':
            case 'm':
                return {
                    scheme: 'Master',
                    img: './assets/img/mastercard-b.svg',
                };
            case 'amex':
            case 'a':
                return {
                    scheme: 'Amex',
                    img: './assets/img/amex-b.svg',
                };
            default:
                return {
                    scheme: '',
                    img: './assets/img/card.svg',
                };
        }
    }
}