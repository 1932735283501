import { Component, Input } from '@angular/core';
import { FormGroup, NgModel } from '@angular/forms';

@Component({
    selector: 'control-messages',
    template: `<div class="error-text" *ngIf="errorMessage">{{ errorMessage }}</div>`,
})
export class ControlMessagesComponent {

    @Input() control: string | NgModel;
    @Input() message: { [key: string]: string; } = {
        required: 'Required',
        pattern: 'Invalid Format',
        phoneNumberInvalid: 'Invalid Phone Number',
        googleAutocompleteInvalid: 'Select From Dropdown',
    };

    @Input() form: FormGroup;

    @Input('controlForm')
    set controlForm(value: FormGroup) {
        this.form = value;
    }

    get errorMessage(): string {
        const formControl = typeof this.control === 'string' ? this.form?.controls?.[this.control] : this.control?.control;

        if (!formControl?.touched || !Object.keys(formControl?.errors || {}).length) return null;

        for (const key in formControl.errors) {
            if (this.message?.hasOwnProperty(key)) {
                return this.message[key];
            }
        }
        return null;
    }
}
