import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
	selector: 'custom-time-picker',
	templateUrl: './customTimePicker.component.html',
	styleUrls: ['./customTimePicker.component.scss']
})
export class CustomTimePicker implements OnInit, OnChanges{

	@Input() company: any;
	@Input() label: any;
	@Input() format: any;
	@Input() time: any;
	@Input() cssClass: string;
	@Output() onTimeChanged: EventEmitter<any> = new EventEmitter<any>();
	minutesArray: any;
	hoursArray: any;
	pickerOptions: any = {};

	ngOnInit() {
		if (this.cssClass) {
			this.pickerOptions = {
				cssClass: this.cssClass,
			};
		}
		let arr = [0];
		if(this.company?.timeBetweenBookings?.min == 30){
			arr.push(30);
		}
		else if(this.company?.timeBetweenBookings?.min == 15){
			arr.push(15,30,45);
		}
		this.minutesArray = arr.join();

		let companyTimeInMins = this.getMaxCompanyTime(this.company.companyHours)

		this.hoursArray = Array.from(Array(Math.floor(companyTimeInMins / 60) + 1).keys());
		this.changeTimeDisplayFormat(this.time);
	}

	getMaxCompanyTime(companyHours) {
		let maxWorkingHours = 0;
		let lunchStartTime;

		if (companyHours.haveLunchBreak && companyHours.lunchStartTime && companyHours.lunchDuration) {
			lunchStartTime = moment().hours(companyHours.lunchStartTime.time).minute(companyHours.lunchStartTime.min).seconds(0).milliseconds(0);
		}
		if (companyHours && companyHours.weekHours) {
			for (let i = 0; i < companyHours.weekHours.length; i++) {
				if (!companyHours.weekHours[i].isWorkingDay) {
					continue;
				}
				let startTime = moment().hours(companyHours.weekHours[i].startTime.time).minutes(companyHours.weekHours[i].startTime.min).seconds(0).milliseconds(0);
				let endTime;
				if (companyHours.weekHours[i].endTime.time === 0 && companyHours.weekHours[i].endTime.min === 0) {
					endTime = moment().add(1, 'd').hour(0).minutes(0).seconds(0).milliseconds(0);
				}
				else {
					endTime = moment().hours(companyHours.weekHours[i].endTime.time).minutes(companyHours.weekHours[i].endTime.min).seconds(0).milliseconds(0);
				}
				let diff = Math.abs(endTime.diff(startTime, 'm'));
				if (lunchStartTime && lunchStartTime.isBetween(startTime, endTime, 's', '[)')) {
					if (companyHours.lunchDuration) {
						diff -= (companyHours.lunchDuration.time * 60 + companyHours.lunchDuration.min);
					}
				}
				if (diff > maxWorkingHours) {
					maxWorkingHours = diff;
				}
			}
		}
		return +(maxWorkingHours).toFixed(2)

	}


	ionViewWillEnter() {
		this.changeTimeDisplayFormat(this.time);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.changeTimeDisplayFormat(this.time);
	}

	changed(){
		this.onTimeChanged.emit(this.time);
		this.changeTimeDisplayFormat(this.time);
	}

	changeTimeDisplayFormat(time:any){
		setTimeout(() => {
			try{
				let el = document.querySelector(".custom-picker-con ion-datetime")?.shadowRoot?.querySelector("div")
				if(el && el.innerHTML){
					let momObj = moment(new Date(time));				
					el.innerHTML = momObj.hour() + 'h ' + momObj.minute() + 'm';
				}
			}catch(e){
				//suppressing error
			}
		});
	}
}