import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Booking_Status } from '@data/models/shared.model';

@Component({
	selector: 'job-card',
	templateUrl: './job-card.component.html',
	styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent {

	bookingStatus: string;

	private _order: any = null;
	public get order(): any {
		return this._order;
	}

	@Input()
	public set order(value: any) {
		this._order = value;
		this.bookingStatus = Booking_Status[value?.status] || '';
	}

	animationIndex: number;
	_index: number;
	@Input()
	set index(val: number) {
		this._index = val;

		val++;
		if (val > 10) {
			val = val % 10;
			val === 0 ? 1 : val;
		}
		this.animationIndex = val;
	}
	get index() {
		return this._index;
	}

	@Input() isFetching: boolean = false;
	@Input() showCancel: boolean = false;
	@Input() showBookingImage: boolean = true;
	@Output() jobCardClickedEmit: EventEmitter<any> = new EventEmitter();
}
