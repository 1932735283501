import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { observeMutation } from '@utils/helper-functions';
import { filter, map, take } from 'rxjs';

@Directive({
    selector: '[google-auto-complete]'
})
export class GoogleAutoComplete implements OnInit, OnDestroy {
    _autoComplete: any;
    _element: any;
    pacCon: Element;
    autocompleteListener: any;

    @Output() public placeSelected: EventEmitter<any> = new EventEmitter();
    @Input() public emitCompleteAddress: boolean = false;
    @Input("restrictCountry") public restrictCountry: string;

    constructor(el: ElementRef) {
        this._element = el;
    }

    ngOnInit() {
        let autoCompleteOptions: google.maps.places.AutocompleteOptions = {} as google.maps.places.AutocompleteOptions;
        if (this.restrictCountry) {
            autoCompleteOptions.componentRestrictions = { country: this.restrictCountry } as google.maps.places.ComponentRestrictions;
        }
        this._autoComplete = new google.maps.places.Autocomplete(this._element.nativeElement, autoCompleteOptions);
        this._autoComplete.setFields(['geometry', 'formatted_address', 'address_components']);
        this.autocompleteListener = this._autoComplete.addListener('place_changed', () => {
            let data = this._autoComplete.getPlace();
            let Location = {
                latitude: data.geometry.location.lat(),
                longitude: data.geometry.location.lng()
            };
            let addressObject = {
                "country": "",
                "state": "",
                "postcode": "",
                "city": "",
                "street": "",
                "formatted_address": data.formatted_address,
                "location": Location
            };

            if (data.formatted_address) {

                data.address_components.forEach(o => {
                    if (o.types.indexOf('street_number') >= 0) {
                        addressObject.street += o.long_name;
                    }
                    if (o.types.indexOf('route') >= 0) {
                        addressObject.street += ' ' + o.long_name;
                    }
                    if (o.types.indexOf('locality') >= 0) {
                        addressObject.city = o.long_name;
                    }
                    if (o.types.indexOf('administrative_area_level_2') >= 0) {
                        addressObject.city = addressObject.city === "" ? o.long_name : addressObject.city;
                    }
                    if (o.types.indexOf('administrative_area_level_1') >= 0) {
                        addressObject.state = o.long_name;
                    }
                    if (o.types.indexOf('country') >= 0) {
                        addressObject.country = o.long_name;
                    }
                    if (o.types.indexOf('postal_code') >= 0) {
                        addressObject.postcode = o.long_name;
                    }
                });
            }
            if (this.emitCompleteAddress) {
                this.placeSelected.emit(data);
            }
            else {
                this.placeSelected.emit(addressObject);
            }
        })

        this.observeInView();
    }

    private observeInView(): void {
        observeMutation(document.body, { childList: true }).pipe(
            map(() => document.querySelector('body > .pac-container:not(.has-initialized)')),
            filter((pacCon) => !!pacCon),
            take(1),
        ).subscribe((pacCon) => {
            if (pacCon) {
                this.pacCon = pacCon;
                pacCon.classList.add('has-initialized');
            }
        });
    }

    ngOnDestroy(): void {
        google.maps.event.removeListener(this.autocompleteListener);
        google.maps.event.clearInstanceListeners(this._autoComplete);

        this.pacCon?.remove();
    }
}